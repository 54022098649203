<template>
    <Loading class="!min-h-[100vh]" v-if="!plans" />
    <MakePayment
        v-else
        :billingFrequency="billingFrequency"
        :totalPrice="totalPrice/100"
        :monthlyPrice="monthlyPrice/100"
        :yearlyPrice="yearlyPrice/100"
        :yearlyDiscount="yearlyDiscount"
        @update:billingFrequency="handleBillingFrequencyChange"
        @continue="handleMakePayment"
        :paymentLoading="paymentLoading"
        :paymentError="paymentError"
        :firstName="firstName"
        :doLogout="doLogout"
        :onPrevious="onPrevious"
    
    />
</template>
<script lang='ts' setup>
import { ref, computed, onMounted } from "vue";
import MakePayment from "@/components/ui/pages/MakePayment/MakePayment.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { agencyApi } from "@/utils/apis/agencyApi";
import { PLAN_TYPES } from "@/components/constants";
import { BILLING_FREQUENCY, calculateSubscriptionPrice } from "@crewfare/server-shared";
import { userGroupsApi } from "@/utils/apis/userGroupsApi";
import { AgencyUpgradePayload } from "@crewfare/server-shared"
import { signOut } from "firebase/auth";
import { auth } from "@/utils/firebase";
import Loading from "@/components/default/Loading.vue";

const store = useStore();
const router = useRouter();
const userGroupId: string = router.currentRoute.value.params.userGroupId as string;
const selectedPlan = computed(()=> store.state.selectedPlan?.[userGroupId]);
const billingFrequency = computed(()=> store.state.selectedPlan?.[userGroupId]?.billingFrequency);
const teammatesNumber = computed(()=> store.state.selectedPlan?.[userGroupId]?.teammatesNumber);
const totalPrice = computed(()=> calculateSubscriptionPrice(PLAN_TYPES.ENTERPRISE, billingFrequency.value, teammatesNumber.value));
const yearlyDiscount = ref(0);
const plans = ref(null);
const monthlyPrice = ref(0);
const yearlyPrice = ref(0);
const paymentLoading = ref(false);
const paymentError = ref("");

const firstName = computed(() => {
    const account = store.state.account;
    return account.firstName ? account.firstName : account.name.split(" ")[0];
  });
  const doLogout = ()=>{  
    store.commit("setAccount", null);
      signOut(auth).then(() => {
        router.push("/")
    });
  }
  const onPrevious = () =>{
    router.go(-1)
  }

onMounted(async ()=>{
    const userGroup = await userGroupsApi.get(userGroupId);
      if(userGroup.error){
        router.push("/")
        return;
      }
    if(!selectedPlan.value){
        console.log("No selected plan found, redirecting to choose a plan page");
        router.push({name: "chooseAPlan", params: {userGroupId}});
        return;
    }
    const response = await agencyApi.getPlans();
    plans.value = response.data;
    yearlyDiscount.value = response.data[PLAN_TYPES.ENTERPRISE].yearlyDiscountPercentage;
    monthlyPrice.value = calculateSubscriptionPrice(PLAN_TYPES.ENTERPRISE, BILLING_FREQUENCY.MONTHLY, 1)
    yearlyPrice.value = calculateSubscriptionPrice(PLAN_TYPES.ENTERPRISE, BILLING_FREQUENCY.YEARLY, 1)
})


const handleBillingFrequencyChange = (newBillingFrequency: BILLING_FREQUENCY)=>{
    store.commit("setSelectedPlan", {
        userGroupId,
        ...selectedPlan.value,
        billingFrequency: newBillingFrequency
    })
}

const handleMakePayment = async (data:any)=>{
    paymentLoading.value = true;
    if(!data.createToken){
        paymentLoading.value = false;
        paymentError.value = "There's some issue with payment form, please try again";
        return;
    }
    const token = await data.createToken();
    console.log("Data", data, token);
    if(!token){
        paymentLoading.value = false;
        return;
    }
    const upgradeData: AgencyUpgradePayload = {
        billingFrequency: data.billingFrequency,
        billingName: data.billingName,
        billingCountry: data.billingName,
        billingZip: data.billingZip,
        teamMembersCount: teammatesNumber.value,
        token
    }
    console.log("Upgrade data", upgradeData);
    const upgradeRes = await agencyApi.upgrade(userGroupId, upgradeData);
    if(upgradeRes.error){
        paymentLoading.value = false;
        paymentError.value = upgradeRes.message || "There was some issue processing the payment";
        return;
    }
    console.log("Upgrade Res", upgradeRes);
    router.push({name: "agencyPaymentCompleted", params: {userGroupId}});
    paymentLoading.value = false;
}

</script>