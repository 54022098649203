<template>
    <span v-if="iconsMap[name]"
        :class='{ "flex items-center justify-center p-2.5 w-[7.5rem] h-[7.5rem] rounded-full": outFilled }'
        :style="{ background: outFilled ? outfillColor : '' }">
        <component :is="iconsMap[name]" :color="color" :width="width" />
    </span>
</template>
<script lang="ts">
import { PropType } from 'vue';
import HomeIcon from './HomeIcon.vue';
import MailIcon from './MailIcon';
import LockIcon from './LockIcon';
import FeatureIcon from './FeatureIcon';
import CheckIcon from './CheckIcon';
import DoNotDisturbIcon from './DoNotDisturbIcon';
import VisibilityOffIcon from './VisibilityOffIcon';
import VisibilityOnIcon from './VisibilityOnIcon';
import AccountCircleIcon from './AccountCircleIcon';
import ArrowDownIcon from './ArrowDownIcon';
import AmericanExpressIcon from './AmericanExpressIcon';
import VisaIcon from './VisaIcon';
import MasterCardIcon from './MasterCardIcon';
import DiscoverIcon from './DiscoverIcon';
import ForwardArrowIcon from './ForwardArrowIcon';
import PlayIcon from './PlayIcon';
import BidLandscapeIcon from './BidLandscapeIcon';
import ChatAddOnIcon from './ChatAddOnIcon';
import HandshakeIcon from './HandshakeIcon';
import MarkEmailReadIcon from './MarkEmailReadIcon';
import WidgetsIcon from './WidgetsIcon';

export const iconsMap = {
    "home": HomeIcon,
    "mail": MailIcon,
    "lock": LockIcon,
    "feature": FeatureIcon,
    "visibility-off": VisibilityOffIcon,
    "visibility-on": VisibilityOnIcon,
    "donotdisturb": DoNotDisturbIcon,
    "check": CheckIcon,
    "accountcircle": AccountCircleIcon,
    "arrowdown": ArrowDownIcon,
    "americanexpress": AmericanExpressIcon,
    "visa": VisaIcon,
    "mastercard": MasterCardIcon,
    "discover": DiscoverIcon,
    "forwardArrow": ForwardArrowIcon,
    "play": PlayIcon,
    "bidlandscape": BidLandscapeIcon,
    "chataddon": ChatAddOnIcon,
    "handshake": HandshakeIcon,
    "markemailread": MarkEmailReadIcon,
    "widgets": WidgetsIcon
}

export type IconNames = "home" | "mail" | "lock" | "feature" | "check" | "donotdisturb" | "visibility-off" | "visibility-on" | "accountcircle" | "arrowdown" | "americanexpress" | "visa" | "mastercard" | "discover" | "forwardArrow" | "play" | "bidlandscape" | "chataddon" | "handshake" | "markemailread" | "widgets";

export default {
    computed: {
        iconsMap() {
            return iconsMap;
        }
    },
    props: {
        name: {
            type: String as PropType<IconNames>,
            required: true
        },
        width: {
            type: Number,
            default: 34
        },
        color: {
            type: String,
            default: '#3E24F6'
        },
        outFilled: {
            type: Boolean,
            default: false
        },
        outfillColor: {
            type: String,
            default: '#F5F4FE'
        }
    }
}
</script>