<template>
    <PaymentSuccessful :name="firstName" :onLogout="doLogout" :onAccountSetup="onAccountSetup" />
</template>
<script lang="ts" setup>
import PaymentSuccessful from '@/components/ui/pages/PaymentSuccessful/PaymentSuccessful.vue';
import { auth } from '@/utils/firebase';
import { signOut } from 'firebase/auth';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const firstName = computed(() => {
    const account = store.state.account;
    return account.firstName ? account.firstName : account.name.split(" ")[0];
  });
  const onAccountSetup = () => {
    router.push("/")
  };

  const doLogout = () => {
    store.commit("setAccount", null);
      signOut(auth).then(() => {
        router.push("/")
    });
  }

</script>