import { BaseApi, getToken } from './apiFactory';
class HotelGroupsApi extends BaseApi {
  async findAll() {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async findByManagerId(managerId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/managerId/${managerId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const hotelGroupsApi = new HotelGroupsApi({ name: 'hotel-groups' });
