<template>
  <div class="inline-flex items-center gap-2 bg-[#3E24F60D] p-2 rounded">
    <button @click="decrement" :disabled="counter <= minValue"
      class="p-2 bg-lpBlue text-lpWhite rounded disabled:bg-gray-300 disabled:cursor-not-allowed flex justify-center">
      <font-awesome-icon icon="minus" class="text-xs" />
    </button>

    <div class="relative">
      <span ref="textWidth" class="absolute invisible whitespace-nowrap px-2">
        {{ counter }}
      </span>
      <input v-model.number="counter" type="number" :min="minValue" :max="maxValue"
        class="w-12 md:w-16 text-14 font-medium text-center bg-transparent border-none focus:outline-none focus:ring-0 appearance-none p-0"
        :readonly="!inputable" :class="{ 'pointer-events-none select-none': !inputable }" />
    </div>

    <button @click="increment" :disabled="counter >= maxValue"
      class="p-2 bg-lpBlue text-lpWhite rounded disabled:bg-gray-300 disabled:cursor-not-allowed flex justify-center">
      <font-awesome-icon icon="add" class="text-xs" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = defineProps({
  inputable: {
    type: Boolean,
    default: true,
  },
  minValue: {
    type: Number,
    default: 1,
  },
  maxValue: {
    type: Number,
    default: 100,
  },
  modelValue: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const counter = ref(props.modelValue);

watch(counter, (newValue) => {
  if (newValue < props.minValue) {
    counter.value = props.minValue;
  }
  if (newValue > props.maxValue) {
    counter.value = props.maxValue;
  }
  emit('update:modelValue', counter.value);
});

function increment() {
  if (counter.value < props.maxValue) {
    counter.value++;
  }
}

function decrement() {
  if (counter.value > props.minValue) {
    counter.value--;
  }
}
</script>

<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
