import { AccountTypes, PlanTypes } from '../typesAndEnums';

export const isCurrentUserCrewfare = (
  currentAccountType: AccountTypes,
  currentGroupPlan: PlanTypes,
) => {
  if (
    currentAccountType === AccountTypes.Super_Admin ||
    currentGroupPlan === PlanTypes.FULL_ACCESS
  )
    return true;
  return false;
};

export const isCurrentUserNotCrewfare = (
  currentAccountType: AccountTypes,
  currentGroupPlan: PlanTypes,
) => {
  if (
    currentAccountType !== AccountTypes.Super_Admin &&
    currentGroupPlan !== PlanTypes.FULL_ACCESS
  )
    return true;
  return false;
};
