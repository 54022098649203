<template>
    <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4314_42887" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="18">
        <rect x="0.333008" width="18" height="18" :fill="color"/>
        </mask>
        <g mask="url(#mask0_4314_42887)">
        <path d="M6.35559 6.9257L8.75278 2.99701C8.82291 2.88163 8.90884 2.79938 9.01059 2.75026C9.11222 2.70126 9.21953 2.67676 9.33253 2.67676C9.44553 2.67676 9.55284 2.70126 9.65447 2.75026C9.75622 2.79938 9.84216 2.88163 9.91228 2.99701L12.3095 6.9257C12.3796 7.0387 12.4147 7.15732 12.4147 7.28157C12.4147 7.40594 12.3858 7.51957 12.3282 7.62244C12.2705 7.72532 12.1912 7.80751 12.0903 7.86901C11.9893 7.93051 11.8715 7.96126 11.7368 7.96126H6.92822C6.79309 7.96126 6.67484 7.9302 6.57347 7.86807C6.47209 7.80595 6.39322 7.72407 6.33684 7.62244C6.27922 7.52107 6.25041 7.40845 6.25041 7.28457C6.25041 7.1607 6.28547 7.04107 6.35559 6.9257ZM13.4592 16.2113C12.6023 16.2113 11.8734 15.9114 11.2724 15.3116C10.6715 14.7118 10.3711 13.9834 10.3711 13.1265C10.3711 12.2696 10.671 11.5407 11.2707 10.9397C11.8706 10.3388 12.599 10.0384 13.4558 10.0384C14.3127 10.0384 15.0417 10.3383 15.6427 10.938C16.2435 11.5379 16.544 12.2663 16.544 13.1231C16.544 13.98 16.2441 14.7089 15.6443 15.3099C15.0445 15.9108 14.3161 16.2113 13.4592 16.2113ZM2.87109 15.1583V11.0895C2.87109 10.8974 2.93603 10.7366 3.06591 10.6073C3.19591 10.478 3.35697 10.4134 3.54909 10.4134H7.61784C7.80997 10.4134 7.97072 10.4783 8.10009 10.6082C8.22934 10.7382 8.29397 10.8993 8.29397 11.0914V15.1601C8.29397 15.3523 8.22903 15.513 8.09916 15.6424C7.96916 15.7716 7.80809 15.8363 7.61597 15.8363H3.54722C3.35509 15.8363 3.19434 15.7713 3.06497 15.6414C2.93572 15.5114 2.87109 15.3504 2.87109 15.1583Z" fill="#3E24F6"/>
        </g>
    </svg>
</template>
<script>
export default {
    computed: {
        height(){
            return this.width*18/19
        }
    },
    props: {
        color: {
            type: String,
            default: '#D9D9D9'
        },
        width: {
            type: Number,
            default: 19
        }
    },
}
</script>