<template>
  <Modal
    :close="close"
    :innerClass="`min-w-[35vw] max-w-[90vw] ${uploadType === uploadTypes.MANUAL ? 'max-h-[80vh]' : ''}`"
  >
    <div class="flex w-full flex-col gap-4 items-center justify-center">
      <template v-if="!fileHasUploaded">
        <div class="flex w-full justify-center gap-4">
          <p class="text-3xl font-semibold" v-if="!isSuccess">
            {{ !editHotelId ? 'Uploading Hotels' : 'Editing Hotel' }}
          </p>
        </div>
        <Switcher
          :options="uploadOptions"
          :passedFunction="setUploadType"
          :default-option="uploadType"
          v-if="!isSuccess && !editHotelId"
        />
        <div
          v-if="uploadType === uploadTypes.FILE_IMPORT"
          class="flex w-full justify-center gap-2 cursor-pointer align-center"
          @click.capture="downloadTemplate"
        >
          <div class="material-symbols-rounded text-crewfareGreen">download</div>
          <span class="text-base font-normal">Download sample file</span>
        </div>
        <div v-if="uploadType === uploadTypes.FILE_IMPORT" class="flex flex-col gap-4">
          <DragAndDrop
            :passedFunction="handleFileUpload"
            :onDropFunction="onDropFile"
            :subtitle="'(Only .CSV File)'"
          >
            <input
              type="file"
              ref="fileModalUpload"
              accept="text/csv"
              id="fileModalUpload"
              @change.prevent="updateFile"
              class="cursor-pointer absolute overflow-hidden opacity-0 w-full h-full"
            />
          </DragAndDrop>
        </div>
        <UploadSuccess v-if="uploadType === uploadTypes.MANUAL && isSuccess" />
      </template>

      <div class="flex flex-col gap-4">
        <div class="flex flex-row gap-2 items-center justify-between">
          <div v-if="uniqueHotelsCount > 0" class="flex gap-4 flex-row justify-center items-center">
            <div class="text-5xl font-bold">{{ duplicatesStats.newHotelsCount }}</div>
            <div class="flex flex-col">
              <span>Hotels Not Found</span>
            </div>
            <div class="text-5xl font-bold">{{ duplicatesStats.possibleDuplicates }}</div>
            <div class="flex flex-col">
              <span>Possible Found Hotels</span>
            </div>
            <div class="text-5xl font-bold">{{ duplicatesStats.avoidables }}</div>
            <div class="flex flex-col">
              <span>Found Hotels</span>
            </div>
            <div v-if="hotelsWithLocationErrCount" class="text-5xl font-bold">
              ({{ hotelsWithLocationErrCount }})
            </div>
            <div v-if="hotelsWithLocationErrCount" class="flex flex-col">
              <span>Location Errors</span>
            </div>
          </div>
        </div>
        <div v-if="uniqueHotelsCount > 0">
          <div class="flex flex-col gap-2 justify-between">
            <div class="flex gap-4">
              <div class="flex gap-4 flex-col">
                <div class="flex flex-col">
                  <span>Expected columns</span>
                </div>
                <div class="flex gap-2">
                  <div
                    class="px-4 py-2"
                    v-for="item in expectedColumns"
                    :key="item"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!error">
              <p>Hotels to be uploaded:</p>
              <div class="flex flex-col mt-2 p-2 bg-gray-700 rounded">
                <div class="flex justify-between">
                  <div class="px-4 py-2">Hotel name</div>
                  <div class="px-4 py-2">Number of contacts</div>
                </div>
                <div class="max-h-[30vh] overflow-auto divide-y">
                  <div
                    v-for="hotel in hotelsUnique"
                    :key="hotel"
                    class="flex justify-between"
                    :class="successfulUids.includes(hotel.uid)
                      ? 'bg-green-500'
                      : failedUids[hotel.uid]
                      ? 'bg-red-500'
                      : ''"
                  >
                    <div class="px-4 py-2">
                      <p>{{ hotel.name }} - {{ hotel.addrFull }}</p>
                      <!-- Updated phrasing here -->
                      <p
                        v-if="detectOnUploadAction(hotel)"
                        class="text-sm text-yellow-600"
                      >
                        {{ detectOnUploadAction(hotel) }}
                      </p>
                      <p
                        v-if="hotel.locationError"
                        class="text-sm text-yellow-600"
                      >
                        Couldn't parse the address, The hotel will be saved with (0, 0) lat, lng
                      </p>
                    </div>
                    <div class="flex justify-between flex-end">
                      <div v-if="failedUids[hotel.uid]" class="px-4 py-2">
                        {{ failedUids[hotel.uid] }}
                      </div>
                      <div class="px-4 py-2">
                        {{ hotel.contacts.length ?? 0}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center mt-4 justify-between gap-4">
            <button
              v-if="!(hotelsImporting === false || hotelsImporting || error)"
              @click="selectHotels"
              class="border border-2 border-[#A4CDE3] hover:bg-[#A4CDE3] hover:text-black px-4 py-2 rounded-full flex gap-2 text-[#A4CDE3]"
            >
              {{ noHotelFoundInCsv ? "Close" : "Upload hotels" }}
            </button>
            <button
              v-if="!(hotelsImporting === false || hotelsImporting || error)"
              @click="downloadNotFoundHotels"
              class="border border-2 border-[#A4CDE3] hover:bg-[#A4CDE3] hover:text-black px-4 py-2 rounded-full flex gap-2 text-[#A4CDE3]"
            >
              Download hotels not found
            </button>
          </div>
        </div>
      </div>

      <div v-if="error" class="flex flex-col items-center mt-4 justify-between gap-4">
        <p class="py-2 px-4 text-center text-red-600">
          Please fix the following issue:
        </p>
        <p>{{ error }}</p>
      </div>

      <div v-if="hotelsImporting">
        <p class="py-2 text-center">The upload is in progress, please wait.</p>
        <div class="flex rounded overflow-hidden border border-crewfareGreen">
          <div
            class="h-[4px] bg-crewfareGreen"
            :style="{ width: `${(importedHotels.length / uniqueHotelsCount) * 100}%` }"
          ></div>
        </div>
      </div>

      <div v-if="isFileLoading">
        <Spinner />
      </div>

      <div v-if="hotelsImporting === false">
        <p class="py-2 text-center">The upload is done, you can close this screen.</p>
      </div>
    </div>
  </Modal>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { mapFileToHotels, downloadAssetFile, processHotels } from '@/utils/hotel.jsx';
import Modal from '@/components/ui/molecules/Modal';
import DragAndDrop from '@/components/ui/molecules/DragAndDrop.vue';
import Switcher from '@/components/ui/molecules/Switcher';
import { UPLOAD_HOTEL_TYPES } from '@/enums/upload-hotel.enum';
import { PUBLIC_FILES } from '@/enums/public-files.enum';
import { hotelsApi } from '@/utils/apis/hotelsApi';
import { accountsApi } from '@/utils/apis/accountsApi';
import UploadSuccess from '../ui/molecules/UploadSuccess.vue';
import Spinner from '../ui/atoms/Spinner.vue';
import { convertArrayToKeyMappedObjectArrays } from '@crewfare/commons/src/utils';
import { stringsKeysCreator } from '@crewfare/server-shared';
import { where, getDocs, query, collection } from "firebase/firestore";
import moment from "moment";


export default {
  data() {
    return {
      noHotelFoundInCsv:true,
      type: 'address',
      isFileLoading: false,
      file: null,
      hotelsUnique: [],
      allHotels: {},
      expectedColumns: null,
      uniqueHotelsCount: 0,
      newHotelsCount: 0,
      avoidables: 0,
      possibleDuplicates: 0,
      correctColumns: false,
      error: '',
      errorType: null,
      successfulUids: [],
      failedUids: {},
      duplicatedHotels: {},
      notFoundHotels: [],
      hotelsWithLocationError: new Set(),
      columnsAddress: ['HOTEL', 'HOTEL ADDRESS'],
      uploadOptions: [
        {
          id: 'file',
          name: 'File Import',
        },
      ],
      uploadType: UPLOAD_HOTEL_TYPES.FILE_IMPORT,
      isSuccess: false,
      hasUpload: false,
    };
  },
  components: {
    Modal,
    DragAndDrop,
    Switcher,
    UploadSuccess,
    Spinner,
  },
  computed: {
    duplicatesStats() {
      let avoidables = 0;
      let possibleDuplicates = 0;
      this.hotelsUnique.forEach((hotel) => {
        const searchableName = stringsKeysCreator(hotel.name);
        if (this.duplicatedHotels[searchableName]) {
          let matchFound = false;
          let missingAddrFull = false;
          // Iterate over every item in the duplicated hotels array
          this.duplicatedHotels[searchableName].forEach((hotelData) => {
            hotel['contacts'] = this.duplicatedHotels[searchableName][0].contacts ?? []
            if (hotelData.addrFull === hotel.addrFull) {
              matchFound = true;
            }
          });
        
          // Use the flag to update your counts
          if (matchFound) {
            avoidables++;
          } else {
            possibleDuplicates++;
          }
        }
      });
      return {
        avoidables,
        possibleDuplicates,
        newHotelsCount: this.hotelsUnique.length - avoidables - possibleDuplicates,
      };
    },
    importedHotels() {
      return [...this.successfulUids, ...Object.keys(this.failedUids)];
    },
    hotelsImporting() {
      return this.$store.state.hotelImporting;
    },
    fileHasUploaded() {
      return this.file !== null;
    },
    uploadTypes() {
      return UPLOAD_HOTEL_TYPES;
    },
    hotelsWithLocationErrCount() {
      return this.hotelsWithLocationError.size;
    },
  },
  watch: {
    file() {
      this.preUpload();
    },
    duplicatedHotels() {
      let avoidables = 0;
      let possibleDuplicates = 0;
      this.hotelsUnique.forEach((hotel) => {
        const searchableName = stringsKeysCreator(hotel.name);
        if (this.duplicatedHotels[searchableName]) {
          const hotelWithSameAddr = this.duplicatedHotels[searchableName].find(
            (hotelData) => hotelData.addrFull === hotel.addrFull
          );
          if (hotelWithSameAddr) {
            avoidables++;
          } else {
            possibleDuplicates++;
          }
        }
      });
      this.avoidables = avoidables;
      this.possibleDuplicates = possibleDuplicates;
      this.newHotelsCount = this.hotelsUnique.length - avoidables - possibleDuplicates;
    },
  },
  methods: {
    distance(lat1, lon1, lat2, lon2) {
      const R = 6371e3;
      const φ1 = (lat1 * Math.PI) / 180;
      const φ2 = (lat2 * Math.PI) / 180;
      const Δφ = ((lat2 - lat1) * Math.PI) / 180;
      const Δλ = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return (R * c);
    },
    // Updated method to clarify “found in DB” vs. “not found” phrasing
    detectOnUploadAction(hotel) {
      const searchableName = stringsKeysCreator(hotel.name);
      // If name is found in DB
      if (this.duplicatedHotels[searchableName]) {
        // If exact same address is found for that name
        const hotelWithSameAddr = this.duplicatedHotels[searchableName].find(
          (hotelData) => {
            return hotelData.addrFull === hotel.addrFull
          }
        );
        const hotelIncludesAddr = this.duplicatedHotels[searchableName].find((hotelData) => {
          return hotelData.searchableAddress.includes(hotel.addrFull.toLowerCase().replace(/[\s,]+/g, ''))
        })
        if (hotelWithSameAddr) {
          return 'Hotel found in DB ⇒ will be selected';
        } else if (hotelIncludesAddr || this.duplicatedHotels[searchableName][0].isIn100MeterRadius == true){
          return 'This hotel shares a name with one in the DB, but the address is within a 0.06 miles radius ⇒ will be selected';
        } else if (this.duplicatedHotels[searchableName][0].isIn100MeterRadius == false){
          return 'This hotel shares a name with one in the DB, but the address is not within a 0.06 miles radius ⇒ will not be selected';
        }
      }
      // If the name is not found at all
      return 'This hotel is not in the DB';
    },

    updateFile() {
      this.file = document.getElementById('fileModalUpload').files[0];
      this.preUpload();
    },
    async preUpload() {
      try {
        this.error = '';
        this.errorType = null;
        this.hasUpload = true;
        const file = this.file;
        this.isFileLoading = true;
      
        if (!file) return;
      
        // 1. Generate 'hotelsMap' + 'headers' from file
        this.expectedColumns = this.columnsAddress;
        const { headers, hotelsMap } = await mapFileToHotels(file, this.expectedColumns);
      
        if (Object.keys(hotelsMap).length === 0) {
          throw new Error('The uploaded file is empty or has no valid rows.');
        }
      
        this.hotelsWithLocationError = new Set();
      
        // 2. Gather unique hotels
        const uniqueHotels = Object.entries(hotelsMap).map(([hotelUid, hotelArr]) => {
          if (hotelArr[0]?.hasLocationError) {
            this.hotelsWithLocationError.add(hotelUid);
          }
          return {
            uid: hotelUid,
            name: hotelArr[0]?.name ?? '',
            addrFull: hotelArr[0]?.addrFull ?? '',
            contacts: hotelArr.filter((h) => h.hasContact).length,
            locationError: hotelArr[0]?.hasLocationError ?? false,
          };
        });
      
        // 3. Check DB for any existing names
        const uniqueHotelNames = uniqueHotels.map((h) => h.name);
        const duplicacyRes = await hotelsApi.checkExistingNameHotels(uniqueHotelNames);
      
        if (duplicacyRes.error) {
          alert(duplicacyRes.message || 'Some error occurred checking duplicacy');
          return;
        }
      
        this.duplicatedHotels = convertArrayToKeyMappedObjectArrays(
          duplicacyRes.data,
          'searchableName'
        );
        Object.keys(this.duplicatedHotels).forEach(key => {
          if (Array.isArray(this.duplicatedHotels[key])) {
            this.duplicatedHotels[key] = this.duplicatedHotels[key].filter(hotel => hotel.addrFull);
          }
        });


        this.notFoundHotels = uniqueHotels.filter((hotel) => {
          const searchableName = stringsKeysCreator(hotel.name);
          return !this.duplicatedHotels[searchableName];
        })

        this.noHotelFoundInCsv = this.notFoundHotels.length > 0 && Object.keys(this.duplicatedHotels).length === 0;
      
        // 4. Convert each hotel’s address to lat/lng in parallel
        //    Then, check possible duplicates in DB for each hotel in parallel as well.
        await processHotels(uniqueHotels, this.duplicatedHotels, this.noHotelFoundInCsv, this.distance)
        // 5. Populate data back on the component
        this.hotelsUnique = uniqueHotels;
        this.uniqueHotelsCount = uniqueHotels.length;
        this.correctColumns = headers.length === this.expectedColumns.length;
        this.isFileLoading = false;
        this.allHotels = hotelsMap;
      } catch (e) {
        console.log('Error reading file', e);
        this.file = null;
        this.errorType = 'file';
        this.isFileLoading = false;
        this.error = e.message || 'Some error occurred parsing the file';
      }
    },
    close() {
      this.$store.commit('setHotelImporting', null);
      this.$emit('close');
      this.hasUpload && this.$store.commit('setLastUploaded');
    },
    async selectHotels() {
      const uploadedToSelectHotels = this.duplicatedHotels
      
      // 1. Flatten the object values into a single array of hotel records
      const hotelRecords = Object.values(uploadedToSelectHotels).flat()
      const filteredHotelRecords = hotelRecords.filter((hotel) =>  hotel.addrIncludes || hotel.isIn100MeterRadius)

      // 2. Extract all the searchableNames
      const searchableNames = filteredHotelRecords.map(h => h.searchableName)

      // 3. Get Firestore docs in a minimal number of queries
      const hotelsFromFirestore = await this.getHotelsBySearchableNames(searchableNames)

      
      const finalSelectedHotels = hotelsFromFirestore.map((dbHotelsRecord) => {
        const matchedHotel = filteredHotelRecords.find((filteredHotel) => {
          // Ensure you're comparing the right properties in plain objects (not Proxy)
          const filteredHotelObj = { ...filteredHotel }; // Make sure it's a plain object
          const dbHotelObj = { ...dbHotelsRecord }; // Ensure dbHotel is also a plain object

          // Compare the address or any other property you want to match on
          return filteredHotelObj.searchableAddress === dbHotelObj.searchableAddress
        });
      
        // If a match is found, return the original dbHotelsRecord from hotelsFromFirestore
        return matchedHotel ? dbHotelsRecord : undefined;
      }).filter((hotel) => hotel !== undefined);

      const finalSelectedHotelsPlain = finalSelectedHotels.map(hotel => JSON.parse(JSON.stringify(hotel)));

      // 4. Do whatever you need with the aggregated result
      this.$store.commit("setSelectedHotels", finalSelectedHotelsPlain);
      this.close();
    },
    async downloadNotFoundHotels() {
      // 1. Define the CSV header
      let csvFile = "Hotel Name,Hotel Address\n";
        
      // 2. Loop through your notFoundHotels array to build CSV rows
      this.notFoundHotels.forEach((hotel) => {
        // Escape any existing double quotes by replacing " with ""
        const safeName = hotel.name.replace(/"/g, '""');
        const safeAddress = hotel.addrFull.replace(/"/g, '""');
      
        // Enclose the fields in double quotes
        let line = `"${safeName}","${safeAddress}"\n`;
      
        csvFile += line;
      });
    
      // 3. Create a Blob from the CSV string
      const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    
      // 4. Give your file a name (e.g., includes the current date)
      const date = moment().format("YYYY-MM-DD");
      const filename = `not-found-hotels-${date}.csv`;
    
      // 5. Trigger a download
      if (navigator.msSaveBlob) {
        // For IE/Edge
        navigator.msSaveBlob(blob, filename);
      } else {
        // For modern browsers
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    /**
     * Helper function to query Firestore for multiple searchableNames in minimal calls.
     * Firestore allows up to 10 values in an 'in' query clause.
     */
     async getHotelsBySearchableNames(searchableNames) {
      // Remove duplicates just in case
      const uniqueNames = [...new Set(searchableNames)]

      // We'll chunk them by 10 to respect the Firestore 'where ... in' limit
      const chunkSize = 10
      let allHotels = []

      for (let i = 0; i < uniqueNames.length; i += chunkSize) {
        const chunk = uniqueNames.slice(i, i + chunkSize)
        const qHotels = query(
          collection(firestore, "hotels"),
          where("searchableName", "in", chunk)
        )

        // Retrieve docs
        const snapshot = await getDocs(qHotels)
        snapshot.forEach(doc => {
          // push each document data or data + id
          allHotels.push({ id: doc.id, ...doc.data() })
        })
      }
      return allHotels
    },
    downloadTemplate() {
      downloadAssetFile(PUBLIC_FILES.BULK_IMPORT_HOTEL_SAMPLE);
    },
    handleFileUpload() {
      this.$refs.fileModalUpload.click();
    },
    onDropFile(event) {
      this.file = event.dataTransfer.files[0];
      this.preUpload();
    },
    setUploadType(option) {
      this.uploadType = option.id;
      this.error = '';
      this.errorType = null;
    },
    async handleSingleHotelUpload({ hotelData, contactData, isEdit = false, hotelId = '' }) {
      if (isEdit) {
        // Edit existing hotel
        const data = {
          ...hotelData,
          contacts: contactData.map((c) => ({
            name: c.contactName,
            email: c.contactEmail,
            phone: c.contactPhone || '',
            role: c.contactRole || '',
          })),
        };
        return hotelsApi.update(hotelId, data);
      } else {
        // Create a new hotel
        return hotelsApi
          .create({ ...hotelData })
          .then(async (response) => {
            if (response.error) {
              return response;
            }
            await Promise.all(
              contactData.map((contact) =>
                this.updateHotelContact({
                  hotelId: response.data,
                  name: contact.contactName || '',
                  email: contact.contactEmail || '',
                  phone: contact.contactPhone || '',
                  role: contact.contactRole || '',
                })
              )
            );
            return response;
          })
          .catch((error) => {
            alert(error.message);
            this.$emit('close');
            throw error;
          });
      }
    },
    async updateHotelContact(data) {
      return accountsApi.addUpdateContact(data);
    },
  },
  props: {
    editHotelId: {
      type: String,
      default: '',
    },
  },
};
</script>
