<template>
  <svg :width="width" :height="width" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8389 26.5242L15.4833 22.1681C15.2236 21.9088 14.8972 21.7759 14.5041 21.7697C14.1113 21.7638 13.7789 21.8966 13.507 22.1681C13.2355 22.44 13.0997 22.7694 13.0997 23.1562C13.0997 23.5431 13.2355 23.8725 13.507 24.1444L18.6525 29.2898C18.9916 29.6286 19.387 29.798 19.8389 29.798C20.2908 29.798 20.6862 29.6286 21.0253 29.2898L31.4569 18.8583C31.7163 18.5986 31.8491 18.2722 31.8553 17.8791C31.8613 17.4862 31.7284 17.1539 31.4569 16.882C31.185 16.6105 30.8556 16.4747 30.4688 16.4747C30.0819 16.4747 29.7525 16.6105 29.4806 16.882L19.8389 26.5242ZM22.5033 40.3125C20.0395 40.3125 17.7237 39.845 15.5559 38.91C13.3881 37.975 11.5025 36.7061 9.89906 35.1033C8.29562 33.5005 7.02609 31.6156 6.09047 29.4488C5.15516 27.2819 4.6875 24.9667 4.6875 22.5033C4.6875 20.0395 5.155 17.7237 6.09 15.5559C7.025 13.3881 8.29391 11.5025 9.89672 9.89906C11.4995 8.29562 13.3844 7.02609 15.5513 6.09047C17.7181 5.15516 20.0333 4.6875 22.4967 4.6875C24.9605 4.6875 27.2763 5.155 29.4441 6.09C31.6119 7.025 33.4975 8.29391 35.1009 9.89672C36.7044 11.4995 37.9739 13.3844 38.9095 15.5513C39.8448 17.7181 40.3125 20.0333 40.3125 22.4967C40.3125 24.9605 39.845 27.2763 38.91 29.4441C37.975 31.6119 36.7061 33.4975 35.1033 35.1009C33.5005 36.7044 31.6156 37.9739 29.4488 38.9095C27.2819 39.8448 24.9667 40.3125 22.5033 40.3125Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
  props: {
    color: {
      type: String,
      default: "#27AE60"
    },
    width: {
      type: Number,
      default: 18
    },
  }
}
</script>
