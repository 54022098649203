<template>
    <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_129_8958" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="18">
            <rect x="0.335938" width="18" height="18" :fill="color" />
        </mask>
        <g mask="url(#mask0_129_8958)">
            <path
                d="M4.31675 15.375C3.93787 15.375 3.61719 15.2438 3.35469 14.9813C3.09219 14.7188 2.96094 14.3981 2.96094 14.0192V3.98081C2.96094 3.60194 3.09219 3.28125 3.35469 3.01875C3.61719 2.75625 3.93787 2.625 4.31675 2.625H14.3551C14.734 2.625 15.0547 2.75625 15.3172 3.01875C15.5797 3.28125 15.7109 3.60194 15.7109 3.98081V14.0192C15.7109 14.3981 15.5797 14.7188 15.3172 14.9813C15.0547 15.2438 14.734 15.375 14.3551 15.375H4.31675ZM14.5859 5.75475L10.1495 10.725L7.64694 8.22262C7.51144 8.087 7.35331 8.01919 7.17256 8.01919C6.99169 8.01919 6.8335 8.087 6.698 8.22262L4.08594 10.8347V12.4384L7.17256 9.35194L9.69069 11.8702C9.82631 12.0057 9.9915 12.0716 10.1862 12.0677C10.3809 12.0639 10.5421 11.988 10.67 11.8399L14.5859 7.44225V5.75475Z"
                fill="#3E24F6" />
        </g>
    </svg>

</template>
<script>
export default {
    computed: {
        height() {
            return this.width * 18 / 19
        }
    },
    props: {
        color: {
            type: String,
            default: '#D9D9D9'
        },
        width: {
            type: Number,
            default: 19
        }
    },
}
</script>