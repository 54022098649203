<template>
<AccountBg>
    <CreateAccountCard :prev-email="prevEmail" :prev-email-err="prevEmailErr" :loading="loading" :signup-error="error" :signup="handleAgencySignup" />
</AccountBg>
</template>
<script lang="ts">
import AccountBg from '@/components/ui/organism/Account/AccountBg/AccountBg.vue';
import ResetEmailSent from '@/components/ui/organism/Account/ResetEmailSent/ResetEmailSent.vue';
import CreateAccountCard from '@/components/ui/organism/Account/CreateAccountCard';
import { AgencySignupData } from '../../organism/Account/CreateAccountCard/CreateAccountCard.vue';
import { agencyApi } from '@/utils/apis/agencyApi';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@/utils/firebase';


export default {
    mounted(){
        document.title = 'Create Account | CrewFare';
    },
    data() {
        return {
            loading: false,
            error: '',
            done: false,
            prevEmail: '',
            prevEmailErr: ''
        }
    },
    components: {
        AccountBg,
        CreateAccountCard,
        ResetEmailSent
    },
    methods:{
        async handleAgencySignup(data: AgencySignupData){
            this.loading = true;
            this.prevEmail = data.email;
            this.prevEmailErr = ''
            this.error = ''
            const res = await agencyApi.signup(data)
            console.log("Res", res)
            if(res.error){
                if(res.email){
                    this.prevEmailErr = res.email;
                }
                else{
                    this.error = res.message || "There was some error signing up for agency account"
                }
            }
            else{
            signInWithEmailAndPassword(
            auth,
            data.email.toLowerCase(),
            data.password
        )
            .then(async (result) => {
                this.$router.push("/")
            })
            .catch((e) => {
            this.loading = false;
            this.error = e.message || "Account was signed up but there was some issue logging in";
            });
            }
            this.loading = false;
        }
    }
}
</script>