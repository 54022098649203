export const accentedCharacters =
  'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ';

export const isValidName = (name: string) => {
  const validInitials = 'a-zA-Z' + accentedCharacters;
  const regex =
    '^[' +
    validInitials +
    ']+' +
    '([ ]?([' +
    validInitials +
    "'’-][ ]?)?" +
    '[' +
    validInitials +
    '])+$';
  // Create a RegExp from the string version
  const regexCompiled = new RegExp(regex);
  return regexCompiled.test(name);
};
