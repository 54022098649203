import * as moment1 from 'moment';
import moment2 from 'moment';
export function getMomentFromFirebaseDate(obj) {
  const moment = moment1.unix ? moment1 : moment2;
  return obj._seconds !== undefined
    ? moment.unix(obj._seconds)
    : moment(obj.toDate());
}

export function getDateObjFromFirebaseDate(obj) {
  const moment = getMomentFromFirebaseDate(obj);
  return moment.toDate();
}
