<template>
  <div class=" flex flex-col bg-lpWhite p-4 rounded-[0.75rem] gap-y-2 hover:shadow-md cursor-pointer w-full max-w-sm ">
    <Icon :name="iconName" :width="19" />
    <div class="flex flex-col gap-y-1">
      <Text variant="h3" color="black" thickness="semiBold" :size="titleFontSize">{{ title }}</Text>
      <Text variant="p" color="gray" thickness="normal" :size="descriptionFontSize">{{ content }}</Text>
    </div>
  </div>
</template>
<script lang="ts">
import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import { PropType } from 'vue';
import { IconNames } from '../../atoms/Icon/Icon.vue';
import { sizes } from '../../atoms/Text/Text.vue';

export default {
  components: {
    Text,
    Icon
  },
  props: {
    title: String,
    content: String,
    iconName: {
      type: String as PropType<IconNames>,
      default: 'feature'
    },
    titleFontSize: {
      type: String as PropType<(typeof sizes)[number]>,
      default: null,
    },
    descriptionFontSize: {
      type: String as PropType<(typeof sizes)[number]>,
      default: null,
    }
  }
}
</script>