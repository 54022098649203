<template>
  <div
    class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
  >
    <div
      class="bg-gray-900 max-h-full rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center min-w-[900px]"
    >
      <div class="px-4 pt-4 flex justify-between items-center gap-4 w-full">
        <p class="text-xl px-4">Contract</p>
        <span
          v-if="editHotelFields"
          @click="handleClose"
          class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
        >
          close
        </span>
      </div>
      <p class="text-sm px-4" v-if="isHotel">
        Please fill out the information below based on the property in which you
        are contracting our group with.
      </p>
      <p v-else class="text-sm px-4">
        Before reviewing or submitting a contract please provide us with the
        following information:
      </p>
      <div v-if="isHotel" class="flex gap-4 px-4 overflow-auto max-h-[70vh]">
        <div class="flex flex-col gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">{{editHotelFields ? "Hotel Person Name": "Your Name"}}</label>
            <input
              type="text"
              v-model="hotel_person_name"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Title</label>
            <input
              type="text"
              v-model="hotel_title"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Address</label>
            <input
              type="text"
              v-model="hotel_address"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Telephone</label>
            <input
              type="text"
              v-model="hotel_telephone"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Email</label>
            <input
              type="text"
              v-model="hotel_email"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Check-In Age:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_check_in_age"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Check-In Time:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_check_in_time"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Check-Out Time:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_check_out_time"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Wifi:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="wifi"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Parking:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="parking"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Breakfast:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="breakfast"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Other Fees:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="other_fees"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 w-full">
          <p class="text-xl -mb-3">Taxes:</p>
          <p class="text-white text-base -mb-3">
            If something does not apply please input the number zero. Please
            indicate $ or %.
          </p>
          <div class="flex flex-col mt-[2px] gap-1 w-full">
            <label class="text-white font-bold">Local Occupancy Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_local_occupancy_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">State Occupancy Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_state_occupancy_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">City Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_city_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold"
              >Convention & Tourism Tax:</label
            >
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_convention_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">VAT Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_vat_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">SAT Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_sat_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold"
              >Municipal Accommodation Tax:</label
            >
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_municipal_accomodation_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">HST Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_hst_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Sustaintability Fee:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_sustaintability_fee"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Other Taxes:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_taxes"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isHotel" class="flex px-4 gap-4 flex-col overflow-y-scroll custom-scrollbar">
        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Company
            </label>
            <Input
              v-model="companyName"
              variant='dark'
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Name
            </label>
            <Input
              v-model="crewfare_name"
              variant='dark'
            />
          </div>
        </div>

        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Title
            </label>
            <Input
              v-model="crewfare_title"
              variant='dark'
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Address
            </label>
            <Input
              v-model="companyAddress"
              variant='dark'
            />
          </div>
        </div>

        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Telephone
            </label>
            <Input
              v-model="companyTelephone"
              variant='dark'
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Email
            </label>
            <Input
              v-model="companyEmail"
              variant='dark'
              @blur="validateCompanyEmail"
              :is-error="!!companyEmailErr"
              :error-message="companyEmailErr"
            />
          </div>
        </div>

        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Event Planner Name
            </label>
            <Input
              v-model="eventPlannerName"
              variant='dark'
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Event Planner Rewards Number
            </label>
            <Input
              v-model="crewfare_members"
              variant='dark'
            />
          </div>
        </div>

        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold flex items-center gap-x-2">
              POC Team Name:
              <InfoTip>
                Point of Contact for the Rooming List.
              </InfoTip>
            </label>
            <Input
              v-model="pocTeamName"
              variant='dark'
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              POC Team Email
            </label>
            <Input
              v-model="pocTeamEmail"
              variant='dark'
              @blur="validatePocTeamEmail"
              :is-error="!!pocTeamEmailErr"
              :error-message="pocTeamEmailErr"
            />
          </div>
        </div>
        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold flex items-center gap-x-2">
              Accounting Team Name:
              <InfoTip>
                Point of Contact for the Deposit Schedule.
              </InfoTip>
            </label>
            <Input
              v-model="accountingTeamName"
              variant='dark'
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">
              Accounting Team Email
            </label>
            <Input
              v-model="accountingTeamEmail"
              variant='dark'
              @blur="validateAccountingTeamEmail"
              :is-error="!!accountingTeamEmailErr"
              :error-message="accountingTeamEmailErr"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">
              Signature
          </label>
          <SignaturePad :signature-data="proposalGroup.defaultSign" ref="signatureField" />
        </div>
      </div>
      <div class="flex justify-end px-4 gap-4">
        <label v-if="!isHotel" @click="setAsDefault=!setAsDefault" class="text-white font-bold flex items-center gap-2">
              <Checkbox :selected="setAsDefault" />
              Set as Default
        </label>
        <button @click="saveFields" class="green-button gap-2">
          <span v-if="savingFields" class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Save
        </button>
      </div>
    </div>
    <div
      v-if="!!errorMessage"
      class="z-40 fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
      ></div>
      <div
        class="bg-gray-900 z-[101] rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center p-4 items-center"
      >
        <p class="text-sm text-center px-4">{{ errorMessage }}</p>
        <div class="flex px-4">
          <button @click="errorMessage = ''" class="cancel-button">Dismiss</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { doc, setDoc } from "firebase/firestore";
import { setStatusHistory } from "@/utils/rfp.jsx";
import { contractsApi } from "@/utils/apis/contractsApi";
import { isValidEmail } from "@crewfare/server-shared";
import Input from "@/components/ui/atoms/Input";
import SignaturePad from "@/components/ui/atoms/SignaturePad/SignaturePad.vue";
import Checkbox from "@/components/ui/atoms/Checkbox.vue";
import InfoTip from "@/components/ui/atoms/InfoTip.vue";

export default {
  name: "ContractFields",
  props: ["rfp", "proposalGroup", "isHotel", "account", "editHotelFields", "closeDialog"],
  components: {
    Input,
    SignaturePad,
    Checkbox,
    InfoTip
  },
  computed: {
    isValidEmpty() {
      if (this.isHotel) {
        return (
          this.hotel_person_name.length < 1 ||
          this.hotel_address.length < 1 ||
          this.hotel_email.length < 1 ||
          this.hotel_title.length < 1 ||
          this.hotel_taxes.length < 1 ||
          this.hotel_check_in_age.length < 1 ||
          this.hotel_check_in_time.length < 1 ||
          this.hotel_check_out_time.length < 1 ||
          this.hotel_local_occupancy_tax.length < 1 ||
          this.hotel_state_occupancy_tax.length < 1 ||
          this.hotel_city_tax.length < 1 ||
          this.hotel_convention_tax.length < 1 ||
          this.hotel_vat_tax.length < 1 ||
          this.hotel_sat_tax.length < 1 ||
          this.hotel_municipal_accomodation_tax.length < 1 ||
          this.hotel_hst_tax.length < 1 ||
          this.hotel_sustaintability_fee.length < 1 ||
          this.wifi.length < 1 ||
          this.parking.length < 1 ||
          this.breakfast.length < 1 ||
          this.other_fees.length < 1
        );
      }
      return (
        this.crewfare_members.length < 1 ||
        this.crewfare_name.length < 1 ||
        this.crewfare_title.length < 1 ||
        this.companyName.length < 1 ||
        this.companyAddress.length < 1 ||
        this.companyTelephone.length < 1 ||
        this.companyEmail.length < 1 ||
        this.eventPlannerName.length < 1 ||
        this.pocTeamName.length < 1 ||
        this.pocTeamEmail.length < 1
      );
    },
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      pocTeamEmailErr: "",
      companyEmailErr: "",
      savingFields: false,
      error: false,
      hotel_signature: this.rfp?.hotel_signature || "",
      hotel_name: this.rfp?.hotel_name || "",
      hotel_person_name: this.rfp?.hotel_person_name || "",
      hotel_address: this.rfp?.hotel_address || "",
      hotel_email: this.rfp?.hotel_email || "",
      hotel_telephone: this.rfp?.hotel_telephone || "",
      hotel_title: this.rfp?.hotel_title || "",
      hotel_date: this.rfp?.hotel_date || "",
      crewfare_date: this.rfp?.crewfare_date || "",
      crewfare_name: this.rfp?.crewfare_name || this.proposalGroup?.crewfare_name || "",
      crewfare_title: this.rfp?.crewfare_title || this.proposalGroup?.crewfare_title || "",
      crewfare_members: this.rfp?.crewfare_members || this.proposalGroup?.crewfare_members || "",
      hotel_taxes: this.rfp?.hotel_taxes || "",
      hotel_other_fees: this.rfp?.hotel_other_fees || "",
      hotel_check_in_age: this.rfp?.hotel_check_in_age || "",
      hotel_check_in_time: this.rfp?.hotel_check_in_time || "",
      hotel_check_out_time: this.rfp?.hotel_check_out_time || "",
      hotel_local_occupancy_tax: this.rfp?.hotel_local_occupancy_tax || "",
      hotel_state_occupancy_tax: this.rfp?.hotel_state_occupancy_tax || "",
      hotel_city_tax: this.rfp?.hotel_city_tax || "",
      hotel_convention_tax: this.rfp?.hotel_convention_tax || "",
      hotel_vat_tax: this.rfp?.hotel_vat_tax || "",
      hotel_sat_tax: this.rfp?.hotel_sat_tax || "",
      hotel_municipal_accomodation_tax: this.rfp?.hotel_municipal_accomodation_tax || "",
      hotel_hst_tax: this.rfp?.hotel_hst_tax || "",
      hotel_sustaintability_fee: this.rfp?.hotel_sustaintability_fee || "",
      wifi: this.rfp?.wifi || "",
      parking: this.rfp?.parking || "",
      breakfast: this.rfp?.breakfast || "",
      other_fees: this.rfp?.other_fees || "",
      companyName: this.rfp?.companyName || this.proposalGroup?.companyName || "",
      companyAddress: this.rfp?.companyAddress || this.proposalGroup?.companyAddress || "",
      companyTelephone: this.rfp?.companyTelephone || this.proposalGroup?.companyTelephone || "",
      companyEmail: this.rfp?.companyEmail || this.proposalGroup?.companyEmail || "",
      eventPlannerName: this.rfp?.eventPlannerName || this.proposalGroup?.eventPlannerName || "",
      pocTeamName: this.rfp?.pocTeamName || this.proposalGroup?.pocTeamName || "",
      pocTeamEmail: this.rfp?.pocTeamEmail || this.proposalGroup?.pocTeamEmail || "",
      accountingTeamName: this.rfp?.accountingTeamName || this.proposalGroup?.accountingTeamName || "",
      accountingTeamEmail: this.rfp?.accountingTeamEmail || this.proposalGroup?.accountingTeamEmail || "",
      accountingTeamEmailErr: "",
      setAsDefault: false,
    };
  },
  watch: {
    hotel_taxes() {
      this.hotel_taxes = this.validate(this.hotel_taxes);
    },
    hotel_check_in_age() {
      this.hotel_check_in_age = this.validate(this.hotel_check_in_age);
    },
    hotel_local_occupancy_tax() {
      this.hotel_local_occupancy_tax = this.validate(
        this.hotel_local_occupancy_tax
      );
    },
    hotel_check_in_time() {
      this.hotel_check_in_time = this.validate(this.hotel_check_in_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_state_occupancy_tax() {
      this.hotel_state_occupancy_tax = this.validate(
        this.hotel_state_occupancy_tax
      );
    },
    hotel_city_tax() {
      this.hotel_city_tax = this.validate(this.hotel_city_tax);
    },
    hotel_convention_tax() {
      this.hotel_convention_tax = this.validate(this.hotel_convention_tax);
    },
    hotel_vat_tax() {
      this.hotel_vat_tax = this.validate(this.hotel_vat_tax);
    },
    hotel_sat_tax() {
      this.hotel_sat_tax = this.validate(this.hotel_sat_tax);
    },
    hotel_municipal_accomodation_tax() {
      this.hotel_municipal_accomodation_tax = this.validate(
        this.hotel_municipal_accomodation_tax
      );
    },
    hotel_municipal_accomodation_tax() {
      this.hotel_municipal_accomodation_tax = this.validate(
        this.hotel_municipal_accomodation_tax
      );
    },
    hotel_hst_tax() {
      this.hotel_hst_tax = this.validate(this.hotel_hst_tax);
    },
    hotel_sustaintability_fee() {
      this.hotel_sustaintability_fee = this.validate(
        this.hotel_sustaintability_fee
      );
    },
  },
  methods: {
    validateCompanyEmail() {
      if ( this.companyEmail && !isValidEmail(this.companyEmail)) {
        this.companyEmailErr = "Please enter a valid email";
      } else {
        this.companyEmailErr = "";
      }
    },
    validatePocTeamEmail() {
      if (this.pocTeamEmail && !isValidEmail(this.pocTeamEmail)) {
        this.pocTeamEmailErr = "Please enter a valid email";
      } else {
        this.pocTeamEmailErr = "";
      }
    },
    validateAccountingTeamEmail() {
      if (this.accountingTeamEmail && !isValidEmail(this.accountingTeamEmail)) {
        this.accountingTeamEmailErr = "Please enter a valid email";
      } else {
        this.accountingTeamEmailErr = "";
      }
    },
    validate(value) {
      return value.replace(/[^\d$% .,:]/g, "");
    },
    async saveFields() {
      this.savingFields = true;
      if (this.isValidEmpty) {
        this.error = true;
        this.errorMessage = "Please fill out all fields";
        return;
      }
      let data = {};
      if (this.isHotel) {
        if(!isValidEmail(this.hotel_email)){
          this.error = true;
          this.errorMessage = "Please enter a valid email";
          this.savingFields = false;
          return;
        }
        data = {
          hotel_person_name: this.hotel_person_name,
          hotel_address: this.hotel_address,
          hotel_email: this.hotel_email,
          hotel_telephone: this.hotel_telephone,
          hotel_title: this.hotel_title,
          hotel_taxes: this.hotel_taxes,
          hotel_check_in_age: this.hotel_check_in_age,
          hotel_check_in_time: this.hotel_check_in_time,
          hotel_check_out_time: this.hotel_check_out_time,
          hotel_local_occupancy_tax: this.hotel_local_occupancy_tax,
          hotel_state_occupancy_tax: this.hotel_state_occupancy_tax,
          hotel_city_tax: this.hotel_city_tax,
          hotel_convention_tax: this.hotel_convention_tax,
          hotel_vat_tax: this.hotel_vat_tax,
          hotel_sat_tax: this.hotel_sat_tax,
          hotel_municipal_accomodation_tax:
            this.hotel_municipal_accomodation_tax,
          hotel_hst_tax: this.hotel_hst_tax,
          hotel_sustaintability_fee: this.hotel_sustaintability_fee,
          wifi: this.wifi,
          parking: this.parking,
          breakfast: this.breakfast,
          other_fees: this.other_fees,
        };
      } else {
        const signData = await this.$refs.signatureField.getSignature()
        if (!signData) {
          this.error = true;
          this.errorMessage = "Please add the default signature";
          this.savingFields = false;
          return;
        }
        if(!isValidEmail(this.companyEmail)){
          this.error = true;
          this.errorMessage = "Please enter a valid company email";
          this.savingFields = false;
          return;
        }
        if(!isValidEmail(this.pocTeamEmail)){
          this.error = true;
          this.errorMessage = "Please enter a valid POC team email";
          this.savingFields = false;
          return;
        }
        data = {
          crewfare_members: this.crewfare_members,
          crewfare_name: this.crewfare_name,
          crewfare_title: this.crewfare_title,
          companyName: this.companyName,
          companyAddress: this.companyAddress,
          companyTelephone: this.companyTelephone,
          companyEmail: this.companyEmail,
          eventPlannerName: this.eventPlannerName,
          pocTeamName: this.pocTeamName,
          pocTeamEmail: this.pocTeamEmail,
          accountingTeamName: this.accountingTeamName,
          accountingTeamEmail: this.accountingTeamEmail,
          defaultSign: signData,
          setAsDefault: this.setAsDefault
        };
      }
      const updatedRes = await contractsApi.update(this.rfp.id, {
        fieldsType: this.isHotel ? "hotel" : "source",
        data,
      });
      if(updatedRes.error){
        this.error = true;
        this.errorMessage = updatedRes.message;
        this.savingFields = false;
        return;
      }
      this.$emit("get_data");
      document.body.style.overflow = '';
      this.closeDialog();
    },
    async saveUpdateContract({ data }) {
      const rfpData = {
        ...this.rfp,
        ...data,
      };
      const rfpDataStatusHistory = await setStatusHistory({
        data: rfpData,
        content: "Contract Field Updated",
        status: rfpData.status,
      });
      await setDoc(
        doc(firestore, "rfp_hotel", this.rfp.id),
        rfpDataStatusHistory
      );
    },
    handleClose() {
      document.body.style.overflow = '';
      this.closeDialog();
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
    console.log("RFP", this.rfp);
  },
  beforeUnmount() {
    document.body.style.overflow = '';
  }
};
</script>

<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
