<template>
    <svg :width="width" :height="height" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M18.51 19.4137C20.415 19.4137 22.0312 18.7469 23.3587 17.4131C24.6862 16.0794 25.3506 14.46 25.3519 12.555C25.3531 10.65 24.6862 9.03313 23.3512 7.70438C22.0162 6.37563 20.3969 5.71125 18.4931 5.71125C16.5894 5.71125 14.9725 6.37813 13.6425 7.71187C12.3125 9.04562 11.6481 10.6656 11.6494 12.5719C11.6506 14.4781 12.3175 16.0944 13.65 17.4206C14.9825 18.7469 16.6025 19.4113 18.51 19.4137ZM18.5006 17.625C17.0944 17.625 15.8994 17.1331 14.9156 16.1494C13.9319 15.1656 13.4394 13.97 13.4381 12.5625C13.4369 11.155 13.9294 9.96 14.9156 8.9775C15.9019 7.995 17.0969 7.5025 18.5006 7.5C19.9044 7.4975 21.1 7.99 22.0875 8.9775C23.075 9.965 23.5669 11.16 23.5631 12.5625C23.5594 13.965 23.0675 15.1606 22.0875 16.1494C21.1075 17.1381 19.9119 17.63 18.5006 17.625ZM18.5006 24.75C14.7931 24.75 11.3931 23.7688 8.30063 21.8063C5.20813 19.8438 2.71062 17.2725 0.808125 14.0925C0.651875 13.8337 0.540625 13.5794 0.474375 13.3294C0.408125 13.0794 0.375 12.8231 0.375 12.5606C0.375 12.2981 0.408125 12.0419 0.474375 11.7919C0.540625 11.5419 0.651875 11.2887 0.808125 11.0325C2.71187 7.8525 5.20938 5.28125 8.30063 3.31875C11.3919 1.35625 14.7919 0.375 18.5006 0.375C22.2094 0.375 25.6094 1.35625 28.7006 3.31875C31.7919 5.28125 34.29 7.85313 36.195 11.0344C36.3512 11.2906 36.4625 11.5437 36.5287 11.7938C36.595 12.0438 36.6275 12.3006 36.6263 12.5644C36.625 12.8281 36.5925 13.0844 36.5287 13.3331C36.465 13.5819 36.3537 13.835 36.195 14.0925C34.2913 17.2738 31.7931 19.8456 28.7006 21.8081C25.6081 23.7706 22.2081 24.7512 18.5006 24.75Z" 
            :fill="color"
        />
    </svg>
</template>
<script lang="ts">
export default {
    computed: {
        height(){
            return (25*this.width)/37
        }
    },
    props: {
        color: {
            type: String,
            default: '#D9D9D9'
        },
        width: {
            type: Number,
            default: 37
        }
    },
}
</script>