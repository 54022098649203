export enum OnBoardingStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
}

export enum OnBoardingSteps {
  CHOOSE_A_PLAN = 'CHOOSE_A_PLAN',
  IATA = 'IATA',
  TEAM_INVITE = 'TEAM_INVITE',
  AGENCY_CONFIRMATION = 'AGENCY_CONFIRMATION',
  COMPLETED = 'COMPLETED',
}

export enum PlanTypes {
  FREE = 'free',
  ENTERPRISE = 'enterprise',
  FULL_ACCESS = 'FULL_ACCESS', //No Billing required
}

export enum BILLING_FREQUENCY {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}
