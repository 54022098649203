<template>
  <div>
    <div class="p-4 flex justify-between items-center">
      <div class="flex gap-4 items-center">
        <span>RFPS</span>
        <fwb-select
          v-if="search.length === 0"
          v-model="tab"
          @change="toggleTab()"
          :options="options"
          class="text-white bg-gray-700"
        />
        <fwb-select
          v-if="['archived', 'allActive'].includes(tab) && createdByListOptions.length > 0"
          v-model="createdBy"
          @change="filterByCreatedBy()"
          :options="createdByListOptions"
          class="text-white bg-gray-700"
        />
      </div>
      <router-link
        v-if="showViewAll"
        to="/rfps"
        class="border border-white px-6 py-1 rounded-full"
        >View all</router-link
      >
      <div class="flex gap-4">
        <CreateRFPDialog v-if="showCreate" />
        <input
          type="search"
          v-model="search"
          class="bg-gray-700 rounded px-4"
          placeholder="Search..."
        />
      </div>
    </div>
    <div class="flex flex-col rounded-lg pb-4 relative bg-[#23262F]">
      <div class="w-full justify-between py-2 border-t border-gray-700">
        <table class="w-full py-2">
          <thead class="py-2 text-white border-b border-gray-700">
            <tr>
              <th class="px-4 font-regular text-left">RFP Name</th>
              <th class="px-4 font-regular text-left">Venue</th>
              <th class="px-4 font-regular text-left">Event Dates</th>
              <th class="px-4 font-regular text-left">RFP Goals</th>
              <th class="px-4 font-regular text-left">Updates</th>
            </tr>
          </thead>
          <tbody v-if="!loading" class="divide-y divide-dashed divide-gray-700">
            <ListItem
              v-for="rfp in rfps"
              :key="rfp.id"
              :rfp="rfp"
              @getData="refreshData"
            />
          </tbody>
        </table>
        <div v-if="loading" class="my-6 flex items-center justify-center">
          <span class="material-symbols-rounded animate-spin text-[32px]">
            autorenew
          </span>
        </div>
        <Pagination
          v-if="!loading"
          :actual="currentPage"
          :max="per_page"
          :total="total"
          @togglePage="togglePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/rfps/lists/admin/ListItem.vue";
import Pagination from "@/components/default/Pagination.vue";
import CreateRFPDialog from "@/components/rfps/CreateRFPDialog.vue";
import { FwbSelect } from "flowbite-vue";
import { rfpsApi } from "@/utils/apis/rfpsApi";
import { debounce } from "@/utils/formatter";
import {RFPStatus} from "@crewfare/server-shared"
import { isCrewfareAccount } from "@/utils/account";

export default {
  props: ["showViewAll", "showCreate"],
  components: {
    ListItem,
    Pagination,
    CreateRFPDialog,
    FwbSelect,
  },
  data() {
    return {
      options: [
        {
          value: "myRFPs",
          name: "My RFPs",
        },
        {
          value: "allActive",
          name: "All Active RFPs",
        },
        {
          value: "myDrafts",
          name: "My Drafts",
        },
        {
          value: "archived",
          name: "Archived RFPs",
        },
      ],
      createdBy: "all",
      createdById: "",
      currentPage: 0,
      tab: "myRFPs",
      rfps: [],
      loading: false,
      search: "",
      createDialogToggle: false,
      createdByListOptions:[],
      isDraft: false,
      statusFilters: [],
      total: 0,
      per_page: 20,
      signals: {
        list: {
          controller: new AbortController(),
          count: 0,
        }
      }
    };
  },
  watch: {
    search: debounce(function(){
      this.statusFilters = []
      this.createdBy = "all"
      this.refreshData();
    }, 1000),
    selectedGroupId(){
      this.refreshData();
    }
  },
  computed: {
    isCrewfare(){
      return isCrewfareAccount(this.account);
    },
    account() {
      const account = this.$store.state.account;
      return account;
    },
    selectedGroupId(){
      return this.$store.state.groups.selectedId || '';
    }
  },
  async mounted() {
    if (
      this.account &&
      !this.isCrewfare
    ) {
      router.push({ name: "rfpsList" });
      return false;
    }
    this.toggleTab();
    document.title = `Launchpad - Crewfare - RFPs`;
  },
  methods: {
    async listRFP(listCount, signal){
      console.log("Listing for count", listCount)
      let rfpOptions = {
        per_page: this.per_page,
        page: this.currentPage,
        statusFilters: JSON.stringify(this.statusFilters),
        search: this.search,
        by: this.createdBy,
        by_id: this.createdById
      }
      const rfps = await rfpsApi.list(rfpOptions, signal).then((res) => res.data||[]);
      if(listCount == this.signals.list.count){
        console.log("Result came for count and is lates", listCount)
        this.rfps = rfps?.rfps||[];
        this.total = rfps?.total||0;
        this.loading = false;
        this.createdByListOptions = rfps?.createdByListOptions||[];
      }
      else{
        console.log("Result came for count and is old", listCount)
      }
    },
    async refreshData(){
      this.loading = true;
      this.signals.list.controller.abort();
      this.signals.list.controller = new AbortController();
      this.signals.list.count++;
      const currentCount = this.signals.list.count;
      this.listRFP(currentCount);
    },
    async toggleTab() {
      this.currentPage = 0;
      this.loading = true;
      this.rfpsFiltered = [];
      if (this.tab === "myRFPs") {
        this.createdBy = this.account.name;
        this.createdById = this.account.id;
        this.statusFilters = [RFPStatus.SENT];
      }
      if (this.tab === "allActive") {
        this.createdBy = "all";
        this.createdById = "";
        this.statusFilters = [RFPStatus.SENT];
      }
      if (this.tab === "myDrafts") {
        this.createdBy = this.account.name;
        this.createdById = this.account.id;
        this.statusFilters = [RFPStatus.NEW, RFPStatus.DRAFT];
      }
      if (this.tab === "archived") {
        this.createdBy = "all";
        this.createdById = "";
        this.statusFilters = [RFPStatus.COMPLETED];
      }
      this.refreshData();
    },
    togglePage(page) {
      this.currentPage = page;
      this.refreshData();
    },
    filterByCreatedBy() {
      // Reset to first page
      this.currentPage = 0;
      
      // Look for the option that matches the selected createdBy value
      const selectedOption = this.createdByListOptions.find(
        (option) => option.value === this.createdBy
      );
      
      // If an option was found, update createdById; otherwise, clear it
      this.createdById = selectedOption ? selectedOption.id : '';
      
      // Now refresh the data with both createdBy and createdById in the API call
      this.refreshData();
    },
  },
};
</script>
