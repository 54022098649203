<template>
  <div v-if="truncate" class="group">
    <component
      :data-contentWidth="contentWidth"
      :data-textwidth="textWidth"
      class="peer"
      :is="variant"
      :class="classes"
    >
      <slot></slot>
    </component>
    <p v-if="isTruncated" class="relative hidden group-hover:block">
      <span class="absolute top-0 left-0 bg-crewfareGreyLight p-1 whitespace-nowrap"><slot></slot></span>
    </p>
  </div>
  <component v-else class="peer" :is="variant" :class="classes">
    <slot></slot>
  </component>
</template>
<script lang="ts">
import { PropType } from 'vue';

export const variants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'p', 'span'] as const;
export const colors = [
  'black',
  'dark',
  'thickGray',
  'gray',
  'lightGray',
  'blue',
  'purple',
  'lightPurple',
  'white',
  'red',
  'lavenderGray',
  'green',
] as const;
export const thickness = ['thin', 'light', 'normal', 'medium', 'semiBold', 'bold'] as const;
export const alignments = ['left', 'center', 'right', 'justify'] as const;
export const sizes = ['40', '32', '28', '24', '20', '18', '16', '14', '12'] as const;
export default {
  mounted() {
    this.calculateTextWidth();
  },
  created() {
    window.addEventListener('resize', this.calculateTextWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateTextWidth);
  },
  data() {
    return {
      isTruncated: false,
      textWidth: 0,
      contentWidth: 0,
    };
  },
  props: {
    variant: {
      type: String as PropType<(typeof variants)[number]>,
      default: 'h1',
    },
    color: {
      type: String as PropType<(typeof colors)[number]>,
      default: 'dark',
    },
    thickness: {
      type: String as PropType<(typeof thickness)[number]>,
      default: 'medium',
    },
    align: {
      type: String as PropType<(typeof alignments)[number]>,
      default: 'left',
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<(typeof sizes)[number]>,
      default: null,
    },
  },

  watch: {
    truncate() {
      this.calculateTextWidth();
    },
  },

  computed: {
    textClass() {
      const defaultSizeMap = {
        h1: 'text-40',
        h2: 'text-16',
        h3: 'text-14',
        h4: 'text-24',
        h5: 'text-20',
        h6: 'text-18',
        label: 'text-14',
        p: 'text-12',
        span: '',
      };
      return this.size ? `text-${this.size}` : defaultSizeMap[this.variant];
    },
    classes() {
      const classMap = {
        h1: 'leading-[3rem]',
        h2: 'leading-[1.5rem]',
        h3: '',
        h4: '',
        h5: '',
        h6: '',
        label: '',
        p: '',
        span: '',
        black: 'text-lpBlack',
        dark: 'text-lpDark',
        gray: 'text-lpGray',
        lightGray: 'text-lpLightGray',
        thickGray: 'text-lpThickGray',
        lavenderGray: 'text-lpLavenderGray',
        purple: 'text-lpPurple',
        lightPurple: 'text-lpLightPurple',
        white: 'text-lpWhite',
        thin: 'font-thin',
        light: 'font-[300]',
        normal: 'font-normal',
        medium: 'font-[500]',
        semiBold: 'font-semibold',
        bold: 'font-bold',
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
        justify: 'text-justify',
        blue: 'text-lpBlue',
        red: 'text-lpRed',
        green: 'text-lpGreen',
        truncate: ' whitespace-nowrap text-ellipsis overflow-hidden',
      };
      return `
                ${classMap[this.variant]} 
                ${classMap[this.color]} 
                ${classMap[this.thickness]} 
                ${classMap[this.align]} 
                ${this.truncate ? classMap['truncate'] : ''}
                ${this.textClass}
                `;
    },
  },
  methods: {
    calculateTextWidth() {
      if (!this.truncate) return;
      const contentText = this.$el.textContent;
      const contentWidth = this.$el.offsetWidth;
      const fontSize = window.getComputedStyle(this.$el).fontSize;
      const fontFamily = window.getComputedStyle(this.$el).fontFamily;
      //Compute font width using canvas method
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return;
      //Set the font size and font family
      context.font = `${fontSize} ${fontFamily}`;
      const textWidth = context.measureText(contentText).width;
      if (textWidth > contentWidth) {
        this.isTruncated = true;
      }
      this.textWidth = textWidth;
      this.contentWidth = contentWidth;
    },
  },
};
</script>
