<template>
    <LoggedInHeader :name="name" @logout="onLogout" :percentage="100" />
    <Wrapper variant="light-silver-screen">
        <div class="relative flex flex-1 justify-center">
            <div class="flex flex-col items-center max-w-4xl py-4 md:py-[2.5rem] gap-10 px-4">
                <img src="/icons/hi-fi.svg" />
                <div class="flex flex-col gap-y-2 max-w-[689px]">
                    <Text variant="h1" thickness="semiBold" align="center">
                        You are all set {{ name }}!
                    </Text>
                    <Text variant="h2" size="16" color="gray" align="center" thickness="light">
                        You’re now ready to explore exclusive opportunities and connect with hotels. 
                        Start your journey with Crewfare Launchpad!
                    </Text>
                </div>
                <div class="flex flex-col gap-y-4 md:flex-row justify-between gap-x-4">
                    <Button @click="$emit('submitted', false)" variant="secondary" theme="light" class="flex items-center">
                        <Icon name="play" color="currentColor" :width="12.19" />
                        <span>Take a Tour</span>
                    </Button>
                    <Button @click="handleHomeRedirect" variant="primary" theme="light" class="flex items-center">
                        <span>Go to my Dashboard</span>
                        <Icon name="forwardArrow" color="currentColor" :width="12.19" />
                    </Button>
                </div>
            </div>
        </div>
    </Wrapper>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import Button from "@/components/ui/atoms/Button";
import Text from "@/components/ui/atoms/Text/Text.vue";
import Wrapper from "@/components/ui/atoms/Wrapper.vue";
import LoggedInHeader from "@/components/ui/organism/LoggedInHeader/LoggedInHeader.vue";
import Icon from "../../atoms/Icon";

// Props
defineProps<{
    name: string;
    onLogout: () => void;
}>();

// Emit
const emit = defineEmits(["submitted"]);

// Router
const router = useRouter();
const handleHomeRedirect = () => {
    router.push("/");
};
</script>
