<template>
  <div v-if="stripeLoaded" class="flex flex-col gap-4 w-full">
    <div>
      <label class="text-14 text-lpThickGray font-medium">Credit Card Number</label>
      <div id="card-number" class="input-field"></div>
      <Text v-if="errorMessages?.cardNumber" size="14" thickness="normal" variant="span" color="red">
        {{ errorMessages.cardNumber }}
      </Text>
    </div>

    <div class="flex gap-4">
      <div class="flex-1">
        <label class="text-14 text-lpThickGray font-medium">Expiration</label>
        <div id="card-expiry" class="input-field"></div>
        <Text v-if="errorMessages?.cardExpiry" size="14" thickness="normal" variant="span" color="red" class="mt-2">
          {{ errorMessages.cardExpiry }}
        </Text>
      </div>

      <div class="flex-1">
        <label class="text-14 text-lpThickGray font-medium">CVV</label>
        <div id="card-cvc" class="input-field"></div>
        <Text v-if="errorMessages?.cardCvc" size="14" thickness="normal" variant="span" color="red" class="mt-2">
          {{ errorMessages.cardCvc }}
        </Text>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, nextTick, defineProps, defineEmits } from "vue";
import Text from "../../atoms/Text";

const props = defineProps({
  nameOnCard: String,
  country: String,
  zip: String,
  errorMessages: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(["update:errorMessages", "tokenGenerated"]);

const stripe = ref(null as any);
const elements = ref(null as any);
const cardNumber = ref(null as any);
const cardExpiry = ref(null as any);
const cardCvc = ref(null as any);
const stripeLoaded = ref(false);
let cardErrors = ref({
  cardNumber: {
    complete: false,
    error: "",
  },
  cardExpiry: {
    complete: false,
    error: "",
  },
  cardCvc: {
    complete: false,
    error: "",
  },
});

onMounted(async () => {
  try {
    if (!(window as any).Stripe) {
      console.error("Stripe.js was not loaded.");
      emit("update:errorMessages", { cardNumber: "Stripe was not loaded." });
      return;
    }

    stripe.value = (window as any).Stripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
      locale: 'en'
    });
    elements.value = stripe.value.elements();
    stripeLoaded.value = true;

    await nextTick();

    if (!document.getElementById("card-number")) {
      console.error("Stripe elements not found at DOM.");
      return;
    }

    const style = {
      base: {
        color: "#141416",
        fontFamily: '"Inter", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#B6B6B6",
          fontSize: "14px",
        },
      },
      invalid: {
        color: "#FF485D",
        iconColor: "#FF485D",
      },
    };

    cardNumber.value = elements.value.create("cardNumber", { style });
    //Add error listener
    cardNumber.value.on("change", (event: any) => {
      console.log("Card number change event", event);
      cardErrors.value = {
        ...cardErrors.value,
        cardNumber: {
          complete: event.complete,
          error: event.error ? event.error.message : "",
        }
      }
      emit("update:errorMessages", cardErrors.value);
    });
    cardNumber.value.mount("#card-number");

    cardExpiry.value = elements.value.create("cardExpiry", { style });
    cardExpiry.value.on("change", (event: any) => {
      console.log("Card expiry change event", event);
      cardErrors.value = {
        ...cardErrors.value,
        cardExpiry: {
          complete: event.complete,
          error: event.error ? event.error.message : "",
        }
      }
      emit("update:errorMessages", cardErrors.value);
    });
    cardExpiry.value.mount("#card-expiry");

    cardCvc.value = elements.value.create("cardCvc", { style, placeholder: 'CVV Code' });
    cardCvc.value.on("change", (event: any) => {
      console.log("Card cvc change event", event);
      cardErrors.value = {
        ...cardErrors.value,
        cardCvc: {
          complete: event.complete,
          error: event.error ? event.error.message : "",
        }
      }
      emit("update:errorMessages", cardErrors.value);
    });
    cardCvc.value.mount("#card-cvc");
  } catch (error) {
    console.error("Erro initializing Stripe:", error);
    emit("update:errorMessages", { cardNumber: {
      complete: false,
      error: "Error initializing Stripe.",
    } });
  }
});


onBeforeUnmount(() => {
  if (cardNumber.value) cardNumber.value.destroy();
  if (cardExpiry.value) cardExpiry.value.destroy();
  if (cardCvc.value) cardCvc.value.destroy();
});



const createToken = async () => {
  console.log("Creating token...");
  if (!stripe.value) {
    emit("update:errorMessages", { cardNumber: "Stripe is not available." });
    return { error: { cardNumber: "Stripe is not available." } };
  }

  const { paymentMethod, error } = await stripe.value.createPaymentMethod({
    type: 'card',
    card: cardNumber.value,
    billing_details: {
      name: props.nameOnCard,
      address: {
        country: props.country,
        postal_code: props.zip,
      },
    },

  });

  if (error) {
    console.error("Error creating token:", error);
    const fieldErrors = {...cardErrors.value};
    if(!(fieldErrors.cardNumber || fieldErrors.cardCvc || fieldErrors.cardExpiry)){
      fieldErrors.cardNumber = error.message;
    }
    cardErrors.value = fieldErrors;
    emit("update:errorMessages", cardErrors.value);

    return { error: fieldErrors };
  }
  emit("update:errorMessages", {});

  return { token: paymentMethod };
};

defineExpose({ createToken, cardNumber, cardExpiry, cardCvc, cardErrors });

</script>

<style scoped>
.input-field {
  @apply border border-neutralGrey px-4 py-4 rounded-lg bg-white w-full text-16 h-12 transition-all duration-200 ease-in-out;
}

.input-field:hover {
  @apply border-lpBlue;
}

.input-field:focus-within {
  @apply border-lpBlue shadow-md;
}

.btn-primary {
  @apply bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition;
}
</style>
