<script setup lang="ts">
import { ref, nextTick, watch, PropType, computed, onMounted, onUnmounted } from "vue";
import Background from "../../atoms/Background";
import Text from "../../atoms/Text";
import PaymentSummary from "../../molecules/PaymentSummary";
import PaymentForm from "../../organism/PaymentForm";
import PlanFeatures from "../../molecules/PlanFeatures";
import Button from "../../atoms/Button";
import BilledBy from "../../molecules/BilledBy";
import Card from "../../atoms/Card";
import LoggedInHeader from '../../organism/LoggedInHeader/LoggedInHeader.vue';
import Select from '../../atoms/Select';
import Input from "../../atoms/Input/Input.vue";
import countriesData from '../../../../../../nest-server/src/shared/countries/countries.json';
import { BILLING_FREQUENCY } from "@crewfare/server-shared";
import Spinner from "../../atoms/Spinner.vue";

//countries
//https://gist.github.com/sohailalam2/816e05eb6e68d030bbe52272785896c4
//There is a embed option, probably will need more config.

const options = countriesData.map(country => ({
  name: country.name,
  value: country.code,
}));

const props = defineProps({
  firstName: {
    type: String,
    required: true,
  },
  doLogout: {
    type: Function as PropType<() => void>,
    required: true,
  },
  onPrevious: {
    type: Function as PropType<() => void>,
    required: true,
  },
  billingFrequency: {
    type: String as PropType<BILLING_FREQUENCY>,
    required: true,
  },
  totalPrice: {
    type: Number,
    required: true
  },
  monthlyPrice: {
    type: Number,
    required: true
  },
  yearlyPrice: {
    type: Number,
    required: true
  },
  yearlyDiscount: {
    type: Number,
    required: true
  },
  paymentLoading: {
    type: Boolean,
    required: true,
    default: false
  },
  paymentError: {
    type: String,
    required: false,
    default: ""
  }
});

const nameOnCard = ref<string>("");
const country = ref<any>(null);
const zip = ref<string>("");
const errorMessages = ref<Record<string, string>>({});
const paymentFormRef = ref(null);
const billingSelected = ref<BILLING_FREQUENCY>(props.billingFrequency);
const hasSubmitted = ref(false);
const emit = defineEmits(["continue", "update:billingFrequency"]);

function handleNameError() {
  if (hasSubmitted.value && !nameOnCard.value) {
    errorMessages.value = { ...errorMessages.value, nameOnCard: "Name on card is required." };
  } else {
    errorMessages.value = { ...errorMessages.value, nameOnCard: "" };
  }
}

function handleZipErr() {
  if (hasSubmitted && !zip.value) {
    errorMessages.value = { ...errorMessages.value, zip: "Zip Code is required." };
  } else {
    errorMessages.value = { ...errorMessages.value, zip: "" };
  }
}

function handleCardErrors(errors: any) {
  for (let key in errors) {
    if (errors[key].error) {
      errorMessages.value = { ...errorMessages.value, [key]: errors[key].error };
    }
    else {
      let fieldName = ''
      switch (key) {
        case 'cardNumber':
          fieldName = 'Card Number';
          break;
        case 'cardExpiry':
          fieldName = 'Card Expiry';
          break;
        case 'cardCvc':
          fieldName = 'Card CVV';
          break;
      }
      let error = hasSubmitted.value && !errors[key].complete ? `${fieldName} is required.` : "";
      errorMessages.value = { ...errorMessages.value, [key]: error };
    }
  }
}

function validateFields() {
  errorMessages.value = {};
  handleNameError();
  handleZipErr();
  handleCardErrors((paymentFormRef.value as any)?.cardErrors)
  if (!country.value) {
    errorMessages.value = { ...errorMessages.value, country: "Country is required." };
  }
}

function handleBillingFrequency(frequency: BILLING_FREQUENCY) {
  billingSelected.value = frequency;
  emit("update:billingFrequency", frequency);
}

async function handleSubmitForm() {
  hasSubmitted.value = true;
  await nextTick();
  validateFields();
  const errors = errorMessages.value;
  for (let key in errors) {
    if (errors[key]) {
      console.log("Error in", key, errors[key]);
      return;
    }
  }

  errorMessages.value = {};
  async function createToken() {
    try {
      const { token, error } = await (paymentFormRef.value as any).createToken();
      if (error) {
        errorMessages.value = { ...errorMessages.value, ...error };
        return;
      }

      if (!token) {
        errorMessages.value = { cardNumber: "Failed to create token." };
        return null;
      }

      return token.id;
    } catch (err) {
      console.error(err);
      errorMessages.value = { cardNumber: "Error creating token. Check console." };
      return null;
    }
  }
  emit("continue", {
    createToken,
    billingName: nameOnCard.value,
    billingFrequency: billingSelected.value,
    billingCountry: country.value,
    billingZip: zip.value
  })
}
const isMobile = ref(false);
const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});
onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});

const bgVariant = computed(() => (isMobile.value ? 'mobile' : 'light-v1'));
</script>

<template>
  <LoggedInHeader :name="firstName" :onLogout="doLogout" :percentage="20" />
  <Background variant="light-v1">
    <div class="flex flex-col items-center w-full">
      <div class="w-full max-w-[960px] flex justify-center mb-4 px-4 mt-10">
        <Text size="40" thickness="semiBold" variant="h2" class="leading-[48px]">
          Make Payment
        </Text>
      </div>
      <div class="flex flex-col md:flex-row w-full max-w-[960px] px-4 py-6 gap-4 md:gap-2">
        <Card size="medium" hPosition="start" vPosition="start"
          class="flex flex-col gap-4 p-4 w-full rounded-lg !py-6 !px-8">
          <Text size="16" thickness="bold" variant="span" class="mt-2">
            Order Summary
          </Text>
          <Text size="16" thickness="semiBold" variant="span" class="mt-2">
            Billing Plan
          </Text>
          <div class="w-full flex flex-col sm:flex-row gap-2">
            <BilledBy title="Billed Annually" :price="yearlyPrice" :discountPercentage="yearlyDiscount" planColor="blue"
              priceTagSubtext="Per User, Per Year" :selected="billingSelected === BILLING_FREQUENCY.YEARLY"
              @select="handleBillingFrequency(BILLING_FREQUENCY.YEARLY)" :class="'gap-[5px]'" />
            <BilledBy title="Billed Monthly" :price="monthlyPrice" planColor="blue"
              priceTagSubtext="Per User, Per Month" :selected="billingSelected === BILLING_FREQUENCY.MONTHLY"
              @select="handleBillingFrequency(BILLING_FREQUENCY.MONTHLY)" :class="'gap-[5px]'" />
          </div>
          <Text size="16" thickness="semiBold" variant="span" class="mt-2">
            Payment Details
          </Text>
          <div class="w-full">
            <Input class="w-full" variant="light" :title="'Name on card'" placeholder="John Doe" v-model="nameOnCard"
              :border="'solid'" @blur="handleNameError" />
            <Text v-if="errorMessages.nameOnCard" size="14" thickness="normal" variant="span" color="red" class="mt-2">
              {{ errorMessages.nameOnCard }}
            </Text>
          </div>
          <PaymentForm ref="paymentFormRef" :nameOnCard="nameOnCard" :country="country ? country.value : ''" :zip="zip"
            :errorMessages="errorMessages" @update:errorMessages="(errors) => handleCardErrors(errors)" />

          <div class="flex flex-row lg:flex-col gap-4 w-full">
            <div class="flex-1">
              <Select class="w-full gap-2" :variant="'inform'" :title="'Country'" :placeholder="'Select'"
                v-model="country" :options="options" />
              <Text v-if="hasSubmitted && !country" size="14" thickness="normal" variant="span" color="red"
                class="mt-2">
                {{ errorMessages.country }}
              </Text>
            </div>
            <div class="flex-1">
              <Input class="w-full" variant="light" :title="'Zip Code'" placeholder="Enter ZIP Code" v-model="zip"
                :border="'solid'" @blur="handleZipErr" />
              <Text v-if="hasSubmitted && !zip" size="14" thickness="normal" variant="span" color="red" class="mt-2">
                {{ errorMessages.zip }}
              </Text>
            </div>
          </div>
          <Text size="12" variant="span" :color="'gray'">
            By providing your card information, you allow Crewfare to charge your card for future payments in
            accordance with their terms and conditions.
          </Text>
          <PaymentSummary :planName="billingSelected === BILLING_FREQUENCY.YEARLY ? 'Yearly Plan' : 'Monthly Plan'"
            :amount="totalPrice"
            :promoText="billingSelected === BILLING_FREQUENCY.YEARLY ? `${yearlyDiscount}% Off Promo Applied` : ''"
            :billingPeriod="billingSelected === BILLING_FREQUENCY.YEARLY ? 'year' : 'month'" />
          <Button theme="light" variant="primary" size="full" :passedFunction="handleSubmitForm">
            <Spinner v-if="paymentLoading" />
            <span v-else>Pay Now • ${{ totalPrice.toFixed(2) }} →</span>
          </Button>
          <Text v-if="paymentError" size="14" align='center' thickness="normal" variant="span" color="red"
            class="mt-2 w-full">
            {{ paymentError }}
          </Text>
        </Card>
        <div class="w-full md:w-[300px] md:mt-0 mt-4">
          <PlanFeatures />
        </div>
      </div>
      <div class="flex flex-row gap-4 items-center justify-between w-full max-w-[960px] px-4 mx-auto mb-8">
        <div class="flex flex-col items-end">
          <Button variant="secondary" theme="light" @click="onPrevious" data-test="previous-button"
            class="border-none shadow-[inset_0_0_0_0.25px_rgba(62,36,246,1)]">
            <div class="flex items-center gap-2">
              <font-awesome-icon :icon="['fas', 'arrow-left']" />
              <span>Previous</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </Background>
</template>
