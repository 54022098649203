<template>
    <svg :width="width" :height="height" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M10.8558 6.62452H1.375C1.19764 6.62452 1.04917 6.56466 0.929583 6.44493C0.809861 6.32535 0.75 6.17688 0.75 5.99952C0.75 5.82216 0.809861 5.67368 0.929583 5.5541C1.04917 5.43438 1.19764 5.37452 1.375 5.37452H10.8558L6.54812 1.06681C6.42424 0.94292 6.36306 0.79792 6.36458 0.631809C6.36625 0.465698 6.43167 0.31799 6.56083 0.188685C6.69014 0.0679902 6.83653 0.00549024 7 0.00118468C7.16347 -0.00312088 7.30986 0.0593791 7.43917 0.188685L12.7227 5.47223C12.8008 5.55028 12.8558 5.63257 12.8877 5.7191C12.9198 5.80563 12.9358 5.8991 12.9358 5.99952C12.9358 6.09993 12.9198 6.19341 12.8877 6.27993C12.8558 6.36646 12.8008 6.44875 12.7227 6.52681L7.43917 11.8104C7.32375 11.9258 7.18083 11.9848 7.01042 11.9874C6.84 11.9901 6.69014 11.931 6.56083 11.8104C6.43167 11.681 6.36708 11.5326 6.36708 11.3649C6.36708 11.1972 6.43167 11.0486 6.56083 10.9193L10.8558 6.62452Z"
            :fill="color"
        />
    </svg>
</template>
<script>
export default {
    computed: {
        height(){
            return this.width*12/13
        }
    },
    props: {
        color: {
            type: String,
            default: '#D9D9D9'
        },
        width: {
            type: Number,
            default: 13
        }
    },
}
</script>