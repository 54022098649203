<template>
  <div class="flex flex-col w-full sticky top-0 z-50">
    <header class="flex items-center justify-between px-4 md:px-12 py-3 bg-crewfareBlack shadow">
      <div class="flex items-center gap-2">
        <img :src="'/logo.svg'" alt="Logo" class="h-8 w-auto" />
      </div>
      <div class="relative" ref="dropdownRef">
        <button @click="toggleMenu"
          class="flex items-center gap-2 px-3 py-2 bg-transparent rounded hover:bg-gray-800 transition">
          <Icon name="accountcircle" color="#FFFFFF" width="24" />
          <div class="flex items-center">
            <span class="text-sm text-white">{{ name }}</span>
            <Icon name="arrowdown" color="#FFFFFF" width="18" />
          </div>
        </button>
        <div v-if="isMenuOpen" class="absolute right-0 mt-2 w-40 bg-white shadow rounded-md z-50">
          <ul class="py-2 text-sm text-gray-700">
            <li @click="onLogout" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Logout</li>
          </ul>
        </div>
      </div>
    </header>
    <div class="w-full bg-lpLightPurple h-1">
      <div class="bg-lpPurple h-full transition-all duration-300" :style="{ width: percentage + '%' }"
        data-test="progress-bar"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import Icon from '../../atoms/Icon/Icon.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'User'
  },
  percentage: {
    type: Number,
    required: false,
    default: 0
  },
  onLogout: {
    type: Function,
    required: true
  }
})

const dropdownRef = ref(null)
const isMenuOpen = ref(false)

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value
}

function handleClickOutside(event) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isMenuOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>
