<template>
  <div class="relative min-w-full min-h-full">
    <div class="absolute left-0 bottom-0 w-full h-full z-[0]">
      <img :src="src" class="w-full h-full object-cover" />
    </div>
    <div class="relative z-[1]">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';

export default {
  computed: {
    src() {
      switch (this.variant) {
        case 'light-v2':
          return '/bg/light-v2-bg.svg';
        case 'light-v1':
          return '/bg/light-v1-bg.svg';
        case 'dark-v1':
          return '/bg/dark-v1-bg.svg';
        case 'mobile':
          return '/bg/background-mobile-light.svg';
      }
      return 'invalid-bg-variant';
    },
  },
  props: {
    variant: {
      type: String as PropType<'light-v2' | 'light-v1' | 'dark-v1' | 'mobile'>,
      default: 'light-v2'
    }
  }
}
</script>
