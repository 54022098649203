<template>
    <Wrapper :variant="'dark-bg-component'">
        <div class="flex gap-y-4 flex-row items-center text-crewfareWhite px-[1.25rem] sm:px-[7.5rem] py-[1.25rem]" :class="{'justify-between': !isSubmitted, 'justify-center sm:justify-between': isSubmitted}">
            <div>
                <Logo size="medium" />
            </div>
            <div v-if="!isSubmitted" class="sm:block hidden">
                <Text variant='h3' size="12" class="sm:text-16" thickness='medium' color="white">Hotel Sign Up</Text>
            </div>
            <div v-if="!isSubmitted" class="flex flex-row text-center items-center gap-2">
                <img src="/icons/homepage.svg" />
                <Text variant='h3' size="12" class="sm:text-16" thickness='medium' color="white"><a href="https://crewfare.com">Go to Homepage</a></Text>
            </div>
        </div>
    </Wrapper>
</template>
<script lang="ts">
import Logo from '../../atoms/Logo';
import Text from '../../atoms/Text';
import Wrapper from '../../atoms/Wrapper.vue';

export default {
    props:{
        isSubmitted: {
            type: Boolean,
            default: false
        }
    },
    components:{
        Wrapper,
        Logo,
        Text
    }
}
</script>