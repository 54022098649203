<template>
  <div
    class="fixed bottom-0 right-0 z-[150] left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
  >
    <div
      class="bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[420px] p-4 relative text-white"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="flex w-full justify-between">
          <p class="text-xl text-center">RFP is accepted!</p>
          <span
            @click="close"
            class="cursor-pointer text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div>
          <p class="text-center text-base my-2 max-w-[420px]">
            You’ve accepted the bid, the hotel will be notified.
            <router-link
              :to="{
                name: 'rfpContract',
                params: { rfp: rfp },
              }"
              class="underline text-crewfareGreen"
              >Click here</router-link
            >
            to add your contract information.
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex justify-end items-center">
            <button class="text-red-400 hover:text-red-600" @click="close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["rfp"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
