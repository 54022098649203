export enum ViewsLocations {
  EDIT_RFP = 'rfpsForm',
  RFP_HOTELS = 'rfpsHotels',
  CONTRACT = 'rfpContract',
  BID_FORM = 'rfpView',
  MESSAGE_FILES = 'messagesFiles',
  CHAT = 'messagesChat',
  EDIT_USER = 'usersEdit',
  HOTEL_SIGNUP_LIST = 'hotelsSignupList',
  GROUP_MANAGER_HOTEL_LIST = 'hotelsGroups',
}
