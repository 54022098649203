import { AgencySignupData } from '@/components/ui/organism/Account/CreateAccountCard/CreateAccountCard.vue';
import { BaseApi, getToken } from './apiFactory';
import { AgencyUpgradePayload } from '@crewfare/server-shared';

class AgencyApi extends BaseApi {
  async signup(data: AgencySignupData) {
    return this.axios({
      url: `${this.apiUrl}/signup`,
      method: 'POST',
      data,
    });
  }

  async getPlans() {
    return this.axios({
      url: `${this.apiUrl}/plans`,
      method: 'GET',
    });
  }

  async noPayment(userGroupId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${userGroupId}/no-payment`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async upgrade(userGroupId: string, payload: AgencyUpgradePayload) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${userGroupId}/upgrade`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
  }
}

export const agencyApi = new AgencyApi({ name: 'agency' });
