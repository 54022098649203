<template>
  <svg :width="width" :height="width" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_571_9921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="45">
      <rect width="45" height="45" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_571_9921)">
      <path
        d="M22.5118 28.7898C22.2858 28.7898 22.0755 28.7538 21.8808 28.6816C21.6861 28.6094 21.501 28.4856 21.3253 28.3103L12.8986 19.8836C12.6393 19.6239 12.5064 19.2975 12.5002 18.9044C12.4943 18.5116 12.6271 18.1792 12.8986 17.9073C13.1705 17.6358 13.4999 17.5 13.8868 17.5C14.2736 17.5 14.603 17.6358 14.8749 17.9073L22.5118 25.5447L30.1486 17.9073C30.4083 17.648 30.7347 17.5152 31.1278 17.5089C31.5207 17.503 31.853 17.6358 32.1249 17.9073C32.3964 18.1792 32.5322 18.5086 32.5322 18.8955C32.5322 19.2823 32.3964 19.6117 32.1249 19.8836L23.6982 28.3103C23.5225 28.4856 23.3374 28.6094 23.1427 28.6816C22.948 28.7538 22.7377 28.7898 22.5118 28.7898Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArrowDownIcon",
  props: {
    color: {
      type: String,
      default: "#27AE60"
    },
    width: {
      type: Number,
      default: 18
    },
  }
}
</script>
