<template>
  <svg :width="width" :height="width" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 8.5625H11C11.1594 8.5625 11.2929 8.50856 11.4007 8.40069C11.5086 8.29281 11.5625 8.15919 11.5625 7.99981C11.5625 7.84031 11.5086 7.70675 11.4007 7.59912C11.2929 7.49137 11.1594 7.4375 11 7.4375H5C4.84063 7.4375 4.70706 7.49144 4.59931 7.59931C4.49144 7.70719 4.4375 7.84081 4.4375 8.00019C4.4375 8.15969 4.49144 8.29325 4.59931 8.40088C4.70706 8.50863 4.84063 8.5625 5 8.5625ZM8.00131 15.125C7.01581 15.125 6.0895 14.938 5.22237 14.564C4.35525 14.19 3.601 13.6824 2.95962 13.0413C2.31825 12.4002 1.81044 11.6463 1.43619 10.7795C1.06206 9.91275 0.875 8.98669 0.875 8.00131C0.875 7.01581 1.062 6.0895 1.436 5.22237C1.81 4.35525 2.31756 3.601 2.95869 2.95962C3.59981 2.31825 4.35375 1.81044 5.2205 1.43619C6.08725 1.06206 7.01331 0.875 7.99869 0.875C8.98419 0.875 9.9105 1.062 10.7776 1.436C11.6448 1.81 12.399 2.31756 13.0404 2.95869C13.6818 3.59981 14.1896 4.35375 14.5638 5.2205C14.9379 6.08725 15.125 7.01331 15.125 7.99869C15.125 8.98419 14.938 9.9105 14.564 10.7776C14.19 11.6448 13.6824 12.399 13.0413 13.0404C12.4002 13.6818 11.6463 14.1896 10.7795 14.5638C9.91275 14.9379 8.98669 15.125 8.00131 15.125Z"
      fill="#FF9E0D" />
  </svg>
</template>

<script>
export default {
  name: "DoNotDisturbIcon",
  props: {
    width: {
      type: Number,
      default: 16
    }
  }
}
</script>