<template>
    <div v-for="column in columns" :key="column.key" :style="column.width ? {width: column.width + 'px'}: {}" class="flex-1 px-2 min-w-0">
        <div>
            <Text :truncate="true" :title="column.transform ? column.transform(row) : row[column.key] as any" variant="h6" color="white">{{ column.transform ? column.transform(row) : row[column.key] }}</Text>
            <p class="text-sm text-red-700"> {{ column.errorText ? column.errorText(row) : '' }} </p>
        </div>
    </div>
    <div v-if="actions.length > 0" class="flex flex-1 px-2 min-w-0 items-center">
        <div v-for="action in actions" class="pr-2">
            <Button :key="action.key" @click="() => handleClick(action)" :variant="action.variant" class="w-[80px] flex justify-center items-center">
                <Spinner size="20px" v-if="actionsLoading.includes(action.key || action.label)" />
                <span v-else>
                    {{ action.label }}
                </span>
            </Button>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType } from 'vue';
import { Column, Row } from './AdminTable.vue';
import Button from '../../atoms/Button';
import Spinner from '../../atoms/Spinner.vue';
import Text from '../../atoms/Text';
export default {
    props: {
        columns: {
            type: Array as PropType<Column[]>,
            required: true
        },
        row: {
            type: Object as PropType<Row<Column[]>>,
            required: true,
        },
        actions:{
            type: Array as PropType<{
                key: string,
                label: string,
                callback: (row: Row<Column[]>) => void,
                variant: 'primary' | 'secondary' | 'danger'
            }[]>,
            default: () => []
        },
        multiActionsAllowed: {
            type: Boolean,
            default: false
        }
    },
    components:{
        Button,
        Spinner,
        Text
    },
    computed: {
        isDisabled(){
            return this.multiActionsAllowed || this.actionsLoading.length !== 0
        }
    },
    methods: {
        async handleClick(action: any){
            const actionKey = action.key || action.label;
            if(this.actionsLoading.includes(actionKey) || this.isDisabled){
                return;
            }
            this.actionsLoading.push(actionKey)
            await action.callback(this.row);
            this.actionsLoading = this.actionsLoading.filter(_key => _key != actionKey)
        }
    },
    data: () => ({
        actionsLoading: [] as any[],
    })
}
</script>