import { h } from 'vue';
import 'flowbite';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueTippy from 'vue-tippy';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import '@vuepic/vue-datepicker/dist/main.css';
import './index.css';
import VueSignaturePad from 'vue-signature-pad';
import Vue3TagsInput from 'vue3-tags-input';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import DropdownMenu from 'v-dropdown-menu';
import VueHtml2Canvas from 'vue-html2canvas';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import { Html2CanvasPlugin } from 'vue3-html2canvas';

library.add(fas);

const stripeOptions = {
  pk: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: import.meta.env.VITE_STRIPE_ACCOUNT_ID,
  apiVersion: '2025-02-24.acacia',
  locale: 'en',
};
const loadStripe = () => {
  return new Promise(resolve => {
    if (window.Stripe) {
      resolve(window.Stripe);
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    script.onload = () => {
      resolve(window.Stripe);
    };
    document.head.appendChild(script);
  });
};

loadStripe().then(Stripe => {
  app.use(StripePlugin, stripeOptions);
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueTippy);
app.use(VueSignaturePad);
app.use(DropdownMenu);
app.use(VueHtml2Canvas);
app.use(Html2CanvasPlugin);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCwDSzKEVg4LGxPAqvcyJTwngTj7OPFJ18',
    libraries: 'places',
  },
});
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_CAPTCHA_V3_KEY });
app.use(VueTelInput);

app.component('Vue3TagsInput', Vue3TagsInput);
app.component('VueDatePicker', VueDatePicker);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
