<template>
  <div class="flex items-center gap-4 text-sm">
    <div class="flex gap-2">
      <div class="divide-x border rounded">
        <button
          v-for="(item, index) in typeFilter"
          :key="index"
          @click="toggleType(item)"
          class="px-4 py-2"
          :class="
            types.includes(item.value)
              ? 'bg-crewfareGreen text-gray-900'
              : 'text-white'
          "
        >
          {{ item.label }}
        </button>
      </div>
      <button class="text-red-200 text-xs w-[40px]" @click="toggleFilter()">
        {{ all ? "Clear" : "All" }}
      </button>
    </div>
    <div>
      <div class="divide-x border rounded">
        <button
          v-for="(item, index) in statusFilter"
          :key="index"
          @click="toggleStatus(item)"
          class="px-4 py-2"
          :class="
            item.value === status
              ? 'bg-crewfareGreen text-gray-900'
              : 'text-white'
          "
        >
          {{ item.label }}
        </button>
      </div>
    </div>
    <div>
      <input
        type="search"
        v-model="search"
        placeholder="Search..."
        class="bg-gray-700 rounded px-4 text-white"
      />
    </div>
  </div>
</template>

<script>
import { AccountTypes } from '@/enums/AccountTypes';
import { hasCurrentUserPaidAccess } from '@/utils/account';
import { search } from '@/views/search';
import { isCurrentUserCrewfare } from '@crewfare/server-shared';

export default {
  props: ["accountsFiltered", "status", "types"],
  data() {
    return {
      search: "",
      typeFilter: [],
      statusFilter: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
    };
  },
  watch: {
    search() {
      this.updateFilter({});
    },
  },
  mounted() {
    const currentGroupId = this.$store.state.groups.selectedId;
    if(!hasCurrentUserPaidAccess(currentGroupId)){
      this.$router.push("/")
    }
    this.typeFilter = this.getTypeFilters();
  },
  computed: {
    accountLogged(){
      return this.$store.state.account;
    },
    all() {
      return this.typeFilter.filter((type) => type.status).length === this.typeFilter.length;
    },
  },
  methods: {
    getTypeFilters(){
      let types = [];
      const currentGroupId = this.$store.state.groups.selectedId;
      const currentAccount = this.$store.state.account;
      const currentGroup = this.$store.state.groups.data.find(group => group.id == currentGroupId);
      if (isCurrentUserCrewfare(currentAccount.type, currentGroup.plan)) {
        types = [
          { label: "NSO", status: true, value: AccountTypes.NSO },
          { label: "Lead Manager", status: true, value: AccountTypes.Lead_Manager },
          { label: "Contact", status: true, value: AccountTypes.Contact },
          { label: "Group Manager", status: true, value: AccountTypes.Group_Manager },
          { label: "Internal", status: true, value: AccountTypes.Internal },
          { label: "Admin", status: true, value: AccountTypes.Admin },
        ]
      } else {
        types = [
          { label: "Admin", status: true, value: AccountTypes.Admin },
          { label: "Internal", status: true, value: AccountTypes.Internal },
        ]
      }

      if(this.accountLogged.type === AccountTypes.Super_Admin){
        types.push({ label: "Super Admin", status: true, value: AccountTypes.Super_Admin });
      }
      return types;
    },
    toggleFilter() {
      if (this.all) {
        this.typeFilter.map((type) => (type.status = false));
      } else {
        this.typeFilter.map((type) => (type.status = true));
      }
      this.updateFilter({ status: this.status });
    },
    toggleStatus(item) {
      item.status = !item.status;
      this.updateFilter({ status: item.value });
    },
    toggleType(item) {
      item.status = !item.status;
      this.updateFilter({ status: this.status });
    },
    updateFilter({ status }) {
      this.$emit("filter", {
        search: this.search,
        status: status || this.status,
        type: this.typeFilter
          .filter((type) => type.status)
          .map((type) => type.value),
      });
    },
  },
};
</script>
