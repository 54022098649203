import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APIKEY,
  authDomain: import.meta.env.VITE_AUTHDOMAIN,
  projectId: import.meta.env.VITE_PROJECTID,
  storageBucket: import.meta.env.VITE_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAINGSENDERID,
  appId: import.meta.env.VITE_APPID,
  measurementId: import.meta.env.VITE_MEASUREMENTID,
};

const firestoreName = import.meta.env.VITE_FIRESTORE_NAME;

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const firestore = firestoreName ? getFirestore(firestoreName) : getFirestore(app);
const storage = getStorage();

// Connect to the Firebase Emulator Suite
if (import.meta.env.VITE_FIRESTORE_EMULATOR_PORT) {
  // Realtime Database Emulator
  connectDatabaseEmulator(db, 'localhost', import.meta.env.VITE_FIREBASE_DATABASE_EMULATOR_PORT);

  // Authentication Emulator
  connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST);

  // Firestore Emulator
  connectFirestoreEmulator(firestore, 'localhost', import.meta.env.VITE_FIRESTORE_EMULATOR_PORT);

  // Storage Emulator
  connectStorageEmulator(storage, 'localhost', import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_PORT);
}

export { auth, db, firestore, storage };
