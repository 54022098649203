<template>
  <div class="flex flex-col gap-1">
    <Text v-if="title" :variant="'label'" :color="variant === 'dark' ? 'white' : 'thickGray'">
      {{ title }}<span class="text-crewfareBlue" v-if="required"> *</span>
    </Text>
    <div :disabled="isDisabled" :class="[
        variant === 'inform'
        ? 'box-border rounded-[8px] px-4 py-3 text-sm appearance-none font-normal w-full relative cursor-pointer border-[0.25px] border-[#B6B6B6] focus:outline-none focus:ring-0 focus:border-transparent'
        : 'box-border min-w-[255px] rounded-[8px] px-4 py-3 text-sm hover:border-crewfarePurple appearance-none border-[0.25px] focus:outline-none font-normal focus:ring-0 focus:border-transparent w-full relative cursor-pointer',
        currentClass
    ]" @click="(showOptions = !showOptions) && (blurred = true)" :placeholder="placeholder" :value="modelValue">
      {{ modelValue?.name || placeholder }}
      <template v-if="variant === 'inform'">
        <Icon name="arrowdown" color="#3E24F6" class="absolute right-4 top-1/2 transform -translate-y-1/2" />
      </template>
      <template v-else>
        <img src="/icons/i-arrow-down.svg" class="w-3 h-3 absolute right-4 top-1/2 transform -translate-y-1/2" />
      </template>
      <div v-if="showOptions"
        class="absolute w-full max-h-[300px] left-0 bottom-[55px] rounded-[8px] z-[999] overflow-y-auto custom-scrollbar"
          :class="[
          variant === 'dark' ? 'bg-crewfareGrey' : 'bg-lpWhite',
          variant === 'inform' ? 'border-[0.25px] border-[#B6B6B6] shadow-sm' : ''
        ]">
        <div v-for="(option, index) in options" :key="index" :class="[
            `py-3 px-4 cursor-pointer text-${variant === 'dark' ? 'white' : 'black'} hover:bg-${variant === 'dark' ? 'lpBlue' : 'crewfareGreen'}`,
          (option as any).value === modelValue?.value && 'border-l-4 border-crewfareGreen'
        ]" @click="updateSelection(option)">
          {{ (option as any).name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Text from '../Text';
import Icon from '../Icon';

const variants = ['dark', 'light', 'inform'] as const;
export default {
  mounted() {
    document.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.showOptions = false;
        this.blurred = false;
      }
    });
  },
  components: {
    Text,
    Icon
  },
  props: {
    title: String,
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    isError: Boolean,
    isValid: Boolean,
    isLocked: Boolean,
    isDisabled: Boolean,
    errorMessage: String,
    modelValue: Object,
    selected: String || Number || Boolean,
    options: Array<{
      name: String;
      value: String | Number | Boolean;
    }>,
    variant: {
      type: String as PropType<typeof variants[number]>,
      default: 'dark',
    },
    required: Boolean
  },
  data() {
    return {
      blurred:false,
      showOptions: false,
    };
  },
  computed: {
    optionsOpeningPosition(){
      //Calculate distance from top of the screen
      const distanceFromTop = this.$el.getBoundingClientRect().top;
      //Calculate distance from bottom of the screen
      const distanceFromBottom = window.innerHeight - this.$el.getBoundingClientRect().bottom;
      //If the distance from the top is greater than the distance from the bottom, open the dropdown upwards
      return distanceFromTop > distanceFromBottom ? 'top-55px' : 'bottom-55px';
    },
    currentClass() {
      return {
        'border-transparent': !this.isError && !this.isLocked && this.variant === 'dark',
        'border-[1px] border-crewfareRed': this.isError,
        '!border-lpGreen !bg-lpLightGreen': this.isValid,
        'hover:border-transparent border-transparent': this.isLocked,
        'text-crewfareGrey cursor-not-allowed border-crewfareGreyLighter hover:border-crewfareGreyLighter':this.isDisabled,
        'bg-crewfareGrey text-white': this.variant === 'dark',
        'bg-lpWhite text-lpLightGray border-[1px] border-neutralGrey hover:border-lpBlue': (!this.modelValue?.name) && this.variant === 'inform',
        'bg-lpWhite text-black border-[1px] border-neutralGrey hover:border-lpBlue': (!!this.modelValue?.name) && this.variant === 'inform',
        'bg-lpWhite text-lpLightGray': (!this.modelValue?.name) && this.variant === 'light',
        'bg-lpWhite text-black': (!!this.modelValue?.name) && this.variant === 'light',
      };
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateSelection(value: any, toggleOptions = true) {
      // For some reason this needs to be set to true to close the dropdown
      if (!toggleOptions) this.showOptions = true;
      this.$emit('update:modelValue', value);
    },
    updateSelectionIfMatch(options: any[]) {
      options.forEach((option: { value: string | number }) => {
        if (option.value === this.selected) {
          this.updateSelection(option, true);
        }
      });
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newOptions) {
        if (newOptions && this.selected) {
          this.updateSelectionIfMatch(newOptions);
        }
      },
    },
    selected: {
      immediate: true,
      handler(newSelected) {
        if (this.options && newSelected) {
          this.updateSelectionIfMatch(this.options);
        }
      },
    },
  },
};
</script>
<style scoped>
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background:transparent;
  border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}
</style>