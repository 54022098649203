<template>
    <div :style="`width: ${width}px ;`">
        <div
        class="bg-white rounded w-fit"
        ref="signaturePadCrewfareHolder"
        >
            <VueSignaturePad
            :width="`${width}px`"
            :height="`${height}px`"
            ref="signaturePadCrewfare"
            />
        </div>
        <div class="flex justify-between mt-2">
            <Button variant='secondary' theme='light' @click="undo">Undo</Button>
            <Button variant='danger' @click="clear">Clear</Button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useHtml2Canvas } from "vue3-html2canvas";
    import Button from '../Button/Button.vue';

    const props =defineProps({
        signatureData: {
            type: String,
            default: "",
        },
        width: {
            type: Number,
            default: 350,
        },
        height: {
            type: Number,
            default: 160,
        },
    });

    let blankSignatureData = ref<string | null>(null);
    onMounted(async ()=>{
        blankSignatureData.value = await getSignature();
        console.log("Blank Signature Data", blankSignatureData.value);
        if(props.signatureData){
            signaturePadCrewfare.value.fromDataURL(props.signatureData);
        }
        
    })
    const html2canvas = useHtml2Canvas();


    const signaturePadCrewfare = ref<any>(null);
    const signaturePadCrewfareHolder = ref<any>(null);
    const undo = ()=>{
        if (signaturePadCrewfare.value) {
            signaturePadCrewfare.value.undoSignature();
        }
    }
    const clear = ()=>{
        if (signaturePadCrewfare.value) {
            signaturePadCrewfare.value.clearSignature();
        }
    }
    const getSignature = async ()=>{
        if (signaturePadCrewfareHolder.value) {
            const el = signaturePadCrewfareHolder.value;
            const options = {
                type: "dataURL",
            };
            try{
                const image = await html2canvas(el, options);
                if(image === blankSignatureData.value){
                    return "";
                }
                return image;
            }
            catch(e){
                console.log("Error in getSignature", e);
                return "";
            }
        }
    }

    defineExpose({
        undo,
        clear,
        getSignature
    });

</script>
