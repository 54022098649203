<script setup lang="ts">
import Counter from '../../atoms/Counter/Counter.vue';
import Text from '../../atoms/Text';
import CommissionBadge from '../../molecules/CommissionBadge';
import PlanToggle from '../../molecules/PlanToggle/PlanToggle.vue';
import PriceTag from '../../molecules/PriceTag';
import { PropType } from 'vue';
import Button from '../../atoms/Button/Button.vue';
import colors from '../../atoms/Text/Text.vue';
import { BillingFrequency } from '@/components/types';
import { BILLING_FREQUENCY } from '@/components/constants';
import { PlanTypes } from '@crewfare/server-shared';
import Icon from '../../atoms/Icon/Icon.vue';

const props = defineProps({
  planType: {
    type: String as PropType<PlanTypes>,
    required: true,
    validator: (value: PlanTypes) => Object.values(PlanTypes).includes(value),
  },
  planTitle: {
    type: String,
    required: true,
  },
  planColor: {
    type: String as PropType<(typeof colors)[number]>,
    required: true,
  },
  priceTagSubtext: {
    type: String,
    required: false,
  },
  price: {
    type: Number,
    required: false,
  },
  commissionBadgeType: {
    type: String,
    required: true,
    validator: (value: string) => ['none', 'included'].includes(value),
  },
  buttonText: {
    type: String,
    required: true,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  billingFrequency: {
    type: String as PropType<BillingFrequency>,
    default: BILLING_FREQUENCY.MONTHLY,
  },
  discountPercentage: {
    type: Number,
    default: 15,
  },
  minTeammates: {
    type: Number,
    default: 1,
  },
  onSelectPlan: Function,
  onPlanToggle: {
    type: Function as PropType<(value: boolean) => void>,
    required: false,
  },
  onCounterChange: {
    type: Function as PropType<(value: number) => void>,
    required: false,
  },
  teammatesNumber: {
    type: Number,
    required: true
  }
});

const emits = defineEmits(['update:billingFrequency', 'update:onCounterChange']);

const isFreePlan = props.planType === PlanTypes.FREE;

const handleBillingFrequencyUpdate = (newFreq: BillingFrequency) => {
  emits('update:billingFrequency', newFreq);
};
const handleCounterChange = (newCounter: Number) => {
  emits('update:onCounterChange', newCounter);
};
</script>

<template>
  <div :class="[
    'w-full md:w-1/2 flex flex-col bg-lpWhite p-4 sm:p-6 rounded-[0.75rem] max-w-[480px] mx-auto gap-y-[10px] hover:shadow-md border',
    selected ? (isFreePlan ? 'border-lpGreen' : 'border-lpBlue') : 'border-transparent',
  ]">
    <div class="flex flex-col gap-y-1">
      <Text variant="span" size="16" thickness="bold" :color="planColor">{{ planTitle }}</Text>
      <PriceTag :subtext="priceTagSubtext" v-if="isFreePlan" />
      <div v-if="planType === PlanTypes.ENTERPRISE">
        <PlanToggle :billing-frequency="billingFrequency" :discount-percentage="discountPercentage"
          @update:billingFrequency="handleBillingFrequencyUpdate" />
        <div class="flex items-center justify-between mt-[16px]">
          <PriceTag :subtext="priceTagSubtext" :price="price" :color="planColor" />
          <div class="flex items-center flex-col gap-2">
            <Text size="12" thickness="semiBold" variant="span" color="gray"> Number of Users </Text>
            <Counter :minValue="minTeammates" @update:modelValue="handleCounterChange" :modelValue="teammatesNumber" />
          </div>
        </div>
      </div>
    </div>
    <hr class="my-2" />

    <CommissionBadge :type="commissionBadgeType" />

    <template v-if="planType === PlanTypes.ENTERPRISE">
      <div class="flex items-center gap-2">
        <span class="inline-flex items-center justify-center w-3 h-3 rounded-full text-white text-xs">
          <Icon name="check" color="#27AE60" :width='18' />
        </span>
        <span class="text-lpGray text-14">
          Earn commission on contracts
        </span>
      </div>
      
      <div class="flex items-center gap-2">
        <span class="inline-flex items-center justify-center w-3 h-3 rounded-full text-white text-xs">
          <Icon name="check" color="#27AE60" :width='18' />
        </span>
        <span class="text-lpGray text-14">
          Collaborate with team members
        </span>
      </div>
      
      <div class="flex items-center gap-2">
        <span class="inline-flex items-center justify-center w-3 h-3 rounded-full text-white text-xs">
          <Icon name="check" color="#27AE60" :width='18' />
        </span>
        <span class="text-lpGray text-14">
          Receive premium support and onboarding assistance
        </span>
      </div>
    </template>

    <button v-if="isFreePlan" :class="[
      'mt-auto px-6 py-3 rounded-lg w-full text-sm font-semibold transition-all border border-lpGreen text-lpGreen bg-lpWhite hover:bg-lpGreen hover:text-lpWhite',
    ]" @click="onSelectPlan && onSelectPlan(planType)">
      {{ buttonText }}
    </button>
    <Button v-if="planType === PlanTypes.ENTERPRISE" @click="onSelectPlan && onSelectPlan(planType)"
      class="flex justify-center items-center" :theme="'light'">
      <span>{{ buttonText }}</span>
    </Button>
  </div>
</template>
