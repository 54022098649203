import AllSetPage from './AllSetPage.vue';
import MakePaymentPage from './MakePaymentPage.vue';
import PaymentSuccessfulPage from './PaymentSuccessfulPage.vue';
import PlanSelectionPage from './PlanSelectionPage.vue';

export const agencyOnBoarding = [
  {
    name: 'chooseAPlan',
    path: '/agency/:userGroupId/plan',
    component: PlanSelectionPage,
  },
  {
    name: 'agencyPayment',
    path: '/agency/:userGroupId/payment',
    component: MakePaymentPage,
  },
  {
    name: 'agencySignupCompleted',
    path: '/agency/:userGroupId/completed',
    component: AllSetPage,
  },
  {
    name: 'agencyPaymentCompleted',
    path: '/agency/:userGroupId/payment-completed',
    component: PaymentSuccessfulPage,
  },
];
