<template>
  <div v-if="init">
    <Editor ref="editor" api-key="d074tealriws5uqr0ziqlwdqanrwu935w4mbmy8c7z05e78j" :init="init" />
    <RFPDialog :rfp="rfp" :dialog="dialog" @hide_dialog="toggleDialog" />
  </div>
</template>

<script>
import { pluginVariable } from '@/plugins/variable.ts';
import { getMomentFromFirebaseDate } from '@/utils/dateUtils';
import { formatPhone } from '@/utils/formatter';
import Editor from '@tinymce/tinymce-vue';
import moment from 'moment';
import RFPDialog from './RFPDialog.vue';
import { ContractReviewerType } from '@crewfare/server-shared/constants';
import { PlanTypes } from '@crewfare/server-shared';

export default {
  props: ['rfp', 'proposalGroup', 'hotel', 'signed', 'contract_content', 'has_changes', 'has_comments'],
  components: {
    Editor,
    RFPDialog,
  },
  computed: {
    cutoffDays() {
      const date = getMomentFromFirebaseDate(this.rfp.startDate);
      const cutoff = getMomentFromFirebaseDate(this.rfp.cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    types() {
      if (!this.rfp) return;
      if (this.rfp.roomTypes) {
        const types = this.rfp.roomTypes.filter(type => type.name.length > 0);
        return types;
      }
      const types = [];
      this.rfp.roomList.forEach(day => {
        day.rooms.forEach(room => {
          if (room.type.length > 0) types.push(room.type);
        });
      });
      return [...new Set(types)];
    },
    dates() {
      const days = [];
      this.rfp.roomList.forEach(day => {
        days.push(day.date);
      });
      return [...new Set(days)];
    },
    rooms() {
      const roomsList = [];
      this.rfp.roomList.forEach(day => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.endDate) return '';
      const date = this.rfp.endDate;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    startDateFormated() {
      if (!this.rfp.startDate) return '';
      const date = this.rfp.startDate;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    effectiveDateFormated() {
      if (!this.rfp.effective_date) return '';
      const date = this.rfp.effective_date;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    cutoffDateFormated() {
      if (!this.rfp.cutoffDate) return '';
      const date = this.rfp.cutoffDate;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    account() {
      return this.$store.state.account;
    },
    isHotel() {
      if (!this.account) return;
      return ['contact'].includes(this.account.type.toLowerCase());
    },
  },
  data() {
    return {
      changeIndex: 0,
      content: null,
      dialog: false,
      hotel_person_name: '',
      hotel_address: '',
      hotel_email: '',
      hotel_telephone: '',
      hotel_title: '',
      hotel_date: '',
      crewfare_date: '',
      crewfare_name: '',
      crewfare_title: '',
      crewfare_members: '',
      hotel_taxes: '',
      hotel_other_fees: '',
      init: null,
      protect_component: `class="variable" contenteditable="false"`,
      initial_value: '',
      is_comment_open: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    validateDate(date) {
      const regex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
      if (!regex.test(date)) {
        date = '';
      }
    },
    getTotal() {
      const roomList = this.rfp.roomList;
      const total = roomList.map(day =>
        day.rooms.map(room => parseFloat(room.amount || 0)).reduce((accumulator, value) => accumulator + value, 0),
      );
      return total.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTotalByType(indexType) {
      const roomList = this.rfp.roomList;
      const total = roomList.map(day =>
        day.rooms.filter((room, indexType) => indexType === indexType).map(room => parseFloat(room.amount || 0)),
      );
      return total.reduce((accumulator, value) => accumulator + parseFloat(value[0]), 0);
    },
    getTotalByDay(rooms) {
      const amount = rooms.map(room => parseFloat(room.amount || 0));
      return amount.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTypeLoop() {
      let tdTypes = '';
      if (!this.types) return;
      this.types.forEach(date => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdTypes;
    },
    getDateLoop() {
      let tdDates = '';
      if (!this.types) return;
      this.dates.forEach(date => {
        tdDates += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdDates;
    },
    getTotalByTypeLoop() {
      let tdTypes = '';
      if (!this.dates) return;
      this.dates.forEach((type, index) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${this.getTotalByType(index)}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    getTotalByDateLoop() {
      let tdTypes = '';
      this.dates.forEach(date => {
        let amount = 0;
        this.rfp
          .roomList.filter(day => day.date === date)
          .forEach(days => {
            amount = days.rooms
              .map(day => day.amount)
              .reduce((accumulator, value) => accumulator + parseFloat(value), 0);
          });
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    roomRateLoop() {
      let trRoomRate = '';
      this.types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomRate += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        this.rfp.roomList.forEach(days => {
          trRoomRate += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.rooms.find((room, index) => index === typeIndex)?.value || 0}</span></span>
          </td>`;
        });
        trRoomRate += `</tr>`;
      });
      return trRoomRate;
    },
    getRoomListLoop() {
      let trRoomList = '';
      let types = this.types;
      if (!types) {
        types = [
          {
            name: 'King',
            type: 'Single',
            configuration: '1 King Bed',
            occupancy: '2',
            extra_value: '',
          },
          {
            name: 'Double Queen',
            type: 'Double',
            configuration: 'Double Queen Beds',
            occupancy: '4',
            extra_value: '',
          },
        ];
      }
      types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomList += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        let total = 0;
        this.rfp.roomList.forEach(days => {
          const amount = parseFloat(days.rooms.find((room, index) => index === typeIndex)?.amount || 0);
          total += amount;
          trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
        });
        trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold; text-align: center;"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${total}</span></span></td></tr>`;
      });
      return trRoomList;
    },
    getResortFee() {
      return this.rfp.resortFee === 'Resort Fee' ? this.rfp.resortFeeAmount : this.rfp.resortFee;
    },
    getOtherFees() {
      return !this.rfp.otherFees
        ? 'No'
        : this.rfp.otherFees === 'Yes'
        ? this.rfp.otherFeesAmount
        : this.rfp.otherFees;
    },
    getOccupancyLoop() {
      let trOccupancyList = '';
      let typeNames = this.types;
      let types = this.rfp.roomTypes;
      if (!types) {
        types = [
          {
            name: typeNames[0] || 'King',
            type: 'Single',
            configuration: '1 King Bed',
            occupancy: '2',
            extra_value: '',
          },
          {
            name: typeNames[1] || 'Double Queen',
            type: 'Double',
            configuration: 'Double Queen Beds',
            occupancy: '4',
            extra_value: '',
          },
        ];
      }
      types?.forEach(days => {
        trOccupancyList += `<tr>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.name}</span></span>
          </td>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.type || '-'}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.configuration || '-'}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.occupancy || '-'}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.extra_value || '-'}</span></span>
          </td>
          </tr>`;
      });
      return trOccupancyList;
    },
    getRoomListTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "

            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              ${this.getDateLoop()}
              <td  style="padding: 4px 6px;text-align: center;">Total</td>
            </tr>
            ${this.getRoomListLoop()}
            <tr>
              <td  style="padding: 4px 6px;text-align: left;"></td>
              ${this.getTotalByDateLoop()}
              <td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
                ${this.getTotal()}
                </span></span>
              </td>
            </tr>
          </table>`;
    },
    getRoomTypeTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Type</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Configuration</td>
              <td  style="padding: 4px 6px;text-align: center;">Hotel Room<br/>Occupancy Limit</td>
              <td  style="padding: 4px 6px;text-align: center;">Additional Person<br/>Value</td>
            </tr>
            ${this.getOccupancyLoop()}
          </table>`;
    },
    getRoomRateTable() {
      return `<table
          style="
            margin-top: 12px;
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
          "
          border="1"
          class="w-full"
          ${this.protect_component}
        >
          <tr>
            <td style="padding: 4px 6px;text-align: left;">Room Name <br/>(As seen in your website)</td>
            ${this.getDateLoop()}
          </tr>
          ${this.roomRateLoop()}
        </table>`;
    },
    getGroupHonoredRate() {
      return this.rfp.groupDateHonored === 'No'
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is
          ${
            this.rfp.groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$${this.rfp.groupDateHonoredPreRate}</span>
              </span>
            pre
          </span>
          `
              : ''
          }
          ${
            this.rfp.groupDateHonoredPre && this.rfp.groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ''
          }
          ${
            this.rfp.groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$${this.rfp.groupDateHonoredPostRate}</span></span>
            post
          </span>
          `
              : ''
          }
          on "Shoulder Nights" known as +/-
          ${
            this.rfp.groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ''
          }
          ${
            this.rfp.groupDateHonoredPre && this.rfp.groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ''
          }
          ${
            this.rfp.groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPostDays}</span></span>
            post
          </span>
          `
              : ''
          }
          the "Peak Nights".
        </div>
        `
        : '' + this.rfp.groupDateHonored === 'Yes'
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is on "Shoulder Nights" known as +/-
          ${
            this.rfp.groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ''
          }
          ${
            this.rfp.groupDateHonoredPre && this.rfp.groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>`
              : ''
          }
          ${
            this.rfp.groupDateHonoredPost
              ? `
            <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPostDays}</span></span>
              post
            </span>
          `
              : ''
          }
          the "Peak Nights".
        </div>
        `
        : '';
    },
    async saveComment() {},
    setInit() {
      console.log('RFP', this.rfp.compRooms);
      this.init = {
        external_plugins: {
          flite: '/flite/plugin.js',
        },
        plugins: 'code tinycomments pluginVariable',
        toolbar: 'bold italic underline | highlightcomments prevcomment showcomments nextcomment | flite',
        menubar: 'format flite tc',
        menu: {
          tc: {
            title: 'Comments',
            items: 'addcomment deleteallconversations',
          },
        },
        height: 700,
        variable_mapper: {
          company_name: this.rfp.companyName || this.proposalGroup?.companyName  || '-',
          event_planner_name: this.rfp.eventPlannerName || this.proposalGroup?.eventPlannerName || '-',
          company_address: this.rfp.companyAddress || this.proposalGroup?.companyAddress  || '-',
          company_telephone: this.rfp.companyTelephone || this.proposalGroup?.companyTelephone || '-',
          company_email: this.rfp.companyEmail || this.proposalGroup?.companyEmail  || '-',
          attrition_value: (this.rfp.attrition || '-').replace("Attrition", ""),
          crewfare_title: this.rfp.crewfare_title || this.proposalGroup?.crewfare_title || '-',
          commissionable_company: this.proposalGroup?.plan === PlanTypes.ENTERPRISE ? (this.rfp.companyName || this.proposalGroup.companyName) : 'Crewfare LLC',
          accounting_email: this.rfp.accountingTeamEmail || this.proposalGroup?.accountingTeamEmail || '-',
          accounting_name: this.rfp.accountingTeamName || this.proposalGroup?.accountingTeamName || '-',
          hotel_title: this.rfp.hotel_title || '-',
          hotel_person_name: this.rfp.hotel_person_name || '-',
          hotel_address: this.rfp.hotel_address || '-',
          hotel_email: this.rfp.hotel_email || '-',
          iata: this.proposalGroup?.plan === PlanTypes.ENTERPRISE ? this.rfp.iata : "10614251",
          crewfare_members: this.rfp.crewfare_members || '-',
          rebate: this.rfp.rebate === 'Other' ? this.rfp.rebateAmount : this.rfp.rebate || '-',
          effective_date_formated: this.effectiveDateFormated || '-',
          rfp_name: this.rfp.name || '-',
          room_list_table: this.getRoomListTable() || '',
          room_rate_table: this.getRoomRateTable() || '',
          commission:
            this.rfp.commission === 'Other'
              ? `${this.rfp.commissionOtherAmount}%`
              : this.rfp.commission || '-',
          group_honored_rate: this.getGroupHonoredRate() || '-',
          start_date_formated: this.startDateFormated || '-',
          end_date_formated: this.endDateFormated || '-',
          cutoff_date_formated: this.cutoffDateFormated || '-',
          suite_upgrades: this.rfp.suiteUpgrades || '',
          wifi: this.rfp.wifi === 'Not included' ? this.rfp.wifi_price : this.rfp.wifi || '-',
          parking:
            this.rfp.parking === 'Not included' ? this.rfp.parkingOther : this.rfp.parking || '-',
          breakfast:
            this.rfp.breakfast === 'Not included'
              ? this.rfp.breakfastOther
              : this.rfp.breakfast || '-',
          terms: !this.rfp.terms ? 'No' : this.rfp.terms,
          comp_rooms: this.rfp.compRooms || '-',
          hotel_telephone: formatPhone(this.rfp.hotel_telephone) || '-',
          get_room_list_loop: this.getRoomListLoop() || '-',
          cutoff_days: this.cutoffDays || '-',
          get_total: this.getTotal() || '-',
          get_total_by_type_loop: this.getTotalByTypeLoop() || '-',
          room_rate_loop: this.roomRateLoop() || '-',
          room_types_table: this.getRoomTypeTable() || '-',
          reward_points: this.rfp.rewardPoints || '-',
          hotel_taxes: this.rfp.hotel_taxes || '-',
          resort_fee: this.getResortFee() || '-',
          other_fees: this.rfp.other_fees || '-',
          group_date_honored: this.rfp.groupDateHonored || '-',
          hotel_name: this.rfp.hotel_name || '-',
          hotel_check_in_age: this.rfp.hotel_check_in_age || '-',
          hotel_check_in_time: this.rfp.hotel_check_in_time || '-',
          hotel_check_out_time: this.rfp.hotel_check_out_time || '-',
          hotel_local_occupancy_tax: this.rfp.hotel_local_occupancy_tax || '-',
          hotel_state_occupancy_tax: this.rfp.hotel_state_occupancy_tax || '-',
          hotel_city_tax: this.rfp.hotel_city_tax || '-',
          hotel_convention_tax: this.rfp.hotel_convention_tax || '-',
          hotel_vat_tax: this.rfp.hotel_vat_tax || '-',
          hotel_sat_tax: this.rfp.hotel_sat_tax || '-',
          hotel_municipal_accomodation_tax: this.rfp.hotel_municipal_accomodation_tax || '-',
          hotel_hst_tax: this.rfp.hotel_hst_tax || '-',
          hotel_sustaintability_fee: this.rfp.hotel_sustaintability_fee || '-',
          room_type: '',
          event_name: this.rfp.event_name || '-',
          comp_rooms: this.rfp.compRooms || '-',
          reward_points: this.rfp.rewardPoints || '-',
          cancellation_time: this.rfp.cancellation_time || '-',
          cancellation_days: this.rfp.cancellation_days || '-',
          cancellation_penalty: this.rfp.cacellation_penalty || '-',
          hotel_date: this.rfp.hotel_date || '-',
          total_taxes_percentage: this.calculateTotalTaxes() || '-',
          total_taxes_flat: this.calculateTotalTaxes() || '-',
          quint_room_list: this.getQuintRoomListTable() || '-',
          min_nights: this.rfp.min_nights || '-',
        },
        tinycomments_mode: 'embedded',
        tinycomments_author: this.account.name || 'Anonymous',
        tinycomments_can_resolve: (req, done, fail) => {
          console.log("Author", req.comments[0].author);
          const allowed = req.comments.length > 0;
          done({
            canResolve: allowed,
          });
        },
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }.mce-annotation.tox-comment { background-color: #ffe89d; }',
        flite: {
          isTracking: true,
          sessionPolicy: 'always',
          isVisible: true,
          autoNext: true,
          removeDeleteOnPaste: true,
          allowFindDeletedText: false,
          preserveWhiteSpace: true,
          trackApiInsert: true,
          showChangesWhenInactive: true,
          ignoreContainers: '',
          contextmenu: true,
          users: [
            { name: ContractReviewerType.HOTEL, id: '1' },
            { name: ContractReviewerType.GROUP, id: '0' },
          ],
          user: this.isHotel ? '1' : '0',
          debug: {
            log: true,
            error: true,
            warn: true,
            debug: false,
          },
          statusCallback: options => {
            if (!options.enabled || !options.change) {
              // we only override if the cursor is in a change and FLITE
              // thinks the command should be enabled
              return;
            }
            if (options.command !== 'flite-acceptone' && options.command !== 'flite-rejectone') {
              return;
            }
            const u = options.plugin.getUserInfo();
            return {
              enabled: u?.id !== options.change.userid,
            };
          },
        },
        setup: editor => {
          const moveToComment = change => {
            const comments = editor.annotator.getAll('tinycomments');
            const commentIds = Object.keys(comments);
            if (!commentIds.length) return;
            if (!this.is_comment_open) {
              editor.execCommand('ToggleSidebar', false, 'showcomments');
            }
            const nextKeyIndex = (this.changeIndex + change) % commentIds.length;
            this.changeIndex = nextKeyIndex;
            const nextKey = commentIds[nextKeyIndex];
            const commentNode = comments[nextKey][0];
            //Focus inside editor
            editor.focus();
            //get current scroll position of browser
            const scrollY = 152;
            editor.selection.setCursorLocation(commentNode, 1);
            commentNode.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'start' });
            // setTimeout(()=>{
            //   //Move the scroll position to the previous position
            //   window.scrollTo(0, scrollY)
            // }, 50)
          };
          editor.ui.registry.addButton('prevcomment', {
            icon: 'chevron-left',
            onAction: _ => {
              moveToComment(-1);
            },
          });
          editor.ui.registry.addButton('nextcomment', {
            icon: 'chevron-right',
            onAction: _ => {
              moveToComment(1);
            },
          });
          editor.on('ToggleSidebar', () => {
            this.is_comment_open = !this.is_comment_open;
          });
          editor.on('init', () => {
            editor.setContent(this.contract_content);
          });
          editor.on('flite:init', flite => {
            this.$emit('initChanges', editor, flite);
            moveToComment(0);
          });
          editor.on('flite:change', flite => {
            console.log('Flite change called');
            this.$emit('hasChanges', editor, flite);
          });
          editor.on('flite:accept', flite => {
            this.$emit('acceptChange', editor, flite);
          });
          editor.on('flite:reject', flite => {
            this.$emit('rejectChange', editor, flite);
          });
          editor.on('CommentChange', () => {
            this.$emit('saveComment', editor);
          });
          editor.on('variableClick', () => {
            this.dialog = true;
          });
          editor.on('BeforeExecCommand', evt => {
            function prevent() {
              evt.preventDefault();
              evt.stopImmediatePropagation();
            }
            const flite = editor.plugins.flite;
            const currentNode = flite.tracker.currentChangeNode(flite.getSelectedNode());
            console.log(flite.tracker);
            const user = flite.getUserInfo();
            const opts = {
              exclude: [user.id],
            };
            const changes = flite.getChanges().changes;
            switch (evt.command) {
              case 'flite-acceptall':
                prevent();
                flite.acceptAll(opts);
                return false;
              case 'flite-acceptone':
                prevent();
                const changeId = flite.tracker.getNodeChangeId(currentNode);
                const change = changes[changeId];
                console.log({ changeId, change });
                if (change.userid === user.id) {
                  alert('You are not allowed to accept your own change');
                  return;
                }
                flite.acceptChange(currentNode, opts);
                return false;
              case 'flite-rejectall':
                prevent();
                flite.rejectAll();
                return false;
              case 'flite-rejectone':
                prevent();
                flite.rejectChange(currentNode);
                return false;
            }
          });
          window.tinymce.PluginManager.add('pluginVariable', pluginVariable);
        },
      };
    },
    calculateTotalTaxes() {
      return (
        Number(this.rfp.hotel_taxes) +
        Number(this.rfp.hotel_local_occupancy_tax) +
        Number(this.rfp.hotel_state_occupancy_tax) +
        Number(this.rfp.hotel_city_tax) +
        Number(this.rfp.hotel_convention_tax) +
        Number(this.rfp.hotel_vat_tax) +
        Number(this.rfp.hotel_sat_tax) +
        Number(this.rfp.hotel_municipal_accomodation_tax) +
        Number(this.rfp.hotel_hst_tax)
      );
    },
    getQuintRoomListTable() {
    const roomList = this.rfp.roomList;

    const types = roomList.reduce((acc, cur) => {
      const types = cur.rooms.map(r => r.type);
      acc.push(...types);
      return acc;
    }, []);

    const uniqueTypes = [...new Set(types)];

    return uniqueTypes
      .map(room_type => {
        const filteredRooms = roomList.map(day => {
          const rooms = day.rooms.filter(r => r.type === room_type);
          return {
            date: day.date,
            rooms: rooms[0].amount,
            rate: rooms[0].value,
          };
        });

        return `
        <p><b>Room Type/Description:</b> <span style="color: #0000f5">${room_type}</span></p>
        <p>Rate is for base occupancy of X person(s) per room.</p>
        <table style="width: 100%; border: 1px solid black; border-collapse: collapse;">
          <tr style="color: #0000f5; font-weight: bold">
            <th style="border: 1px solid black; padding: 2px; text-align: left">Date</th>
            <th style="border: 1px solid black; padding: 2px; text-align: left"># Rooms</th>
            <th style="border: 1px solid black; padding: 2px; text-align: left">Rate</th>
          </tr>
          ${filteredRooms
            .map(r => {
              return `
              <tr style="color: #0000f5">
                <td style="border: 1px solid black; padding: 2px">${r.date}</td>
                <td style="border: 1px solid black; padding: 2px">${r.rooms}</td>
                <td style="border: 1px solid black; padding: 2px">${r.rate}</td>
              </tr>
            `;
            })
            .join('')}
        </table>
      `;
      })
      .join('');
  }
  },
  mounted() {
    this.setInit();
  },
  created() {
    window.onbeforeunload = () => (this.has_comments || this.has_changes ? true : null);
  },
  // async beforeUnmount() {
  //   if (this.has_comments || this.has_changes) {
  //     const contract_content = this.$refs["editor"].getEditor().getContent();
  //     let data = {
  //       ...this.rfp,
  //       contract_content,
  //     };
  //     await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
  //   }
  // },
};
</script>
