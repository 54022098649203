export enum GA_TAG_KEYS {
  HOTEL_NAME = 'hotel_name',
  HOTEL_ADDRESS = 'hotel_address',
  HOTEL_CITY = 'hotel_city',
  HOTEL_STATE = 'hotel_state',
  HOTEL_COUNTRY = 'hotel_country',
  CONTACT_NAME = 'contact_name',
  CONTATCT_TITLE = 'contact_title',
}

export enum GA_TAG_NAMES {
  lp_hotel_signup = 'LP_HOTEL_SIGNUP',
}

export const addInfoToGATag = (tagName: GA_TAG_NAMES, info: { [key in GA_TAG_KEYS]?: string }) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: tagName,
    ...info,
  });
};
