<template>
    <svg :width="width" :height="computedHeight" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" width="30.0002" height="18.75" rx="1.50001" fill="#1434CB" />
        <path
            d="M23.76 4.86935C23.0153 4.60013 22.2289 4.46438 21.437 4.46835C18.88 4.46835 17.073 5.76035 17.062 7.60935C17.041 8.96835 18.343 9.73435 19.327 10.1923C20.338 10.6613 20.676 10.9533 20.671 11.3693C20.666 12.0103 19.863 12.2963 19.124 12.2963C18.097 12.2963 17.54 12.1563 16.681 11.7963L16.362 11.6453L15.998 13.7813C16.623 14.0363 17.743 14.2603 18.899 14.2813C21.623 14.2813 23.399 13.0003 23.419 11.0253C23.44 9.94235 22.743 9.12035 21.258 8.44235C20.362 8.00535 19.801 7.70735 19.801 7.25935C19.801 6.86335 20.28 6.44735 21.28 6.44735C21.9436 6.43072 22.6034 6.553 23.217 6.80635L23.462 6.91535L23.821 4.85835L23.76 4.86935ZM30.407 4.64535H28.407C27.782 4.64535 27.318 4.81235 27.042 5.43235L23.198 14.1453H25.917L26.465 12.7183L29.782 12.7233C29.865 13.0563 30.099 14.1443 30.099 14.1443H32.5L30.407 4.64535ZM13.38 4.56735H15.969L14.349 14.0713H11.76L13.38 4.56235V4.56735ZM6.797 9.80135L7.062 11.1243L9.598 4.64535H12.343L8.259 14.1293H5.526L3.286 6.09835C3.26884 6.03164 3.23826 5.96912 3.19614 5.91462C3.15402 5.86012 3.10123 5.81477 3.041 5.78135C2.23638 5.36285 1.38283 5.04609 0.5 4.83835L0.531 4.63935H4.708C5.271 4.66035 5.729 4.83835 5.885 5.44235L6.797 9.80635V9.80135ZM27.203 10.7753L28.24 8.11435C28.224 8.13935 28.453 7.56735 28.583 7.20735L28.76 8.02435L29.36 10.7693H27.203V10.7753Z"
            fill="white" />
    </svg>

</template>

<script>
export default {
    name: "VisaIcon",
    props: {
      width: {
            type: Number,
            default: 33
      }
        },
    computed: {
      computedHeight() {
        return (this.width * 19) / 33;
      }
    }
}
</script>  