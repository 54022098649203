<template>
  <div class="flex items-center gap-2">
    <span :class="[circleClasses]">
      <Icon :name="computedIconName" :color="computedColor" :width='18' />
    </span>
    <span class="text-lpGray text-14">
      {{ computedLabel }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import Icon from '../../atoms/Icon/Icon.vue';
import { IconNames } from '../../atoms/Icon/Icon.vue';
const props = defineProps({
  type: {
    type: String,
    default: 'none',
  },
  icon: {
    type: String as () => IconNames,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '',
  },
});

const circleClasses = 'inline-flex items-center justify-center w-3 h-3 rounded-full text-white text-xs';

const computedIconName = computed(() => {
  if (props.icon) {
    return props.icon as IconNames;
  }
  return props.type === 'included' ? 'check' : 'donotdisturb';
});

const computedLabel = computed(() => {
  if (props.label) return props.label;
  return props.type === 'included' ? 'Commission included' : 'No commission';
});

const computedColor = computed(() => {
  if (props.bgColor) return props.bgColor;
  return props.type === 'included' ? '#27AE60' : '#FF9E0D';
});

</script>