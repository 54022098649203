import { UpdateContractDto } from '../../contract/dto/update-contract.dto';
import { UpdateProposalDto } from '../../proposals/dto/update-proposal.dto';

export const parseContractTemplateIFs = (
  template: string,
  proposal: UpdateProposalDto | UpdateContractDto,
) => {
  const attrition = proposal.attrition || '';
  let isCourtesyBlock = false;
  if (attrition === 'Courtesy Block') {
    isCourtesyBlock = true;
  }
  if (isCourtesyBlock) {
    //Find all the substrings starting with [isAttrition] and ending with [/isAttrition]
    const regex = /\[isAttrition\]([\s\S]*?)\[\/isAttrition\]/g;
    const matches = template.match(regex);
    console.log('Attrition matches', matches);
    if (matches) {
      matches.forEach((match) => {
        template = template.replace(match, '');
      });
    }
    //Remove all the [isCourtesy] and [/isCourtesy] tags
    template = template.replace(/\[isCourtesy\]/g, '');
    template = template.replace(/\[\/isCourtesy\]/g, '');
  } else {
    //Find all the substrings starting with [isCourtesy] and ending with [/isCourtesy]
    const regex = /\[isCourtesy\]([\s\S]*?)\[\/isCourtesy\]/g;
    const matches = template.match(regex);
    if (matches) {
      matches.forEach((match) => {
        template = template.replace(match, '');
      });
    }
    //Remove all the [isAttrition] and [/isAttrition] tags
    template = template.replace(/\[isAttrition\]/g, '');
    template = template.replace(/\[\/isAttrition\]/g, '');
  }
  return template;
};
