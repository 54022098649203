<template>
    <Loading class="!min-h-[100vh]" v-if="!plans" />
    <ChooseAPlan
    v-else
    :enterprise-price="Number((price/100).toFixed(2))"
    :teammates-number="teammatesNumber"
    :billing-frequency="billingFrequency"
    :update-error="error"
    :yearly-discount="yearlyDiscount"
    @continue="handleChoosePlan"
    @update:billingFrequency="billingFrequency = $event"
    @update:teammatesNumber="teammatesNumber = $event"
    :first-name="firstName"
    :do-logout="doLogout"
    :continue-loading="continueLoading"
    />
  </template>
  
<script setup lang="ts">
  import { ref, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { BILLING_FREQUENCY, PLAN_TYPES } from "@/components/constants";
  import Loading from "@/components/default/Loading.vue";
  import ChooseAPlan from "@/components/ui/pages/ChooseAPlan/ChooseAPlan.vue";
  import { agencyApi } from "@/utils/apis/agencyApi";
  import { calculateSubscriptionPrice, OnBoardingStatus, PlanTypes } from "@crewfare/server-shared";
  import { signOut } from "firebase/auth";
  import { auth } from "@/utils/firebase";
import { userGroupsApi } from "@/utils/apis/userGroupsApi";
  
  // Vuex store and router
  const store = useStore();
  const router = useRouter();
  
  // Reactive state
  const plans = ref(null);
  const error = ref("");
  const continueLoading = ref(false);
  const planType = ref(PLAN_TYPES.ENTERPRISE);
  const teammatesNumber = ref(1);
  const billingFrequency = ref(BILLING_FREQUENCY.MONTHLY);
  const yearlyDiscount = ref(0);
  
  // Computed properties
  const price = computed(() => 
    calculateSubscriptionPrice(planType.value, billingFrequency.value, 1)
  );

  const userGroupId: string = router.currentRoute.value.params.userGroupId as string;
  
  const firstName = computed(() => {
    const account = store.state.account;
    return account.firstName ? account.firstName : account.name.split(" ")[0];
  });
  
  // Fetch plans on mount
  onMounted(async () => {
    try {
      const userGroupRes = await userGroupsApi.get(userGroupId);
      if(userGroupRes.error){
        router.push("/")
        return;
      }
      const userGroup = userGroupRes.data;
      if(userGroup.plan === PLAN_TYPES.ENTERPRISE && userGroup.onBoardingStatus===OnBoardingStatus.PENDING){
        router.push({name: "agencyPaymentCompleted", params: { userGroupId }});
        return;
      }
      else if(!userGroup.isDraft){
        router.push({name: "agencySignupCompleted", params: { userGroupId }});
        return;
      }
      const response = await agencyApi.getPlans();
      plans.value = response.data;
      yearlyDiscount.value = response.data[PLAN_TYPES.ENTERPRISE].yearlyDiscountPercentage;
    } catch (err) {
      console.error("Failed to fetch plans:", err);
      error.value = "Failed to load plans.";
    }
  });

  const doLogout = ()=>{
    store.commit("setAccount", null);
      signOut(auth).then(() => {
        router.push("/")
    });
  }
  
  // Handle plan selection
  const handleChoosePlan = async ({ planType, billingFrequency, teammatesNumber }: 
    { planType: PlanTypes; billingFrequency: BILLING_FREQUENCY; teammatesNumber: number }) => {
    if(continueLoading.value) return;
    continueLoading.value = true;
    
    if (planType === PLAN_TYPES.ENTERPRISE) {
      store.commit("setSelectedPlan", { planType, billingFrequency, teammatesNumber, userGroupId });
      router.push({ name: "agencyPayment", params: { userGroupId } });
    } 
    else if (planType === PLAN_TYPES.FREE) {
      try {
        const res = await agencyApi.noPayment(userGroupId);
        if (res.error) {
          error.value = res.message || "Error registering you for the free plan";
        } else {
          router.push({name: "agencySignupCompleted", params: { userGroupId }});
        }
      } catch (err) {
        error.value = "Unexpected error occurred.";
      }
    }
    else{
      error.value = "Invalid plan type";
    }
    continueLoading.value = false;
  };
  </script>
  