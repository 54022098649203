<template>
  <div
    class="h-[100vh] w-full flex items-center justify-center px-4 bg-gray-900 text-white"
  >
    <div class="flex flex-col gap-4 items-center">
      <img src="/logo.svg" class="h-[40px]" />
      <p class="text-xl text-center">
        Hey this link doesn't exist anymore, or have never existed before.
      </p>
      <p class="text-center">
        <router-link :to="{ name: 'home' }" class="underline"
          >click here</router-link
        >
        to log in
      </p>
    </div>
  </div>
</template>
