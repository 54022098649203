<template>
  <div v-if="account" class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 items-center">
        <h1 class="text-xl text-white">Hotels - Groups</h1>
      </div>
      <div class="flex items-center gap-4">
        <router-link :to="{ name: 'hotelsGroupAdd' }" class="green-button">
          Create group
        </router-link>
      </div>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div
        class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
      >
        <div class="flex gap-2 items-center text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            hotelsFound
          }}</strong>
          Groups
        </div>
        <div class="flex items-center gap-4 text-white">
          <input
            type="search"
            v-model="search"
            class="bg-gray-700 rounded px-4"
            placeholder="Search..."
          />
        </div>
      </div>
      <div>
        <div v-if="!loading">
          <table
            class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
          >
            <tr class="border-b px-4 border-gray-700">
              <th class="text-white px-4 text-left py-2">Name</th>
              <th class="text-white px-4 text-left">Hotels</th>
              <th class="text-white px-4 text-left">Manager</th>
              <th class="flex gap-4 px-4 justify-end"></th>
            </tr>
            <tbody class="divide-y px-4 divide-gray-700">
              <ListItem
                v-for="group in sliceHotels"
                :key="group.id"
                :group="group"
                @getData="getData"
              />
            </tbody>
          </table>
          <Pagination
            :max="16"
            :actual="actual"
            :total="filteredHotels.length"
            @togglePage="togglePage"
          />
        </div>
      </div>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, orderBy } from "firebase/firestore";
import { ListItem } from "@/components/group";
import Pagination from "@/components/default/Pagination.vue";
import { hotelGroupsApi } from "@/utils/apis/hotelGroupsApi";
import { hotelsApi } from "@/utils/apis/hotelsApi";

export default {
  components: {
    ListItem,
    Pagination,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    hotelsFound() {
      return this.filteredHotels.length;
    },
  },
  data() {
    return {
      search: "",
      actual: 0,
      range: [...Array(8).keys()],
      loading: false,
      loadingMore: false,
      hotels: [],
      city: "",
      state: "",
      country: "",
      filteredHotels: [],
      linesToImport: null,
      linesProccessed: 0,
      report: {},
    };
  },
  watch: {
    search() {
      if (this.search.length > 3) {
        this.filteredHotels = this.hotels.filter(
          (hotel) => hotel.data().name.indexOf(this.search) >= 0
        );
      } else {
        this.filteredHotels = this.hotels;
      }
      this.actual = 0;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    togglePage(page) {
      this.actual = page;
    },
    async getData() {
      this.loading = true;
      const hotelGroups = await hotelGroupsApi
        .findAll()
        .then((response) => {
        return response.data.data;
      });
      const results = await Promise.allSettled(
      hotelGroups.map(hotelGroup =>
          hotelsApi.fetchByGroupId(hotelGroup.id).then(response => ({
            hotelGroup,
            count: response.data.length
          }))
        )
      );
        
      results.forEach(result => {
        if (result.status === "fulfilled") {
          result.value.hotelGroup.count = result.value.count;
        } else {
          console.error("Failed to fetch data for a group:", result.reason);
        }
      });
      const hotelsDocs = hotelGroups;
      this.hotels = this.filteredHotels = hotelsDocs;
      document.title = `Launchpad - Crewfare - Hotels - Groups`;
      this.loading = false;
    },
  },
};
</script>
