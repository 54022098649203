<template>
    <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_129_8951" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="18">
            <rect x="0.664062" width="18" height="18" :fill="color" />
        </mask>
        <g mask="url(#mask0_129_8951)">
            <path
                d="M3.89488 14.625C3.52087 14.625 3.20138 14.4926 2.93638 14.2277C2.6715 13.9627 2.53906 13.6432 2.53906 13.2692V4.73081C2.53906 4.35681 2.6715 4.03731 2.93638 3.77231C3.20138 3.50744 3.52087 3.375 3.89488 3.375H15.4333C15.8073 3.375 16.1268 3.50744 16.3918 3.77231C16.6566 4.03731 16.7891 4.35681 16.7891 4.73081V7.64869C16.7891 7.82944 16.7539 8.00319 16.6837 8.16994C16.6136 8.33681 16.5169 8.48175 16.3938 8.60475L12.4649 12.5265L11.2766 11.3379C11.0179 11.0793 10.6978 10.95 10.316 10.95C9.93425 10.95 9.61406 11.0793 9.35544 11.3379L8.35306 12.3403C8.11069 12.5827 7.99963 12.8781 8.01988 13.2266C8.04 13.5753 8.15294 13.8919 8.35869 14.1765C8.42706 14.2716 8.43863 14.3701 8.39338 14.472C8.34813 14.574 8.26637 14.625 8.14812 14.625H3.89488ZM9.66406 8.25L4.45869 4.91963C4.30394 4.82063 4.14606 4.812 3.98506 4.89375C3.82394 4.9755 3.73619 5.10531 3.72181 5.28319C3.70256 5.37644 3.71194 5.46513 3.74994 5.54925C3.78794 5.63338 3.84969 5.70187 3.93519 5.75475L9.29919 9.189C9.41456 9.25913 9.53619 9.29419 9.66406 9.29419C9.79194 9.29419 9.91356 9.25913 10.0289 9.189L15.3929 5.75475C15.4784 5.69712 15.5402 5.62625 15.5782 5.54213C15.6162 5.45788 15.6256 5.36913 15.6063 5.27588C15.5919 5.098 15.5042 4.96944 15.3431 4.89019C15.1821 4.81081 15.0242 4.82063 14.8694 4.91963L9.66406 8.25ZM12.4535 14.6453L16.3102 10.7886C16.4188 10.6798 16.5506 10.6254 16.7054 10.6254C16.8602 10.6254 16.9919 10.6798 17.1005 10.7886C17.2093 10.8972 17.2636 11.0289 17.2636 11.1836C17.2636 11.3385 17.2093 11.4703 17.1005 11.5789L12.9281 15.7515C12.8578 15.8216 12.7837 15.8731 12.7059 15.9058C12.628 15.9384 12.5439 15.9548 12.4535 15.9548C12.3631 15.9548 12.279 15.9384 12.2011 15.9058C12.1233 15.8731 12.0492 15.8216 11.9789 15.7515L9.92075 13.6933C9.81212 13.5846 9.75781 13.4528 9.75781 13.2981C9.75781 13.1433 9.81212 13.0116 9.92075 12.9028C10.0294 12.7942 10.1611 12.7399 10.316 12.7399C10.4708 12.7399 10.6024 12.7942 10.7111 12.9028L12.4535 14.6453Z"
                fill="#3E24F6" />
        </g>
    </svg>

</template>
<script>
export default {
    computed: {
        height() {
            return this.width * 18 / 19
        }
    },
    props: {
        color: {
            type: String,
            default: '#D9D9D9'
        },
        width: {
            type: Number,
            default: 19
        }
    },
}
</script>