<template>
  <div class="bg-[#141416]">
    <div v-if="isAppLoading" class="flex justify-center min-h-[100vh]">
      <Loading size="text-[30px]" />
    </div>
    <div v-if="!isAppLoading">
      <div v-if="isOpenRoute || account">
        <RouterView />
      </div>
      <div v-else>
        <Login />
      </div>
      <div v-if="account">
        <Messages />
        <NotesDialog />
      </div>
    </div>
    <Toast />
    <Prompt/>
    <Alert/>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import Toast from "@/components/default/Toast.vue";
import NotesDialog from "@/components/default/NotesDialog.vue";
import Messages from "@/components/floatInbox/Messages.vue";
import Login from "./components/ui/pages/Account/Login.vue";
import Loading from "@/components/default/Loading.vue";
import { auth, firestore } from "@/utils/firebase";
import { setGroups } from "@/utils/groups";
import {
  doc,
  collection,
  setDoc,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { checkPermission } from "@/utils/permissionVerify.jsx";
import { onAuthStateChanged } from "firebase/auth";
import { accountsApi } from "./utils/apis/accountsApi";
import { isCrewfareAccount } from "./utils/account";
import { userGroupsApi } from "./utils/apis/userGroupsApi";
import { hotelSignupsApi } from "./utils/apis/hotelSignupsApi";
import { HotelSignupStatus, OnBoardingSteps } from "@crewfare/server-shared";
import Alert from "./components/ui/molecules/Alert.vue";
import Prompt from "./components/ui/molecules/Prompt.vue";

export default {
  computed: {
    recaptchaInstance(){
      return this.$recaptchaInstance
    },
    isOpenRoute(){
      return ["forgotPassword","invite","resetPassword", "hotelSignup", "agencySignup"].includes(this.$route.name)
    },
    account() {
      return this.$store.state.account;
    },
    selectedGroupId(){
      return this.$store.state.groups.selectedId;
    },
    isGroupsLoading(){
      return this.$store.state.groups.loading;
    },
    isAppLoading(){
      return this.loading || this.isGroupsLoading;
    },
    userGroups(){
      return this.$store.state.groups.data;
    },
    currentGroup(){
      return this.userGroups?.find(group => group.id === this.selectedGroupId)
    }
  },
  components: {
    Login,
    RouterView,
    NotesDialog,
    Messages,
    Toast,
    Loading,
    Alert,
    Prompt
  },
  data() {
    return {
      loading: true,
      loginRoute: false,
      isRfp: false,
    };
  },
  watch: {
    currentGroup(){
      const currentGroup = this.currentGroup
      if(!currentGroup?.isDraft){
        console.log("Current group is not draft")
        return
      }
      if(this.$route.name !== 'chooseAPlan'  && currentGroup.nextOnBoardingStep === OnBoardingSteps.CHOOSE_A_PLAN){
        this.$router.push({name: "chooseAPlan", params: {userGroupId: this.selectedGroupId}})
      }
    }
  },
  async mounted() {
    onAuthStateChanged(auth, async (user) => {
      const recaptcha = this.$recaptchaInstance
      if(recaptcha){
        console.log("Recap found", recaptcha)
        recaptcha.value.hideBadge()
      }
      if (user) {
        console.log("Got user", user);
        this.$store.commit("setUser", user);
        const me = await accountsApi.me();
        if(me.error){
          this.loading = false;
          return;
        }
        const account = me.data;
        const isCrewfare = isCrewfareAccount(account)
        if(isCrewfare){
          await setGroups()
        }
        await this.$store.commit("setAccount", account);
        try{
          const res = await hotelSignupsApi.list({
            perPage: 1,
            page: 1,
            statuses: [HotelSignupStatus.PENDING]
          })
          if(res.error){
            console.log(res.message)
          }
          this.$store.commit("setHotelSignupCount", res.data.count)
        }
        catch(e){
          console.log(e)
        }
        
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  },
};
</script>
