<script setup lang="ts">
import { defineProps, defineEmits, computed } from 'vue';
import PriceTag from '../PriceTag/PriceTag.vue';
import Text, { colors } from '../../atoms/Text/Text.vue';

const props = defineProps<{
  title?: string;
  selected?: boolean;
  priceTagSubtext?: string;
  price: number;
  planColor?: (typeof colors)[number];
  discountPercentage?: number;
}>();

const emits = defineEmits(['select']);
const isSelected = computed(() => props.selected ?? false);

const toggleSelection = () => {
  emits('select');
};
</script>

<template>
  <div @click="toggleSelection" :class="[
    'flex flex-col p-5 rounded-xl hover:shadow-md border cursor-pointer transition-all',
    'w-full h-[147px] max-w-full',
    isSelected ? 'border-lpBlue bg-lpBlue bg-opacity-5' : 'border'
  ]">
    <div v-if="title" class="flex items-center justify-between md:justify-start md:space-x-2">
      <Text :size="'14'" thickness="normal" variant="span" class="whitespace-nowrap">
        {{ title }}
      </Text>
      <div
        class="relative w-[70px] h-[28px] pt-[5px] pr-[8px] pb-[5px] pl-[8px] gap-[10px] rounded-[500px] bg-[#0000001A] text-12 font-normal flex items-center justify-center"
        :class="{ 'invisible': !discountPercentage }">
        <Text v-if="discountPercentage" variant="span" :size="'12'" class="whitespace-nowrap font-normal">
          Save {{ discountPercentage }}%
        </Text>
      </div>
    </div>
    <PriceTag :subtext="priceTagSubtext" :price="price" :color="planColor" />
  </div>
</template>
