<template>
  <Modal
    :close="close"
    :innerClass="`min-w-[60vw] max-w-[90vw] ${uploadType === uploadTypes.MANUAL ? 'max-h-[80vh]' : ''}`"
  >
    <div class="flex w-full flex-col gap-4 items-center justify-center">
      <template v-if="!fileHasUploaded">
        <div class="flex w-full justify-center gap-4">
          <p class="text-3xl font-semibold" v-if="!isSuccess">
            {{ !editHotelId ? 'Uploading Hotels' : 'Editing Hotel' }}
          </p>
        </div>
        <Switcher
          :options="uploadOptions"
          :passedFunction="setUploadType"
          :default-option="uploadType"
          v-if="!isSuccess && !editHotelId"
        />
        <div
          v-if="uploadType === uploadTypes.FILE_IMPORT"
          class="flex w-full justify-center gap-2 cursor-pointer align-center"
          @click.capture="downloadTemplate"
        >
          <div class="material-symbols-rounded text-crewfareGreen">download</div>
          <span class="text-base font-normal">Download sample file</span>
        </div>
        <div v-if="uploadType === uploadTypes.FILE_IMPORT" class="flex flex-col gap-4">
          <DragAndDrop
            :passedFunction="handleFileUpload"
            :onDropFunction="onDropFile"
            :subtitle="'(Only .CSV File)'"
          >
            <input
              type="file"
              ref="fileModalUpload"
              accept="text/csv"
              id="fileModalUpload"
              @change.prevent="updateFile"
              class="cursor-pointer absolute overflow-hidden opacity-0 w-full h-full"
            />
          </DragAndDrop>
        </div>
        <HotelManualUpload
          v-if="uploadType === uploadTypes.MANUAL && !isSuccess"
          @manual-data="handleManualUpload($event)"
          :chain="chain"
          :is-nso="isNso"
          :edit-hotel-id="editHotelId"
        />
        <UploadSuccess v-if="uploadType === uploadTypes.MANUAL && isSuccess" />
      </template>
      <div class="flex flex-col gap-4">
        <div class="flex flex-row gap-2 items-center justify-between">
          <div v-if="uniqueHotelsCount > 0" class="flex gap-4 flex-row justify-center items-center">
            <div class="text-5xl font-bold">{{ newHotelsCount }}</div>
            <div class="flex flex-col">
              <span>New</span>
            </div>
            <div class="text-5xl font-bold">{{ possibleDuplicates }}</div>
            <div class="flex flex-col">
              <span>Possible Duplicates</span>
            </div>
            <div class="text-5xl font-bold">{{ avoidables }}</div>
            <div class="flex flex-col">
              <span>Already Exist Hotels</span>
            </div>
            <div v-if="hotelsWithLocationErrCount" class="text-5xl font-bold">({{ hotelsWithLocationErrCount }})</div>
            <div v-if="hotelsWithLocationErrCount" class="flex flex-col">
              <span>Location Errors</span>
            </div>
          </div>
        </div>
        <div v-if="uniqueHotelsCount > 0">
          <div class="flex flex-col gap-2 justify-between">
            <div class="flex gap-4">
              <div class="flex gap-4 flex-col">
                <div class="flex flex-col">
                  <span>Expected columns</span>
                </div>
                <div class="flex gap-2">
                  <div class="px-4 py-2" v-for="item in expectedColumns" :key="item">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!error">
              <Tabs :tabs="tabs" :selected="selected" :onTabChange="key => (selected = key)" />
              <div class="flex flex-col mt-2 p-2 bg-gray-700 rounded">
                <div class="flex justify-between">
                  <div class="px-4 py-2">
                    <div class="flex items-center gap-x-2">
                      <Checkbox @click="selectAll" :selected="isSelectedAll" v-if="isPossibleDuplicate" />
                      <p>Hotel Name</p>
                    </div>
                  </div>
                  <div class="px-4 py-2">Number of contacts</div>
                </div>
                <div class="max-h-[30vh] overflow-auto divide-y">
                  <div
                    v-for="hotel in hotelMapWithTabs[selected]"
                    :key="hotel"
                    class="flex justify-between"
                    :class="successfulUids.includes(hotel.uid) ? 'bg-green-500' : failedUids[hotel.uid] ? 'bg-red-500' : ''"
                  >
                    <div class="px-4 py-2">
                      <div class="flex items-center gap-x-2">
                        <Checkbox v-if="isPossibleDuplicate" :selected="includedPossibleDuplicates.has(hotel.uid)" @click="handleInclude(hotel.uid)" />
                        <p>{{ hotel.name }} - {{ hotel.addrFull }}</p>
                      </div>
                      <!-- <p v-if="detectOnUploadAction(hotel)" class="text-sm text-yellow-600">{{ detectOnUploadAction(hotel) }}</p> -->
                      <p v-if="hotel.locationError" class="text-sm text-yellow-600">Couldn't parse the address, The hotel will be saved with (0, 0) lat, lng</p>
                    </div>
                    <div class="flex justify-between flex-end">
                      <div v-if="failedUids[hotel.uid]" class="px-4 py-2">
                        {{ failedUids[hotel.uid] }}
                      </div>
                      <div class="px-4 py-2">
                        {{ hotel.contacts }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center mt-4 justify-between gap-4">
            <div v-if="!(hotelsImporting === false || hotelsImporting || error)">
              <button
                v-if="Object.keys(hotelsToBeUploaded).length"
                @click="startUpload"
                class="border border-2 border-[#A4CDE3] hover:bg-[#A4CDE3] hover:text-black px-4 py-2 rounded-full flex gap-2 text-[#A4CDE3]"
              >
                Upload {{ Object.keys(hotelsToBeUploaded).length }} hotels
              </button>
              <p v-else-if="possibleDuplicates>0">Select hotels to upload</p>
              <p v-else>There's no new hotels to upload</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="error" class="flex flex-col items-center mt-4 justify-between gap-4">
        <p class="py-2 px-4 text-center text-red-600">Please fix the following issue:</p>
        <p>{{ error }}</p>
      </div>
      <div v-if="hotelsImporting">
        <p class="py-2 text-center">The upload is in progress, please wait.</p>
        <div class="flex rounded overflow-hidden border border-crewfareGreen">
          <div
            class="h-[4px] bg-crewfareGreen"
            :style="{
              width: `${(importedHotels.length / uniqueHotelsCount) * 100}%`,
            }"
          ></div>
        </div>
      </div>
      <div v-if="isFileLoading">
        <Spinner/>
      </div>
      <div v-if="hotelsImporting === false">
        <p class="py-2 text-center">The upload is done, you can close this screen.</p>
      </div>
    </div>
  </Modal>
</template>


<script>
import { downloadAssetFile, importHotels, importHotelsLatLng, mapFileToHotels } from '@/utils/hotel.jsx';
import Button from '@/components/ui/atoms/Button';
import Modal from '@/components/ui/molecules/Modal';
import DragAndDrop from '@/components/ui/molecules/DragAndDrop.vue';
import Switcher from '@/components/ui/molecules/Switcher';
import { UPLOAD_HOTEL_TYPES } from '@/enums/upload-hotel.enum';
import { PUBLIC_FILES } from '@/enums/public-files.enum';
import HotelManualUpload from '../ui/organism/HotelManualUpload.vue';
import { hotelsApi } from '@/utils/apis/hotelsApi';
import { accountsApi } from '@/utils/apis/accountsApi';
import UploadSuccess from '../ui/molecules/UploadSuccess.vue';
import Spinner from '../ui/atoms/Spinner.vue';
import { convertArrayToKeyMappedObjectArrays } from '@crewfare/commons/src/utils';
import { DuplicateStatus, stringsKeysCreator } from '@crewfare/server-shared';
import Tabs  from '@/components/ui/atoms/Tabs.vue';
import Checkbox from '../ui/atoms/Checkbox.vue';
import { lpAlert } from '@/utils/lpNativeEvents';

export default {
  data() {
    return {
      type: 'address',
      isFileLoading: false,
      file: null,
      hotelsUnique: [],
      allHotels: {},
      expectedColumns: null,
      uniqueHotelsCount: 0,
      newHotelsCount: 0,
      avoidables: 0,
      possibleDuplicates: 0,
      correctColumns: false,
      error: '',
      errorType: null,
      successfulUids: [],
      failedUids: {},
      duplicatedHotels: {},
      hotelsWithLocationError: new Set(),
      includedPossibleDuplicates: new Set(),
      tabs: [
        {
          key: DuplicateStatus.NEW,
          label: DuplicateStatus.NEW,
          info: "Hotel with both new name and address"
        },
        {
          key: DuplicateStatus.POSSIBLY_DUPLICATE,
          label: DuplicateStatus.POSSIBLY_DUPLICATE,
          includeFrom: true,
          info: "Hotel with same name but different address, check them to include in the upload"
        },
        {
          key: DuplicateStatus.DUPLICATE,
          label: DuplicateStatus.DUPLICATE,
          info: "Hotel with same name and address, can not be uploaded"
        }
      ],
      selected: DuplicateStatus.NEW,
      columnsAddress: [
        'HOTEL',
        'HOTEL CHAIN',
        'STREET ADDRESS',
        'COUNTRY',
        'CITY',
        'STATE',
        'ZIP CODE',
        'CONTACT NAME',
        'EMAIL ADDRESS',
      ],
      uploadOptions: [
        {
          id: 'manual',
          name: 'Manually',
        },
        {
          id: 'file',
          name: 'File Import',
        },
      ],
      uploadType: UPLOAD_HOTEL_TYPES.MANUAL,
      isSuccess: false,
      hasUpload: false,
    };
  },
  components: {
    Button,
    Modal,
    DragAndDrop,
    Switcher,
    HotelManualUpload,
    UploadSuccess,
    Spinner,
    Tabs,
    Checkbox
  },
  computed: {
    hotelsToBeUploaded(){
      const newHotelIds = this.hotelMapWithTabs[DuplicateStatus.NEW].map(hotel => hotel.uid)
      const includeDupIds = this.includedPossibleDuplicates;
      const uploadIds = [...newHotelIds, ...includeDupIds]
      const hotelsToBeUploaded = {}
      uploadIds.forEach(uid => {
        hotelsToBeUploaded[uid] = this.allHotels[uid]
      })
      return hotelsToBeUploaded;
    },
    isSelectedAll(){
      return this.hotelMapWithTabs[this.selected].every(hotel => this.includedPossibleDuplicates.has(hotel.uid))
    },
    isPossibleDuplicate(){
      return this.selected === DuplicateStatus.POSSIBLY_DUPLICATE;
    },
    hotelMapWithTabs(){
      let hotelMapWithTabs = {
        [DuplicateStatus.NEW]: [],
        [DuplicateStatus.POSSIBLY_DUPLICATE]: [],
        [DuplicateStatus.DUPLICATE]: [],
      }
      this.hotelsUnique.forEach(hotel => {
        const searchableName = stringsKeysCreator(hotel.name)
        if(this.duplicatedHotels[searchableName]){
          const hotelWithSameAddr = this.duplicatedHotels[searchableName].find(hotelData => hotelData.addrFull === hotel.addrFull)
          if(hotelWithSameAddr){
            hotelMapWithTabs[DuplicateStatus.DUPLICATE].push(hotel)
          }
          else{
            hotelMapWithTabs[DuplicateStatus.POSSIBLY_DUPLICATE].push(hotel)
          }
        }
        else{
          hotelMapWithTabs[DuplicateStatus.NEW].push(hotel)
        }
      })
      if(hotelMapWithTabs[DuplicateStatus.NEW].length === 0){
        if(hotelMapWithTabs[DuplicateStatus.POSSIBLY_DUPLICATE].length > 0){
          this.selected = DuplicateStatus.POSSIBLY_DUPLICATE;
        }
        else{
          this.selected = DuplicateStatus.DUPLICATE;
        }
      }
      else{
        this.selected = DuplicateStatus.NEW;
      }
      return hotelMapWithTabs;
    },
    importedHotels() {
      return [...this.successfulUids, ...Object.keys(this.failedUids)];
    },
    account() {
      return this.$store.state.account;
    },
    hotelsImporting() {
      return this.$store.state.hotelImporting;
    },
    fileHasUploaded() {
      return this.file !== null;
    },
    uploadTypes() {
      return UPLOAD_HOTEL_TYPES;
    },
    hotelsWithLocationErrCount() {
      return this.hotelsWithLocationError.size;
    },
  },
  watch: {
    file() {
      this.preUpload();
    },
    duplicatedHotels(){
      console.log("Duplicated hotels, ", this.duplicatedHotels)
      let avoidables = 0;
      let possibleDuplicates = 0;
      this.hotelsUnique.forEach(hotel => {
        const searchableName = stringsKeysCreator(hotel.name)
        if(this.duplicatedHotels[searchableName]){
          const hotelWithSameAddr = this.duplicatedHotels[searchableName].find(hotelData => hotelData.addrFull === hotel.addrFull)
          if(hotelWithSameAddr){
            avoidables++;
          }
          else{
            possibleDuplicates++;
          }
        }
      })
      this.avoidables = avoidables;
      this.possibleDuplicates = possibleDuplicates;
      this.newHotelsCount = this.hotelsUnique.length - avoidables - possibleDuplicates;
    },
  },
  methods: {
    selectAll(){
      if( this.hotelsImporting ){
        lpAlert("Upload in progress")
        return;
      }
      if(this.isSelectedAll){
        this.includedPossibleDuplicates.clear();
      }
      else{
        this.hotelMapWithTabs[this.selected].forEach(hotel => this.includedPossibleDuplicates.add(hotel.uid))
      }
    },
    handleInclude(hotelUid){
      if( this.hotelsImporting ){
        lpAlert("Upload in progress")
        return;
      }
      if(this.includedPossibleDuplicates.has(hotelUid)){
        this.includedPossibleDuplicates.delete(hotelUid);
      }
      else{
        this.includedPossibleDuplicates.add(hotelUid);
      }
    },
    detectOnUploadAction(hotel){
      const searchableName = stringsKeysCreator(hotel.name)
      if(this.duplicatedHotels[searchableName]){
        const hotelWithSameAddr = this.duplicatedHotels[searchableName].find(hotelData => hotelData.addrFull === hotel.addrFull)
        if(hotelWithSameAddr){
          return "This hotel will be avoided as one already exists with same name and address"
        }
        else{
          return "This hotel will be uploaded as a new hotel with same name but different address"
        }
      }
    },
    updateFile() {
      this.file = document.getElementById('fileModalUpload').files[0];
      this.preUpload();
    },
    async preUpload() {
      try{
        this.error='';
        this.errorType = null;
        this.hasUpload = true;
        const file = this.file;
        this.isFileLoading = true;
        const type = this.type;
        if (file) {
            this.expectedColumns = this.columnsAddress;
            const {headers, hotelsMap } = await mapFileToHotels(file, this.expectedColumns)
            if (Object.keys(hotelsMap).length === 0) {
              throw new Error('The uploaded file is empty or has no valid rows.');
            }
            const uniqueHotels = [];
            this.hotelsWithLocationError = new Set();
            Object.keys(hotelsMap).forEach(hotelUid => {
              if(hotelsMap[hotelUid][0].hasLocationError){
                this.hotelsWithLocationError.add(hotelUid);
              }
              uniqueHotels.push({
                uid: hotelUid,
                name: `${hotelsMap[hotelUid][0].name}`,
                addrFull: hotelsMap[hotelUid][0].addrFull,
                contacts: hotelsMap[hotelUid].filter(hotelData => hotelData.hasContact).length,
                locationError: hotelsMap[hotelUid][0].hasLocationError,
              });
            });
            const uniqueHotelNames = uniqueHotels.map(hotel => hotel.name);
            const duplicacyRes = await hotelsApi.checkExistingNameHotels(uniqueHotelNames)
            if(duplicacyRes.error){
              alert(duplicacyRes.message || "Some error occured checking duplicacy");
              return;
            }
            this.duplicatedHotels = convertArrayToKeyMappedObjectArrays(duplicacyRes.data, 'searchableName');
            this.hotelsUnique = uniqueHotels;
            this.uniqueHotelsCount = this.hotelsUnique.length;
            this.correctColumns = headers.length === this.expectedColumns.length;
            this.isFileLoading = false;
            this.allHotels = hotelsMap;
          }
      }
      catch(e){
        console.log("Error reading file", e)
         this.file = null;
         this.errorType = 'file';
         this.isFileLoading = false;
         this.error = e.message || "Some error occured parsing the file"
      }
    },
    close() {
      this.$store.commit('setHotelImporting', null);
      this.$emit('close');
      this.hasUpload && this.$store.commit("setLastUploaded")
    },
    async startUpload() {
      if(this.hotelsImporting){
        alert("Upload in progress")
        return;
      }
      const hotelsToBeUploaded = this.hotelsToBeUploaded;
      console.log("Process the upload", hotelsToBeUploaded)
      const chainNames = Object.values(hotelsToBeUploaded).map(hotel => hotel[0].chain);
      this.$store.commit('setHotelImporting', true);
      console.log("Chain names", chainNames)
      const bulkRes = await hotelsApi.createBulkChains(chainNames);
      if(bulkRes.error){
        this.error = bulkRes.message || "Some error occured creating chains"
        this.$store.commit('setHotelImporting', false);
        return;
      }
      //Process in batch of 5
      const batchSize = 5;
      const uids = Object.keys(hotelsToBeUploaded);
      for (let i = 0; i < uids.length; i += batchSize) {
        const batchUids = uids.slice(i, i + batchSize);
        const batchHotels = batchUids.map(uid => this.hotelsToBeUploaded[uid]);
        await Promise.all(
          batchHotels.map(async hotel => {
            try{
              const hotelData = {
                addrFull: hotel[0].addrFull,
                chain: hotel[0].chain,
                city: hotel[0].city,
                country: hotel[0].country,
                lat: hotel[0].lat,
                lng: hotel[0].lng,
                name: hotel[0].name,
                rooms: 0,
                state: hotel[0].state, 
                zipCode: hotel[0].zipCode,
                rating: hotel[0].rating,
              };
              const contactData = hotel.filter(hotelData=>hotelData.hasContact).map(contact => ({
                contactName: contact.contactName,
                contactEmail: contact.contactEmail,
                contactPhone: contact.contactPhone,
                contactRole: contact.contactRole,
              }));
              const uploadRes = await this.handleSingleHotelUpload({ hotelData, contactData });
              if(uploadRes.error){
                this.failedUids[hotel[0].uid]=uploadRes.message || "Some error occured";
                return;
              }
              this.successfulUids.push(hotel[0].uid);
            }
            catch(e){
              console.log("Error uploading hotel", e)
              this.failedUids[hotel[0].uid]=e.message || "Some error occured";
            }
          }),
        );
      }
      this.$store.commit('setHotelImporting', false);
      this.$store.commit("setLastUploaded")
    },

    downloadTemplate() {
      downloadAssetFile(PUBLIC_FILES.IMPORT_HOTEL_SAMPLE);
    },

    handleFileUpload() {
      this.$refs.fileModalUpload.click();
    },
    onDropFile(event) {
      this.file = event.dataTransfer.files[0];
      this.preUpload();
    },
    setUploadType(option) {
      this.uploadType = option.id;
      this.error = '';
      this.errorType = null;
    },
    openModal() {
      this.uploadType = UPLOAD_HOTEL_TYPES.MANUAL;
      this.$store.commit('setModalOpen', true);
    },

    async handleSingleHotelUpload({ hotelData, contactData, isEdit = false, hotelId = '' }){
      if (isEdit) {
        const data = {
          ...hotelData,
          contacts: contactData.map(contact => ({
            name: contact.contactName,
            email: contact.contactEmail,
            phone: contact.contactPhone || '',
            role: contact.contactRole || '',
          })),
        };
        return hotelsApi.update(hotelId, data);
      } 
      else {
        return hotelsApi
            .create({
              ...hotelData,
            })
            .then(async response =>{
              if(response.error){
                return response;
              }
              await Promise.all(
                contactData.map(
                  async contact =>
                     this.updateHotelContact({
                        hotelId: response.data,
                        name: contact.contactName || "",
                        email: contact.contactEmail || "",
                        phone: contact.contactPhone || "",
                        role: contact.contactRole || "",
                    }),
                ),
              )
              return response;
            },
            )
            .catch(error => {
              alert(error.message);
              this.$emit('close');
              throw error;
            });
      }
    },

    /**
     * Handle the manual upload by creating a hotel and its contact.
     *
     * @param {Object} data - The data of the hotel and its contact.
     * @param {Object} data.hotelData - The data of the hotel.
     * @param {Object} data.contactData - The data of the contact.
     * @returns {Promise<void>} - The promise that resolves when the hotel and its contact are created.
     */
     async handleManualUpload({ hotelData, contactData, isEdit = false, hotelId = '' }) {
      try {
        this.error = '';
        this.errorType = null;
        this.hasUpload = true;

        const singleUploadRes = await this.handleSingleHotelUpload({ hotelData, contactData, isEdit, hotelId });
        if (singleUploadRes.error) {
          throw new Error(singleUploadRes.message || 'An error occurred during manual upload.');
        }

        this.isSuccess = true;
        this.$store.commit('setLastUploaded');
      } catch (e) {
        console.error('Error during manual upload', e);
        this.error = e.message || 'An error occurred.';
        this.errorType = 'manual';
      }
    },
    async updateHotelContact(data) {
      return accountsApi.addUpdateContact(data);
    },
  },
  props: {
    isNso: {
      type: Boolean,
      default: false,
    },
    chain: {
      type: String,
      default: '',
    },
    editHotelId: {
      type: String,
      default: '',
    },
  },
};
</script>
