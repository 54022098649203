import { BILLING_FREQUENCY, PlanTypes } from '../typesAndEnums';
interface Plan {
  name: string;
  monthlyPrice: number;
  yearlyDiscountPercentage: number;
}

export type AgencyPlans = Record<PlanTypes, Plan>;

export const plans: AgencyPlans = {
  [PlanTypes.FREE]: {
    name: 'Basic Plan',
    monthlyPrice: 0,
    yearlyDiscountPercentage: 0,
  },
  [PlanTypes.ENTERPRISE]: {
    name: 'Enterprise Plan',
    monthlyPrice: 4900,
    yearlyDiscountPercentage: 15,
  },
  [PlanTypes.FULL_ACCESS]: undefined,
};

/**
 *
 * @param planType enum PlanTypes
 * @param frequency enum BILLING_FREQUENCY
 * @param memberCount number
 * @returns total price in cents
 */
export const calculateSubscriptionPrice = (
  planType: PlanTypes,
  frequency: BILLING_FREQUENCY,
  memberCount: number,
) => {
  console.log(
    'Calling calculateSubscriptionPrice',
    planType,
    frequency,
    memberCount,
  );
  const plan = plans[planType];
  if (!plan) {
    throw new Error(`Plan type ${planType} not found`);
  }
  const discountedPrice =
    frequency == BILLING_FREQUENCY.MONTHLY
      ? plan.monthlyPrice
      : plan.monthlyPrice * 12 * (1 - plan.yearlyDiscountPercentage / 100);
  return discountedPrice * memberCount;
};
