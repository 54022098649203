<template>
  <LoggedInHeader :percentage="16" :name="firstName" :onLogout="doLogout" />
  <Background variant="light-v1">
    <div class="flex flex-col items-center w-full">
      <div class="w-full max-w-[960px] mx-auto px-4 pb-8">
        <div class="flex flex-col items-center mb-6 mt-10 text-center">
          <Text size="40" thickness="semiBold" variant="h2">Choose a Plan</Text>
          <Text size="16" thickness="normal" color="gray"> Select a plan that works for you. </Text>
        </div>

        <div class="flex flex-col md:flex-row items-stretch justify-center gap-6 mb-8">
          <PlanCard :planType="PlanTypes.FREE" :selected="selectedPlanType === PlanTypes.FREE" planTitle="Basic"
            planColor="green" commissionBadgeType="none" title="Basic" priceTagSubtext="for only 1 user"
            description="A simple and affordable plan." buttonText="Start for Free" :onSelectPlan="onSelectPlanHandler"
            :billing-frequency="billingFrequency" @update:billingFrequency="handleBillingFrequencyUpdate"
            :teammatesNumber="props.teammatesNumber" />

          <PlanCard :planType="PlanTypes.ENTERPRISE" :selected="selectedPlanType === PlanTypes.ENTERPRISE"
            planTitle="Enterprise" planColor="blue" :priceTagSubtext="enterprisePlanSubtext" :price="enterprisePrice"
            commissionBadgeType="included" title="Enterprise Plan"
            description="A comprehensive solution for large teams." buttonText="Get Premium Plan"
            :onSelectPlan="onSelectPlanHandler" :billing-frequency="billingFrequency"
            @update:billingFrequency="handleBillingFrequencyUpdate" :teammatesNumber="props.teammatesNumber"
            @update:onCounterChange="handleTeammatesNumberUpdate" :discount-percentage="yearlyDiscount" />
        </div>
        <div class="flex justify-center mb-8 md:mb-10">
          <FeaturesGrid :features="features" font-size="12" mainTitle="Included in all plans" :titleFontSize="'14'"
            :descriptionFontSize="'12'" />
        </div>
        <div class="flex flex-row-reverse gap-4 items-center justify-between w-full max-w-[960px] mt-6 mx-auto">
          <div class="flex flex-col items-end">
            <Button @click="handleContinue" class="flex justify-center items-center" :theme="'light'">
              <span>Continue
                <font-awesome-icon v-if="!continueLoading" :icon="faArrowRight" class="text-sm ml-2" />
              </span>
              <Spinner size="8px" v-if="continueLoading" />
            </Button>
            <Text v-if="errorMessage || updateError" size="14" thickness="normal" variant="span" color="red"
              class="mt-2">
              {{ errorMessage || updateError }}
            </Text>
          </div>
        </div>
      </div>
    </div>
  </Background>
</template>
<script lang="ts" setup>
import { ref, PropType, watch, computed, onMounted, onUnmounted } from 'vue';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../atoms/Button/Button.vue';
import Text from '../../atoms/Text/Text.vue';
import PlanCard from '../../organism/PlanCard/PlanCard.vue';
import FeaturesGrid, { FeatureItem } from '../../organism/FeaturesGrid/FeaturesGrid.vue';
import Background from '../../atoms/Background/Background.vue';
import { BillingFrequency, PlanType } from '../../../types/index';
import { BILLING_FREQUENCY, PLAN_TYPES } from '@/components/constants';
import LoggedInHeader from '../../organism/LoggedInHeader/LoggedInHeader.vue'
import { PlanTypes } from '@crewfare/server-shared';
import Spinner from '../../atoms/Spinner.vue';

const props = defineProps({
  firstName: {
    type: String,
    required: true,
  },
  doLogout: {
    type: Function as PropType<() => void>,
    required: true,
  },
  updateError: {
    type: String,
    required: false,
  },
  enterprisePrice: {
    type: Number,
    required: true,
  },
  yearlyDiscount: {
    type: Number,
    required: true,
  },
  planType: {
    type: String as PropType<PlanType>,
    default: PLAN_TYPES.ENTERPRISE,
  },
  billingFrequency: {
    type: String as PropType<BillingFrequency>,
    default: BILLING_FREQUENCY.MONTHLY,
  },
  teammatesNumber: {
    type: Number,
    required: true,
  },
  continueLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['previous', 'continue', 'update:billingFrequency', 'update:teammatesNumber']);
const enterprisePlanSubtext = computed(() => `per user, per ${billingFrequency.value === BILLING_FREQUENCY.MONTHLY ? 'month' : 'year'}`);

const selectedPlanType = ref<string | null>(props.planType);
const billingFrequency = ref<BillingFrequency>(props.billingFrequency);
const teammatesNumber = ref<number>(props.teammatesNumber);
const errorMessage = ref<string | null>(null);
const name = ref<string>('User');

watch(() => props.billingFrequency, (newValue) => {
  billingFrequency.value = newValue;
});

watch(() => props.teammatesNumber, (newValue) => {
  teammatesNumber.value = newValue;
});

function handlePrevious() {
  emit('previous');
}

function handleContinue() {
  if (!selectedPlanType.value) {
    errorMessage.value = 'Please select a plan.';
    return;
  }
  if (!billingFrequency.value) {
    errorMessage.value = 'Please select a billing frequency.';
    return;
  }
  if (!teammatesNumber.value || teammatesNumber.value < 1) {
    errorMessage.value = 'Please enter a valid number of teammates.';
    return;
  }

  errorMessage.value = null;

  emit('continue', {
    planType: selectedPlanType.value,
    billingFrequency: billingFrequency.value,
    teammatesNumber: teammatesNumber.value,
  });
}

function handleBillingFrequencyUpdate(newFrequency: BillingFrequency) {
  billingFrequency.value = newFrequency;
  emit('update:billingFrequency', newFrequency);
}

function handleTeammatesNumberUpdate(newTeammatesNumber: number) {
  teammatesNumber.value = newTeammatesNumber;
  emit('update:teammatesNumber', newTeammatesNumber);
}

function onSelectPlanHandler(planType: string) {
  selectedPlanType.value = planType;
}

const features: FeatureItem[] = [
  {
    iconName: 'feature',
    title: 'Centralized Travel Integration',
    content: 'Manage hotel sourcing, negotiation, and contracts in one platform, ensuring efficient event travel management.',
  },
  {
    iconName: 'handshake',
    title: 'Effortless RFP & Contracting',
    content: 'Create, compare, and finalize RFPs and contracts within the platform, saving time and reducing manual effort.',
  },
  {
    iconName: 'chataddon',
    title: 'Real-Time Inventory & Communication',
    content: 'Track inventory levels, manage cut-off dates, and streamline communication with hotels and attendees, including automated emails.',
  },
  {
    iconName: 'widgets',
    title: 'Contact & Preference Management',
    content: 'Save contacts, preferences, and specific needs for repeat events, ensuring personalized service and easy repeat bookings.',
  },
  {
    iconName: 'markemailread',
    title: 'Automated Email Tools',
    content: 'Automate personalized communications with hotels, keeping everyone informed of updates and booking details, removing clutter and back-and-forth.',
  },
  {
    iconName: 'bidlandscape',
    title: 'Integrated Analytics & Reporting',
    content: 'Access real-time analytics on room rates, cost breakdowns, and negotiation outcomes to enable informed decision-making.',
  },
];

const isMobile = ref(false);
const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});
onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});

const bgVariant = computed(() => (isMobile.value ? 'mobile' : 'light-v1'));
</script>
