<template>
    <div class="flex flex-col w-full max-w-[40rem] gap-y-[2.5rem] pt-[2.5rem] sm:pb-[2.5rem]">
        <div class="flex flex-col gap-y-2">
            <Text :variant="'h1'" size="32" class="sm:text-40" :align="'center'">Hotel Information</Text>
            <Text :variant="'h2'" size="14" class="sm:text-16" :color="'gray'"  :align="'center'" :thickness="'normal'">Tell us about your hotel, and get started with Launchpad!</Text>
        </div>
        <div class="flex flex-col gap-y-[1rem]">
            <Input :is-valid="!!contactName" :is-error="submitAttempted && !contactName" v-model="contactName" :title="'Full Name'" autocomplete="name" :required="true" :variant="'light'" :placeholder="'Type here'" :is-title-text="true" />
            <Input :is-valid="!!contactTitle" :is-error="submitAttempted && !contactTitle" :title="'Job Title'" autocomplete="work" v-model="contactTitle":required="true" :variant="'light'" :placeholder="'Type here'" :is-title-text="true" />
            <Input :is-valid="!!contactEmail" :is-error="!!(submitAttempted || contactEmail) && !isEmailValid" :title="'Email Address'" autocomplete="email" v-model="contactEmail" :required="true" :variant="'light'" :placeholder="'Type here'" />
            <Input :is-valid="!!phoneNumber" :is-error="!!(submitAttempted || phoneNumber) && !isPhoneValid" :title="'Phone Number'"  autocomplete="tel" v-model="phoneNumber" @change="handlePhoneChange" :required="true" :variant="'light'" :placeholder="'Type here'" input-filter="phone" />
            <Input :is-valid="!!hotelName" :is-error="submitAttempted && !hotelName" :title="'Hotel Name'" v-model="hotelName":required="true" :variant="'light'" :placeholder="'Type here'" :is-title-text="true" />
            <Input :is-valid="!!isAddressValid" :is-error="submitAttempted && !isAddressValid" :title="'Hotel Address'" :locationFunction="setAddress" input-filter="location" :required="true" :variant="'light'" :placeholder="'Type here'" />
            <Select :is-valid="!!isChainValid" :is-error="submitAttempted && !isChainValid" :placeholder="chainLoading? 'Loading...': 'Select'" :variant="'light'" :required="true" :title="'Chain'" :options="chains" v-model="selectedChain" />
            <Input :is-valid="!!chainName" @blur="handleBlur" v-if="isOtherChain" :is-error="submitAttempted && !chainName" :required="true" :variant="'light'" v-model="chainName" :placeholder="'Type here'" />
        </div>
        <div>
            <Button @click="submitHotelSignup" class="w-full flex justify-center" :theme="'light'"> 
                <Spinner v-if="loading" />
                <span v-else>Submit</span>
            </Button>
        </div>
        
    </div>
</template>
<script lang="ts">
import { parseGooglePlace } from '@/utils/gmap';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import Text from '../../atoms/Text';
import { isValidEmail, stringsKeysCreator } from '@crewfare/server-shared';
import Spinner from '../../atoms/Spinner.vue';
import { recaptchSetup } from '@/utils/recaptchaSetup';
import { hotelSignupsApi } from '@/utils/apis/hotelSignupsApi';
import { hotelsApi } from '@/utils/apis/hotelsApi';
import { SiteConstants } from '@crewfare/server-shared/constants';
import { addInfoToGATag, GA_TAG_NAMES } from '@/utils/gaTag';
export default {
    setup: recaptchSetup,
    components: {
        Text,
        Input,
        Select,
        Button,
        Spinner
    },
    computed:{
        selectedChainName(){
            if(this.isOtherChain){
                return this.chainName
            }
            return this.selectedChain?.value
        },
        isOtherChain(){
            return this.selectedChain?.value === SiteConstants.OTHER
        },
        isAddressValid(){
            return this.address && this.latitude && this.longitude && this.state && this.country
        },
        isChainValid(){
            return !!this.selectedChainName
        },
        isEmailValid(){
            if(this.submitAttempted && !this.contactEmail) return false
            if(this.contactEmail) return isValidEmail(this.contactEmail);
            return false;
        },
        isPhoneValid(){
            return (this.phone as any)?.isValid
        },
        isReadyToSubmit(){
            return (
                this.isPhoneValid &&
                this.contactName &&
                this.contactTitle &&
                this.hotelName &&
                this.isEmailValid &&
                this.isAddressValid &&
                this.isChainValid
        )
                
        }
    },
    data() {
        return {
            loading: false,
            phoneNumber: '',
            selectedChain: {} as {name: string, value: string},
            chainName: '',
            contactName: '',
            contactTitle: '',
            contactEmail: '',
            contactPhone: '',
            hotelName: '',
            address: '',
            latitude: '',
            longitude: '',
            state: '',
            city: '',
            zipCode: '',
            country: '',
            phone: null,
            chains: [] as {name: string, value: string}[],
            chainLoading: false,
            submitAttempted: false
        };
    },
    watch: {
        selectedChain: function(val){
            console.log("Chain selected: ", val)
        },
        phone: function(val){
            console.log("Phone number changed: ", val)
        }
    },
    methods: {
        handleBlur(){
            if(this.isOtherChain){
                const chainNameKey = stringsKeysCreator(this.chainName)
                const matchingChain = this.chains.find((chain) => stringsKeysCreator(chain.name) === chainNameKey)
                if(matchingChain){
                    this.chainName = ''
                    this.selectedChain = matchingChain
                }
            }     
        },
        addSignupToGATag(){
            addInfoToGATag(GA_TAG_NAMES.lp_hotel_signup, {
                hotel_name: this.hotelName,
                hotel_address: this.address,
                hotel_state: this.state,
                hotel_city: this.city,
                contact_name: this.contactName,
                contact_title: this.contactTitle
            })
        },
        async submitHotelSignup(){
            this.submitAttempted = true;
            if(this.loading || !this.isReadyToSubmit) return;
            const captchaToken = await this.recaptchaV3()
            const hotelData = {
                contactName: this.contactName,
                contactTitle: this.contactTitle,
                contactEmail: this.contactEmail,
                contactPhone: this.phoneNumber,
                hotelName: this.hotelName,
                address: this.address,
                latitude: this.latitude,
                longitude: this.longitude,
                state: this.state,
                city: this.city,
                zipCode: this.zipCode,
                country: this.country,
                chain: this.selectedChainName
            }
            this.addSignupToGATag()
            this.loading = true;
            console.log("Submitting hotel signup form", hotelData)
            const signupCreated = await hotelSignupsApi.create({payload: hotelData, captchaToken})
            if(signupCreated.error){
                alert(signupCreated.message)
                this.loading = false;
                return;
            }
            this.loading = false;
            this.$emit('submitted', true)
            console.log("Submitting hotel signup form")
        },
        setAddress(place:any){
            const {
                address,
                latitude,
                longitude,
                state,
                city,
                country,
                zipCode,
            } = parseGooglePlace(place)
            this.address = address;
            this.latitude = latitude;
            this.longitude = longitude;
            this.state = state;
            this.city = city;
            this.country = country;
            this.zipCode = zipCode;
        },
        handlePhoneChange(phone: any){
            this.phone = phone;
        }
    },
    async mounted(){
        this.chainLoading = true;
        const chains = await hotelsApi.listChains(true)
        if(chains.error){
            alert(chains.message || "There was an error fetching chains")
            return;
        }
        this.chainLoading = false;
        this.chains = chains.data.map((chain: any) => ({name: chain, value: chain}))
        this.chains.push({name: SiteConstants.OTHER, value: SiteConstants.OTHER})
    }

}
</script>