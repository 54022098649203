<template>
  <svg :width="width" :height="computedHeight" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.65625" width="29.2051" height="18.7501" rx="1.21227" fill="#EEEEEE" />
        <g clip-path="url(#clip0_4241_26771)">
            <path
                d="M3.17735 7.09668H2V11.2027H3.16114C3.78165 11.2027 4.22736 11.0586 4.6256 10.7237C5.08751 10.3401 5.37345 9.74955 5.37345 9.14155C5.37345 7.94299 4.467 7.09668 3.17735 7.09668ZM4.10117 10.1808C3.84648 10.4041 3.52812 10.5005 3.0037 10.5005H2.79648V7.78373H3.0037C3.51308 7.78373 3.83143 7.87906 4.10117 8.1197C4.38712 8.37545 4.54688 8.75908 4.54688 9.14272C4.54572 9.54146 4.38712 9.94021 4.10117 10.1808Z"
                fill="black" />
            <path d="M6.5336 7.09668H5.73828V11.2027H6.5336V7.09668Z" fill="black" />
            <path
                d="M8.49336 8.66273C8.0164 8.48719 7.87285 8.37559 7.87285 8.15122C7.87285 7.89547 8.12754 7.70365 8.4621 7.70365C8.70058 7.70365 8.8916 7.79898 9.11503 8.03962L9.52832 7.48045C9.1787 7.17703 8.78046 7.0166 8.31855 7.0166C7.5869 7.0166 7.04511 7.52811 7.04511 8.19889C7.04511 8.77433 7.2998 9.06148 8.04766 9.33351C8.36602 9.44511 8.52462 9.52532 8.6045 9.57299C8.7631 9.66831 8.84298 9.82874 8.84298 10.0043C8.84298 10.3402 8.57208 10.5797 8.22247 10.5797C7.84043 10.5797 7.53828 10.3879 7.34727 10.0368L6.83789 10.5158C7.20371 11.0587 7.64942 11.2982 8.25372 11.2982C9.08146 11.2982 9.66955 10.739 9.66955 9.94034C9.67071 9.30212 9.40097 8.9987 8.49336 8.66273Z"
                fill="black" />
            <path
                d="M9.92383 9.15843C9.92383 10.3733 10.8627 11.2998 12.0875 11.2998C12.4371 11.2998 12.7242 11.2359 13.0901 11.0603V10.1175C12.7717 10.4372 12.4858 10.5651 12.1199 10.5651C11.3084 10.5651 10.7516 9.97336 10.7516 9.14332C10.7516 8.36094 11.3408 7.73667 12.0887 7.73667C12.4707 7.73667 12.7566 7.86454 13.0912 8.20051V7.25771C12.7416 7.08217 12.4545 7.00195 12.1049 7.00195C10.8951 7.00079 9.92383 7.95987 9.92383 9.15843Z"
                fill="black" />
            <path
                d="M19.5514 9.86116L18.4689 7.09668H17.5938L19.3279 11.3155H19.7586L21.524 7.09668H20.665L19.5514 9.86116Z"
                fill="black" />
            <path
                d="M21.8887 11.2027H24.1484V10.5157H22.6851V9.39731H24.101V8.69398H22.6851V7.78373H24.1484V7.09668H21.8887V11.2027Z"
                fill="black" />
            <path
                d="M27.3164 8.31054C27.3164 7.54327 26.7908 7.0957 25.8682 7.0957H24.6758V11.2017H25.4711V9.55792H25.5822L26.6797 11.2041H27.666L26.3776 9.47771C26.983 9.34983 27.3164 8.93481 27.3164 8.31054ZM25.7096 8.98248H25.4711V7.73625H25.7096C26.2027 7.73625 26.4736 7.94434 26.4736 8.34309C26.4899 8.75811 26.219 8.98248 25.7096 8.98248Z"
                fill="black" />
            <path
                d="M15.6051 11.346C16.8001 11.346 17.7688 10.3732 17.7688 9.17324C17.7688 7.97326 16.8001 7.00049 15.6051 7.00049C14.4101 7.00049 13.4414 7.97326 13.4414 9.17324C13.4414 10.3732 14.4101 11.346 15.6051 11.346Z"
                fill="url(#paint0_linear_4241_26771)" />
            <path opacity="0.65"
                d="M15.6051 11.3472C16.7998 11.3472 17.7688 10.3741 17.7688 9.1744C17.7688 7.97468 16.801 7.00049 15.6051 7.00049C14.4092 7.00049 13.4414 7.97352 13.4414 9.17324C13.4414 10.373 14.4104 11.3472 15.6051 11.3472Z"
                fill="url(#paint1_radial_4241_26771)" />
            <path
                d="M27.8744 7.27225C27.8744 7.19203 27.8269 7.16064 27.7309 7.16064H27.6035V7.54428H27.6984V7.38385L27.8096 7.528H27.9207L27.7934 7.36874C27.8432 7.36757 27.8744 7.31991 27.8744 7.27225ZM27.7158 7.31991L27.6996 7.22458H27.7158C27.7633 7.22458 27.7795 7.24086 27.7795 7.27225C27.7795 7.30363 27.7471 7.31991 27.7158 7.31991Z"
                fill="black" />
            <path
                d="M27.7467 7.00049C27.5557 7.00049 27.4121 7.14464 27.4121 7.33646C27.4121 7.52827 27.5557 7.67243 27.7467 7.67243C27.9215 7.67243 28.0812 7.52827 28.0812 7.33646C28.065 7.15975 27.9215 7.00049 27.7467 7.00049ZM27.7467 7.60732C27.6031 7.60732 27.4758 7.47945 27.4758 7.33529C27.4758 7.17603 27.5869 7.06326 27.7467 7.06326C27.8902 7.06326 28.0014 7.19114 28.0014 7.33529C28.0014 7.49572 27.8902 7.60732 27.7467 7.60732Z"
                fill="black" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_4241_26771" x1="16.713" y1="10.9152" x2="15.2059" y2="8.56531"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#F9A020" />
                <stop offset="0.2041" stop-color="#F99D20" />
                <stop offset="0.4348" stop-color="#F79320" />
                <stop offset="0.6196" stop-color="#F68820" />
                <stop offset="0.7969" stop-color="#F57E20" />
                <stop offset="1" stop-color="#F47521" />
            </linearGradient>
            <radialGradient id="paint1_radial_4241_26771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16.4673 10.75) rotate(-117.709) scale(3.82286 2.95811)">
                <stop stop-color="#FF932C" />
                <stop offset="0.5677" stop-color="#E86D20" />
                <stop offset="0.8604" stop-color="#E85718" stop-opacity="0.8653" />
                <stop offset="1" stop-color="#640000" />
            </radialGradient>
            <clipPath id="clip0_4241_26771">
                <rect width="26.08" height="4.34667" fill="white" transform="translate(2 7)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "DiscoverIcon",
    props: {
        width: {
            type: Number,
      default: 30
    }
        },
  computed: {
    computedHeight() {
      return (this.width * 19) / 30;
    }
    }
}
</script>
