<template>
    <svg :width="width" :height="height" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
        d="M0.0859375 8.70318V1.29609C0.0859375 1.07609 0.161285 0.895537 0.311979 0.754426C0.462535 0.613454 0.638299 0.542969 0.839271 0.542969C0.903299 0.542969 0.969479 0.552066 1.03781 0.57026C1.10628 0.588316 1.17254 0.615538 1.23656 0.651927L7.06656 4.36338C7.18101 4.44144 7.26677 4.53491 7.32385 4.6438C7.38108 4.75283 7.40969 4.87144 7.40969 4.99964C7.40969 5.12783 7.38108 5.24644 7.32385 5.35547C7.26677 5.46436 7.18101 5.55783 7.06656 5.63589L1.23656 9.34734C1.17254 9.38373 1.10628 9.41095 1.03781 9.42901C0.969479 9.4472 0.903299 9.4563 0.839271 9.4563C0.638299 9.4563 0.462535 9.38582 0.311979 9.24484C0.161285 9.10373 0.0859375 8.92318 0.0859375 8.70318ZM1.33594 7.7913L5.72698 4.99964L1.33594 2.20797V7.7913Z" 
        :fill="color"
        />
    </svg>

</template>
<script>
export default {
    computed: {
        height(){
            return this.width*10/8
        }
    },
    props: {
        color: {
            type: String,
            default: '#D9D9D9'
        },
        width: {
            type: Number,
            default: 8
        }
    },
}
</script>