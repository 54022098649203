<template>
  <div class="inline-flex flex-col gap-[5px]">
    <Text variant="span" :color="color" size="40" thickness="bold" class="leading-none m-0 p-0">
      {{ displayPrice }}
    </Text>
    <Text v-if="subtext" variant="span" color="gray" size="14" thickness="normal" class="leading-none m-0 p-0">
      {{ subtext }}
    </Text>
  </div>
</template>

<script lang="ts" setup>
import Text from '../../atoms/Text';
import { colors } from '../../atoms/Text/Text.vue';
import { computed, defineProps, PropType } from 'vue';

const props = defineProps({
  price: {
    type: Number,
    default: 0,
  },
  subtext: {
    type: String,
    default: '',
  },
  color: {
    type: String as PropType<(typeof colors)[number]>,
    default: 'green',
  },
});

const displayPrice = computed(() => {
  return props.price === 0 ? 'Free' : `$${props.price.toFixed(2)}`;
});
</script>
