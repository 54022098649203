import store from '@/store';
import { accountsApi } from './apis/accountsApi';
import { AccountTypes } from '@/enums/AccountTypes';
import { PlanTypes } from '@crewfare/server-shared';

const updateAccount = async (values: any) => {
  const accountId = store.state.account.id;
  const updated = await accountsApi.update(accountId, values);
  if (updated.error) {
    alert('Error updating account');
    return;
  }
  const updatedAccountData = updated.data;
  store.commit('setAccount', updatedAccountData);
};

const isCrewfareAccount = (account: any) => {
  const crewfareTypes = [AccountTypes.Super_Admin, AccountTypes.Admin, AccountTypes.Internal];
  const currentAccountType = account.type.toLowerCase();
  return crewfareTypes.includes(currentAccountType);
};

const isNsoAccount = (account: any) => {
  const nsoTypes = [AccountTypes.NSO];
  const currentAccountType = account.type.toLowerCase();
  return nsoTypes.includes(currentAccountType);
};

const isGroupManagerAccount = (account: any) => {
  const groupManagerTypes = [AccountTypes.Group_Manager];
  const currentAccountType = account.type.toLowerCase();
  return groupManagerTypes.includes(currentAccountType);
};

const hasCurrentUserPaidAccess = (userGroupId: string) => {
  const account = store.state.account;
  const currentGroup: any = store.state.groups.data?.find((group: any) => group.id === userGroupId);
  if (!currentGroup) return false;
  return (
    account.type === AccountTypes.Super_Admin ||
    [PlanTypes.FULL_ACCESS, PlanTypes.ENTERPRISE].includes(currentGroup.plan)
  );
};

const hasCurrentUserFullAccess = (userGroupId: string) => {
  const account = store.state.account;
  const currentGroup: any = store.state.groups.data?.find((group: any) => group.id === userGroupId);
  if (!currentGroup) return false;
  return account.type === AccountTypes.Super_Admin || currentGroup.plan === PlanTypes.FULL_ACCESS;
};

const isGroupOnPaidPlan = (group: any) => {
  return [PlanTypes.FULL_ACCESS, PlanTypes.ENTERPRISE].includes(group?.plan);
};

export {
  updateAccount,
  isCrewfareAccount,
  isNsoAccount,
  hasCurrentUserPaidAccess,
  isGroupOnPaidPlan,
  hasCurrentUserFullAccess,
};
