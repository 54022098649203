<template>
  <div class="flex flex-row items-center gap-2">
    <button @click="selectMonthly" :class="[
      baseButtonClasses,
      billingFrequency === BILLING_FREQUENCY.MONTHLY
        ? 'bg-lpLavenderPurple text-lpBlue border border-lpBlue hover:bg-lpLightPurple'
        : 'bg-lpWhite border-[0.25px] text-lpGray hover:bg-lpSilverGray',
    ]">
      <span class="text-14">Monthly</span>
    </button>

    <button @click="selectYearly" :class="[
      baseButtonClasses,
      billingFrequency === BILLING_FREQUENCY.YEARLY
        ? 'border border-lpGreen text-lpGreen hover:bg-lpLightGreen'
        : 'bg-lpWhite border-[0.25px] text-lpGray hover:bg-lpSilverGray',
    ]">
      <span class="text-14">Annually</span>
      <span
        class="ml-2 px-2 py-[2px] text-12 rounded-full border border-lpGreen text-lpGreen inline-flex items-center whitespace-nowrap leading-none">
        Save {{ discountPercentage }}%
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { BILLING_FREQUENCY } from '@/components/constants';
import { BillingFrequency } from '@/components/types';
import { defineProps, defineEmits, PropType } from 'vue';

const props = defineProps({
  billingFrequency: {
    type: String as PropType<BillingFrequency>,
    default: BILLING_FREQUENCY.MONTHLY,
  },
  discountPercentage: {
    type: Number,
    default: 15,
  },
});

const emits = defineEmits(['update:billingFrequency']);

const baseButtonClasses = 'rounded-full px-4 py-2 font-semibold focus:outline-none transition-colors flex items-center';

function selectMonthly() {
  emits('update:billingFrequency', BILLING_FREQUENCY.MONTHLY);
}

function selectYearly() {
  emits('update:billingFrequency', BILLING_FREQUENCY.YEARLY);
}
</script>
