<template>
  <section class=" w-full max-w-[960px] mx-auto py-8 flex flex-col items-center gap-y-6 ">
    <Text v-if="mainTitle" variant="span" size="16" color="gray" thickness="semiBold" class="text-center">
      {{ mainTitle }}
    </Text>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <FeatureCard v-for="(item, index) in features" :key="index" :iconName="item.iconName" :title="item.title"
        :titleFontSize="titleFontSize" :content="item.content" :descriptionFontSize="descriptionFontSize" :class="'!cursor-auto'" />
    </div>
  </section>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Text from '../../atoms/Text';
import FeatureCard from '../../molecules/FeatureCard';
import { IconNames } from '../../atoms/Icon/Icon.vue';
import { sizes } from '../../atoms/Text/Text.vue';

export interface FeatureItem {
  iconName: IconNames;
  title: string;
  content: string;
}

export default {
  name: 'FeaturesGrid',
  components: {
    Text,
    FeatureCard,
  },
  props: {
    mainTitle: {
      type: String,
      default: '',
    },
    features: {
      type: Array as PropType<FeatureItem[]>,
      default: () => [],
    },
    titleFontSize: {
      type: String as PropType<(typeof sizes)[number]>,
      default: null,
    },
    descriptionFontSize: {
      type: String as PropType<(typeof sizes)[number]>,
      default: null,
    }
  },
};
</script>
